import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DisplayContext } from "../../../Context/displayContext";
import AssignOperation from "../../OperationPages/AssignOperation";
import ViewOperationSummary from "../../OperationPages/ViewOperationSummary";
const { RangePicker } = DatePicker;
const operationStatusOptions = [
  { key: "all", label: "All", value: "all" },
  { key: "assigned", label: "Assigned", value: "not_null" },
  { key: "closed", label: "Closed", value: "closed" },
  { key: "none", label: "Not Assigned", value: "none" },
  { key: "open", label: "Open", value: "open" },
  { key: "partially_open", label: "Partially Open", value: "partially_open" },
];

export default function SubaccountTransferList({
  canCreateSubaccountTransfer,
  setCanCreateSubaccountTransfer,
}: any) {
  const navigate = useNavigate();
  const [filterForm] = Form.useForm();
  const [isTransferLoading, setIsTransferLoading] = useState<boolean>(false);
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const [venueAccounts, setVenueAccounts] = useState<any[]>([]);
  const [subaccountTransfers, setSubaccountTransfers] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [venueTransferEditing, setVenueTransfertEditing] =
    useState<any>(undefined);
  const [exportingCSV, setExportingCSV] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [canFilterNotes, setCanFilterNotes] = useState<boolean>(false);
  const [destroyModal, setDestroyModal] = useState(false);
  const [subaccountTransfersCount, setSubAccountTransferCount] =
    useState<number>(0);
  const checkCanFilterDescription = useCallback(() => {
    if (
      filterForm.getFieldValue("operationStatus") &&
      filterForm.getFieldValue("operationStatus") !== "all"
    ) {
      setCanFilterNotes(true);
    } else {
      setCanFilterNotes(false);
      filterForm.setFieldValue("notes", "");
    }
  }, [filterForm]);
  const exportCSV = useCallback(() => {
    setExportingCSV(true);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/subaccount-transfer/export-csv",
      withCredentials: true,
      data: {
        transfers: subaccountTransfers,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_venue_transfers.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setExportingCSV(false);
      });
  }, [navigate, subaccountTransfers]);
  const getVenueAccounts = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-accounts",
      withCredentials: true,
    }).then((res) => {
      const result = res.data.venueAccountList?.map((row: any) => ({
        value: row.id,
        label: row.label,
        key: row.id,
      }));
      setVenueAccounts(result);
    });
  }, []);
  useEffect(() => {
    getVenueAccounts();
  }, [getVenueAccounts]);
  const fetchData = useCallback(() => {
    setIsTransferLoading(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/subaccount-transfer",
      withCredentials: true,
    })
      .then((res) => {
        setCanCreateSubaccountTransfer(res.data.canCreateSubaccountTransfer);
        /**
         * key,
         * transferId,
         * from,
         * to,
         * assetTicker,
         * venue,
         * amount
         * timestamp
         * user
         */
        setSubAccountTransferCount(res.data.transfers?.length || 0);
        setSubaccountTransfers(res.data.transfers);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setIsTransferLoading(false);
      });
  }, [setCanCreateSubaccountTransfer, navigate]);
  const onReset = useCallback(() => {
    filterForm.resetFields();
    fetchData();
  }, [filterForm, fetchData]);

  const filterData = useCallback(
    (form: any) => {
      setIsTransferLoading(true);
      let startDate = undefined,
        endDate = undefined;
      if (form.dateRange && form.dateRange.length) {
        startDate = form.dateRange[0].toDate().toISOString();
        endDate = form.dateRange[1].toDate().toISOString();
      }
      axios({
        method: "Get",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL + "/venue/subaccount-transfer",
        params: {
          startDate,
          endDate,
          fromAccountId: form.fromAccountId,
          toAccountId: form.toAccountId,
          operationStatus: form.operationStatus,
          notes: form.notes,
          isSuccess:
            form.isSuccess === "undefined" ? undefined : form.isSuccess,
        },
        withCredentials: true,
      })
        .then((res) => {
          setCanCreateSubaccountTransfer(res.data.canCreateSubaccountTransfer);
          setSubaccountTransfers(res.data.transfers);
          setSubAccountTransferCount(res.data.transfers?.length || 0);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        })
        .finally(() => {
          setIsTransferLoading(false);
        });
    },
    [setCanCreateSubaccountTransfer, navigate],
  );
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateVenueTransfer = useCallback(() => {
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/subaccount-transfer",
      data: {
        id: venueTransferEditing?.key,
        notes: venueTransferEditing?.notes,
      },
      withCredentials: true,
    })
      .then(() => {
        setShowModal(false);
        setVenueTransfertEditing(undefined);
      })
      .finally(() => {
        fetchData();
      });
  }, [venueTransferEditing, fetchData]);

  const [showOperationAssignModal, setShowOperationAssignModal] =
    useState<boolean>(false);
  const [referenceId, setReferenceId] = useState<number>();
  const [referenceType, setReferenceType] = useState<string>();
  const [referenceItem, setReferenceItem] = useState<any>();
  const [clientOptions, setClientOptions] = useState<any[]>();
  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      params: {
        withOperationOpen: JSON.stringify(true),
      },
      withCredentials: true,
    })
      .then((res) => {
        for (const c of res.data.clients) {
          c.label = `${c.label} (${c.deltecAccount})`;
        }
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);

  useEffect(() => {
    getClientOptions();
  }, [getClientOptions]);
  const [showOperationDetailModal, setShowOperationDetailModal] =
    useState<boolean>(false);

  const [canAssignOperation, setCanAssignOperation] = useState<boolean>(false);
  const fetchAssignCapability = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/access",
      withCredentials: true,
    })
      .then((res) => {
        setCanAssignOperation(res.data.canAssign);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  useEffect(() => {
    fetchAssignCapability();
  }, [fetchAssignCapability]);

  const columns = [
    {
      title: "Transfer ID",
      dataIndex: "transferId",
      fixed: "left" as const,
      render: (_: any, { transferId }: any) => (
        <Link
          to={"/bo/venues/delchain_transactions/ledgers"}
          state={{
            from: window.location.pathname,
            ledgerId: transferId,
          }}
        >
          {transferId}
        </Link>
      ),
      width: "180px",
    },
    {
      title: "Operation",
      dataIndex: "operationItem",
      sorter: false,
      align: "center" as const,
      render: (
        _: any,
        {
          key,
          operationItem,
          assetId,
          assetTicker,
          amount,
          fromAccountType,
          toAccountType,
        }: any,
      ) =>
        operationItem && operationItem.length ? (
          <Row justify={"center"}>
            <Button
              onClick={() => {
                setReferenceId(key);
                setReferenceType("venue_transfer");
                setDestroyModal(false);
                setShowOperationDetailModal(true);
              }}
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
            >
              <FontAwesomeIcon
                icon={
                  operationItem?.some(
                    (item: any) =>
                      item.operation?.operationStatus?.name?.toLocaleLowerCase() ===
                      "open",
                  )
                    ? ("fa-solid fa-lock-open" as IconProp)
                    : ("fa-solid fa-lock" as IconProp)
                }
                className={
                  operationItem?.some(
                    (item: any) =>
                      item.operation?.operationStatus?.name?.toLocaleLowerCase() ===
                      "open",
                  )
                    ? operationItem?.every(
                        (item: any) =>
                          item.operation?.operationStatus?.name?.toLocaleLowerCase() ===
                          "open",
                      )
                      ? "all-open"
                      : "some-open"
                    : "closed"
                }
              />
            </Button>
          </Row>
        ) : (
          <Row justify={"center"}>
            <Button
              onClick={() => {
                setReferenceId(key);
                setReferenceType("venue_transfer");
                setShowOperationAssignModal(true);
                setReferenceItem({
                  amount:
                    fromAccountType === "subaccount" && toAccountType === "main"
                      ? Number(amount) * -1
                      : amount,
                  assetId: assetId,
                  assetName: assetTicker,
                });
              }}
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
              disabled={!canAssignOperation}
            >
              {canAssignOperation ? (
                <FontAwesomeIcon
                  icon={"fa-regular fa-square-plus" as IconProp}
                  style={{ fontSize: "1.2em" }}
                />
              ) : (
                <></>
              )}
            </Button>
          </Row>
        ),
      width: "100px",
    },
    {
      title: "Venue",
      dataIndex: "venue",
      width: "90px",
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      render: (_: any, { timestamp }: any) =>
        timestamp ? (
          <>
            {new Date(timestamp)
              .toLocaleString("en-US", {
                timeZone: displayTimezone,
                timeZoneName: "short",
              })
              .split(", ")
              .join(" ")}
          </>
        ) : (
          <></>
        ),
      width: "180px",
    },
    {
      title: "From Account",
      dataIndex: "fromLabel",
      render: (_: any, { fromLabel, fromAccount }: any) => (
        <React.Fragment>
          <Row>
            <b>{fromLabel}</b>
          </Row>
          <Row>
            <i>{fromAccount}</i>
          </Row>
        </React.Fragment>
      ),
      width: "200px",
    },
    {
      title: "To Account",
      dataIndex: "toLabel",
      render: (_: any, { toLabel, toAccount }: any) => (
        <React.Fragment>
          <Row>
            <b>{toLabel}</b>
          </Row>
          <Row>
            <i>{toAccount}</i>
          </Row>
        </React.Fragment>
      ),
      width: "200px",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right" as const,
      render: (_: any, { amount }: any) =>
        amount ? (
          <>
            {amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </>
        ) : (
          <>0.00</>
        ),
      width: "100px",
    },
    {
      title: "Asset",
      dataIndex: "assetTicker",
      width: "75px",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      width: "300px",
      render: (_: any, { notes }: any) => (
        <p style={{ whiteSpace: "pre-line", color: "black" }}>{notes}</p>
      ),
    },
    {
      title: "Created By",
      dataIndex: "user",
      width: "120px",
    },
    {
      title: "Edit",
      dataIndex: "key",
      align: "center" as const,
      render: (_: any, subaccountTransfer: any) => (
        <Button
          disabled={!canCreateSubaccountTransfer || showModal}
          onClick={() => {
            setShowModal(true);
            setVenueTransfertEditing({ ...subaccountTransfer });
          }}
          style={{
            border: "none",
            background: "none",
            boxShadow: "none",
          }}
        >
          <FontAwesomeIcon
            icon={"fa-regular fa-pen-to-square" as IconProp}
            style={{ fontSize: "1.5em" }}
          />
        </Button>
      ),
      width: "90px",
    },
  ];

  const onConfirmationModalClose = useCallback((showModal: boolean) => {
    setShowOperationDetailModal(showModal);
    setDestroyModal(true);
  }, []);

  return (
    <Content id="subaccount-transfer-list">
      <Row justify={"space-between"} className="dcl-filter-row">
        <Col sm={16} md={16} lg={16} xl={16} xxl={8}>
          <Button
            className="dcl-btn-toggle"
            style={{ marginRight: "10px" }}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
            loading={isTransferLoading}
          >
            <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
            Filters
            {showFilter ? (
              <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
            ) : (
              <FontAwesomeIcon icon={"fa-solid fa-caret-down" as IconProp} />
            )}
          </Button>
          <Button type="primary" onClick={fetchData} className="dcl-btn-toggle">
            Refresh
          </Button>
        </Col>
        <Col sm={8} md={8} lg={8} xl={8} xxl={8}>
          <Row justify="end">
            <Button
              className="dcl-btn-toggle"
              loading={exportingCSV || !subaccountTransfers}
              onClick={exportCSV}
            >
              <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
              CSV Export
            </Button>
          </Row>
        </Col>
      </Row>
      <Row justify={"space-between"} className="dcl-filter-row">
        <Col sm={16} md={16} lg={16} xl={16} xxl={16}>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={filterData}
            className="dcl-toggled-content dcl-toggled-content-filter"
            hidden={!showFilter}
          >
            <Row>
              <Form.Item name="dateRange" className="dcl-filter-item">
                <RangePicker className="dcl-daterange-select" />
              </Form.Item>
              <Form.Item name="fromAccountId" className="dcl-filter-item">
                <Select
                  className="dcl-venue-select"
                  allowClear
                  showSearch
                  placeholder="From Account"
                  options={venueAccounts}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="toAccountId" className="dcl-filter-item">
                <Select
                  className="dcl-venue-select"
                  allowClear
                  showSearch
                  placeholder="To Account"
                  options={venueAccounts}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="isSuccess" className="dcl-filter-item">
                <Select
                  className="dcl-order-type-select"
                  allowClear
                  placeholder="Transfer Status"
                  options={[
                    { label: "Success", key: "success", value: true },
                    { label: "Failed", key: "failed", value: false },
                    { label: "All", key: "all", value: "undefined" },
                  ]}
                  popupMatchSelectWidth={false}
                  onChange={() => {
                    checkCanFilterDescription();
                  }}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="operationStatus" className="dcl-filter-item">
                <Select
                  className="dcl-order-type-select"
                  allowClear
                  placeholder="Operation Status"
                  options={operationStatusOptions}
                  popupMatchSelectWidth={false}
                  onChange={() => {
                    checkCanFilterDescription();
                  }}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="notes" className="dcl-filter-item">
                <Input
                  disabled={!canFilterNotes}
                  className="dcl-order-input"
                  allowClear
                  placeholder="Notes"
                />
              </Form.Item>
            </Row>
            <Row justify="end">
              <Space>
                {subaccountTransfersCount}
                <b>Results</b>
              </Space>
              <Button htmlType="submit" className="ant-btn-primary">
                Apply
              </Button>
              <Button onClick={onReset}>Reset</Button>
            </Row>
          </Form>
        </Col>
      </Row>

      <Table
        dataSource={subaccountTransfers}
        columns={columns}
        pagination={{
          defaultPageSize: 100,
          onChange: (page, pageSize) => {
            console.log(page, pageSize);
          },
          showSizeChanger: true,
          pageSizeOptions: [100, 200],
        }}
        loading={isTransferLoading}
        sticky
        scroll={{ x: 1500, y: 1000 }}
      />
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: "white",
              /* here is your component tokens */
            },
          },
        }}
      >
        <Modal
          open={showModal}
          okText={"Save"}
          onOk={() => {
            updateVenueTransfer();
          }}
          onCancel={() => {
            setShowModal(false);
            setVenueTransfertEditing(undefined);
          }}
        >
          <Form.Item label={<b>Transfer ID</b>} style={{ color: "white" }}>
            {venueTransferEditing?.transferId}
          </Form.Item>
          <Form.Item label={<b>Notes</b>}>
            <TextArea
              style={{ width: "300px" }}
              rows={3}
              value={venueTransferEditing?.notes}
              onChange={(e) => {
                venueTransferEditing.notes = e.target.value;
                setVenueTransfertEditing({ ...venueTransferEditing });
              }}
            />
          </Form.Item>
        </Modal>
        <Modal
          open={showOperationAssignModal}
          footer={[]}
          onCancel={() => {
            setShowOperationAssignModal(false);
            setClientOptions([...(clientOptions as any)]);
          }}
        >
          <AssignOperation
            clientOptions={clientOptions}
            setShowOperationAssignModal={setShowOperationAssignModal}
            referenceId={referenceId}
            referenceItem={referenceItem}
            referenceType={referenceType}
            fetchData={fetchData}
            form={undefined}
          />
        </Modal>
        <Modal
          open={showOperationDetailModal}
          destroyOnClose={destroyModal}
          footer={[]}
          closable={true}
          onCancel={() => {
            setReferenceType(undefined);
            setReferenceId(undefined);
            setShowOperationDetailModal(false);
          }}
          onClose={() => {
            setReferenceType(undefined);
            setReferenceId(undefined);
            setShowOperationDetailModal(false);
          }}
        >
          <ViewOperationSummary
            clientOptions={clientOptions}
            referenceId={referenceId}
            reference={referenceType}
            setShowModal={onConfirmationModalClose}
            fetchData={fetchData}
            form={undefined}
          />
        </Modal>
      </ConfigProvider>
    </Content>
  );
}
