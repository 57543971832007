import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Row, Select, Space, Table, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DisplayContext } from "../../Context/displayContext";
import OperationReportTabs from "./OperationReportTabs";

export default function OperationReportList() {
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);
  const [selectedClient, setSelectedClient] = useState<any>(undefined);
  const [clientList, setClientList] = useState<any[]>();
  const [years, setYears] = useState<any[]>([]);
  const [isFetchingFiles, setIsFetchingFiles] = useState<boolean>(false);
  const [selectedYear, setSelectedYear] = useState<any>(undefined);
  const [s3Files, setS3Files] = useState<any>([]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [showOperationPreview, setShowOperationPreview] =
    useState<boolean>(false);
  const [previewData, setPreviewData] = useState<any>(undefined);
  const [isAnyDataFetched, setIsAnyDataFetched] = useState<boolean>(false);

  const getClientList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client/",
      withCredentials: true,
    })
      .then((res) => {
        const result = res.data.clients.map((row: any) => ({
          key: row.id,
          label: row.clientName,
          value: row.id,
        }));
        setClientList(result);
      })
      .catch(() => {});
  }, []);
  const fetchClientOperationReportYears = useCallback(() => {
    if (!selectedClient) return;
    axios({
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/report/client/year-list",
      withCredentials: true,
      params: {
        clientId: selectedClient,
      },
    }).then((res) => {
      setYears(
        res.data.map((row: any) => ({
          label: row,
          value: row,
          key: row,
        })),
      );
    });
  }, [selectedClient]);
  const fetchFiles = useCallback(() => {
    setIsFetchingFiles(true);
    setShowOperationPreview(false);
    setPreviewData(undefined);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/report/client",
      withCredentials: true,
      params: {
        year: selectedYear,
        clientId: selectedClient,
      },
    })
      .then((res) => {
        setS3Files(res.data);
      })
      .finally(() => {
        setIsAnyDataFetched(true);
        setIsFetchingFiles(false);
      });
  }, [selectedYear, selectedClient]);

  useEffect(() => {
    getClientList();
  }, [getClientList]);
  useEffect(() => {
    fetchClientOperationReportYears();
  }, [fetchClientOperationReportYears]);

  const downloadFile = useCallback((reportId: string) => {
    if (!reportId) {
      toast.error("Something is wrong");
      return;
    }
    setIsDownloading(true);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/report/client/download",
      withCredentials: true,
      params: {
        reportId,
      },
    })
      .then((res) => {
        const url = res.data?.url;
        window.open(url, "_blank");
      })
      .catch((err) => {
        toast.error("Something is wrong");
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }, []);

  return (
    <Content id="operation-report-list">
      <Row style={{ margin: "5px" }} align="middle">
        <Select
          options={clientList}
          value={selectedClient}
          onChange={(e) => {
            setSelectedClient(e);
            setIsAnyDataFetched(false);
            setShowOperationPreview(false);
            setPreviewData(undefined);
          }}
          className="dcl-client-select"
          showSearch
          allowClear
          filterOption={(input, option: any) => {
            return option.label.toLowerCase().includes(input.toLowerCase());
          }}
          placeholder="Client"
          popupMatchSelectWidth={false}
        />
        {selectedClient && years?.length ? (
          <Select
            options={years}
            value={selectedYear}
            onChange={(e) => {
              setSelectedYear(e);
            }}
            className="dcl-client-select"
            showSearch
            allowClear
            filterOption={(input, option: any) => {
              return option.label.toLowerCase().includes(input.toLowerCase());
            }}
            placeholder="Year"
          />
        ) : undefined}
        {selectedClient && years?.length ? (
          <Button
            onClick={() => fetchFiles()}
            loading={isFetchingFiles}
            disabled={!selectedYear}
            type="primary"
          >
            Get Files
          </Button>
        ) : undefined}
      </Row>
      {selectedClient ? (
        !years?.length ? (
          <Row style={{ margin: "5px" }}>
            <b>No Reports</b>
          </Row>
        ) : undefined
      ) : undefined}

      {isAnyDataFetched ? (
        <Row justify={"center"}>
          <Table
            // className="client-table-dark"
            dataSource={s3Files}
            loading={isFetchingFiles}
            rowKey={"id"}
            columns={[
              {
                title: "label",
                dataIndex: "label",
                sorter: (a: any, b: any) => a.label.localeCompare(b.label),
              },
              {
                title: "Last Modified",
                dataIndex: "updatedAt",
                sorter: (a, b) =>
                  new Date(a.updatedAt).getTime() -
                  new Date(b.updatedAt).getTime(),
                render: (_: any, { updatedAt }: any) => (
                  <Space>
                    {new Date(updatedAt).toLocaleString("en-US", {
                      timeZone: displayContext?.displayContext.timezone,
                      timeZoneName: "short",
                    })}
                  </Space>
                ),
              },
              {
                title: "Actions",
                dataIndex: "actions",
                width: "200px",
                render: (
                  _: any,
                  { id, clientReportJSON, startDate, endDate }: any,
                ) => (
                  <Row justify={"space-between"}>
                    <Tooltip title="Preview">
                      <Button
                        style={{
                          border: "none",
                          background: "none",
                          boxShadow: "none",
                        }}
                        onClick={() => {
                          setShowOperationPreview(true);
                          setPreviewData({
                            ...clientReportJSON,
                            startDate,
                            endDate,
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={"fa-solid fa-eye" as IconProp} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Download">
                      <Button
                        style={{
                          border: "none",
                          background: "none",
                          boxShadow: "none",
                        }}
                        loading={isDownloading}
                        onClick={() => {
                          downloadFile(id);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={"fa-solid fa-download" as IconProp}
                        />
                      </Button>
                    </Tooltip>
                  </Row>
                ),
              },
            ]}
            sticky
          />
        </Row>
      ) : undefined}
      {showOperationPreview && previewData ? (
        <OperationReportTabs previewData={previewData} theme="light" />
      ) : undefined}
    </Content>
  );
}
