import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ConfigProvider,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import OperationReportTabs from "../AdminComponents/OperationPages/OperationReportTabs";
import { DisplayContext } from "../Context/displayContext";

export default function ClientReports() {
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);
  const [yearLoaded, setYearLoaded] = useState<boolean>(false);
  const [yearOptions, setYearOptions] = useState<any[]>([]);
  const [selectedYear, setSelectedYear] = useState();
  const [isFetchingFiles, setIsFetchingFiles] = useState<boolean>(false);
  const [s3Files, setS3Files] = useState<any>([]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [showOperationPreview, setShowOperationPreview] =
    useState<boolean>(false);
  const [previewData, setPreviewData] = useState<any>(undefined);
  const [isAnyDataFetched, setIsAnyDataFetched] = useState<boolean>(false);
  const fetchYearOptions = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/report/client/year-list",
      withCredentials: true,
    })
      .then((res) => {
        setYearOptions(
          res.data.map((row: any) => ({
            label: row,
            value: row,
            key: row,
          })),
        );
      })
      .finally(() => {
        setYearLoaded(true);
      });
  }, []);
  useEffect(() => {
    fetchYearOptions();
  }, [fetchYearOptions]);
  const fetchFiles = useCallback(() => {
    setIsFetchingFiles(true);
    setShowOperationPreview(false);
    setPreviewData(undefined);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/report/client",
      withCredentials: true,
      params: {
        year: selectedYear,
      },
    })
      .then((res) => {
        setS3Files(res.data);
      })
      .finally(() => {
        setIsAnyDataFetched(true);
        setIsFetchingFiles(false);
      });
  }, [selectedYear]);
  const downloadFile = useCallback((reportId: string) => {
    if (!reportId) {
      toast.error("Something is wrong");
      return;
    }
    setIsDownloading(true);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/report/client/download",
      withCredentials: true,
      params: {
        reportId,
      },
    })
      .then((res) => {
        const url = res.data?.url;
        window.open(url, "_blank");
      })
      .catch((err) => {
        toast.error("Something is wrong");
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }, []);

  return (
    <Content id="client-reports" style={{ margin: "5px" }}>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              borderColorDisabled: "rgba(74,120,247,0.0)",
              defaultBg: "#4A78F7",
              defaultBorderColor: "#4A78F7",
              defaultHoverBg: "#0F44D6",
              defaultHoverBorderColor: "#0F44D6",
              borderRadius: 30,
              defaultColor: "rgba(255,255,255, 0.8)",
              defaultHoverColor: "#fff",
            },
            Select: {
              optionSelectedBg: "#22293B",
              optionActiveBg: "#22293B",
              multipleItemBg: "#ffffff",
              borderRadius: 30,
              colorBorder: "#22293B",
            },
            Table: {
              borderColor: "rgba(255,255,255,0.1)",
              colorBgBase: "#22293B",
              headerBg: "#22293B",
              rowHoverBg: "#14171E",
              headerSplitColor: "rgba(255,255,255,0.1)",
            },
          },
          token: {
            colorBgContainerDisabled: "rgba(74,120,247,0.5)",
            colorTextDisabled: "rgba(255,255,255,0.8)",
            colorPrimary: "#fff",
          },
        }}
      >
        <Row align={"middle"} style={{ margin: "1em" }} className="client-row">
          <label style={{ marginRight: 8 }}>
            <b>Year:</b>
          </label>

          <Select
            className="client-select"
            loading={!yearLoaded}
            style={{ width: 120 }}
            placeholder="Select Year"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e)}
            options={yearOptions}
          />
          <Button
            onClick={() => fetchFiles()}
            loading={isFetchingFiles}
            disabled={!yearLoaded || !selectedYear}
          >
            Get Files
          </Button>
        </Row>
        {isAnyDataFetched ? (
          <Row justify={"center"}>
            <Table
              className="client-table-dark"
              dataSource={s3Files}
              loading={isFetchingFiles}
              rowKey={"id"}
              columns={[
                {
                  title: "label",
                  dataIndex: "label",
                  sorter: (a: any, b: any) => a.label.localeCompare(b.label),
                },
                {
                  title: "Last Modified",
                  dataIndex: "updatedAt",
                  sorter: (a, b) =>
                    new Date(a.updatedAt).getTime() -
                    new Date(b.updatedAt).getTime(),
                  render: (_: any, { updatedAt }: any) => (
                    <Space>
                      {new Date(updatedAt).toLocaleString("en-US", {
                        timeZone: displayContext?.displayContext.timezone,
                        timeZoneName: "short",
                      })}
                    </Space>
                  ),
                },
                {
                  title: "Actions",
                  dataIndex: "actions",
                  width: "200px",
                  render: (
                    _: any,
                    { id, clientReportJSON, startDate, endDate }: any,
                  ) => (
                    <Row justify={"space-between"}>
                      <Tooltip title="Preview">
                        <Button
                          style={{
                            border: "none",
                            background: "none",
                            boxShadow: "none",
                          }}
                          className="dark-action"
                          onClick={() => {
                            setShowOperationPreview(true);
                            setPreviewData({
                              ...clientReportJSON,
                              startDate,
                              endDate,
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={"fa-solid fa-eye" as IconProp}
                          />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Download">
                        <Button
                          style={{
                            border: "none",
                            background: "none",
                            boxShadow: "none",
                          }}
                          loading={isDownloading}
                          onClick={() => {
                            downloadFile(id);
                          }}
                          className="dark-action"
                        >
                          <FontAwesomeIcon
                            icon={"fa-solid fa-download" as IconProp}
                          />
                        </Button>
                      </Tooltip>
                    </Row>
                  ),
                },
              ]}
              sticky
            />
          </Row>
        ) : undefined}
        {showOperationPreview && previewData ? (
          <OperationReportTabs previewData={previewData} />
        ) : undefined}
      </ConfigProvider>
    </Content>
  );
}
