import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Collapse,
  ConfigProvider,
  Divider,
  Form,
  Pagination,
  Row,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../Context/userContext";
const { Panel } = Collapse;

const DEFAULT_OFFSET = 0;
const DEFAULT_PAGE_SIZE = 10;

function MyProfile() {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { encodedId } = useParams();
  const [clientList, setClientList] = useState<any[]>([]);

  const [currClientPageSize, setClientCurrPageSize] =
    useState(DEFAULT_PAGE_SIZE);

  const [clientsLength, setClientsLength] = useState(0);
  const [user, setUser] = useState<any>({
    id: "",
    timezoneId: "",
    firstName: "",
    lastName: "",
    role: { roleName: "" },
    mobileNum: "",
  });
  const fetchData = useCallback(
    (params = {}) => {
      axios({
        method: "Get",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/user/" +
          encodeURIComponent(encodedId ? encodedId : ""),

        withCredentials: true,
      })
        .then((res) => {
          setUser(res.data.user);
          res.data.capabilities.forEach((capbility: any) => {
            capbility.key = capbility.id;
            capbility.capName = capbility.name ? capbility.name : "";
          });
        })
        .catch(() => {
          toast.error("Unable to fetch user info", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      // console.log(email);
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/client/",
        params: {
          offset: DEFAULT_OFFSET,
          pageSize: DEFAULT_PAGE_SIZE,
          encodedUserId: encodedId,
        },
        withCredentials: true,
      })
        .then((res) => {
          res.data["clients"].map((client: any) => {
            client.key = client.id;
            client.businessActivityName =
              client.businessActivityDspTerm &&
              client.businessActivityDspTerm.value
                ? client.businessActivityDspTerm.value
                : "";
            return client;
          });
          setClientList(res.data["clients"]);
          // console.log(res.data["totalClients"]);
          setClientsLength(res.data["totalClients"]);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [encodedId],
  );

  const clientColumns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   width: "3%",
    //   editable: false,
    // },
    {
      title: "Client Name",
      dataIndex: "clientName",
      width: "10%",
      editable: false,
      render: (clientName: any, record: any) => {
        //console.log(clientName);
        return (
          <Link
            to={"/client/my_company_profile/" + encodeURIComponent(record.id)}
            state={{
              from: window.location.pathname,
            }}
            className={"client-link-dark"}
          >
            {clientName}
          </Link>
        );
      },
    },
    { title: "Email", dataIndex: "email", width: "10%", editable: false },
    // {
    //   title: "Telegram Group",
    //   dataIndex: "telegramGroup",
    //   width: "10%",
    //   editable: false,
    //   render: (_: any, { telegramGroup, telegramGroupLink }: any) => (
    //     <Link
    //       to=""
    //       onClick={() => {
    //         window.open(telegramGroupLink);
    //       }}
    //       className={"client-link-dark"}
    //     >
    //       {telegramGroup}
    //     </Link>
    //   ),
    // },
    {
      title: "Business Activity",
      dataIndex: "businessActivityName",
      width: "10%",
      editable: false,
    },
  ];

  const onClientPaginationChange = (current: any, pageSize: any) => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client/",
      params: {
        offset: current === 1 || current === 0 ? 0 : (current - 1) * pageSize,
        pageSize: pageSize,
        userEmail: encodedId,
      },
      withCredentials: true,
    })
      .then((res) => {
        res.data["clients"].map((client: any) => {
          client.key = client.id;
          client.businessActivityName = client.businessActivity.name
            ? client.businessActivity.name
            : "";
          return client;
        });
        setClientList(res.data["clients"]);
        setClientCurrPageSize(pageSize);
      })
      .catch(() => {
        toast.error("Unable to fetch client list", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <React.Fragment>
      <ConfigProvider
        theme={{
          components: {
            Collapse: {
              colorBorder: "#1B1F2B",
            },
            Table: {
              borderColor: "rgba(255,255,255,0.1)",
              colorBgBase: "#22293B",
              headerBg: "#22293B",
              rowHoverBg: "#14171E",
              headerSplitColor: "rgba(255,255,255,0.1)",
            },
          },
          token: {
            colorPrimary: "#fff",
          },
        }}
      >
        <Row>
          <Button
            onClick={() => {
              if (location.state) {
                navigate(location.state.from);
              } else {
                navigate("/bo/users");
              }
            }}
          >
            <FontAwesomeIcon
              icon={"fa-solid fa-circle-chevron-left" as IconProp}
            />
          </Button>
        </Row>
        <Content style={{ color: "white" }}>
          <Card title={undefined} style={{ margin: "5px" }}>
            <Row
              style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}
            >
              <Col
                span={8}
                style={{ borderBottom: "1px rgba(255,255,255,0.2) solid" }}
              >
                <Form.Item label="Email" style={{ marginBottom: "10px" }}>
                  {user?.email}
                </Form.Item>
              </Col>
            </Row>
            <Row
              style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}
            >
              <Col
                span={8}
                style={{ borderBottom: "1px rgba(255,255,255,0.2) solid" }}
              >
                <Form.Item label="Role Name" style={{ marginBottom: "10px" }}>
                  {userContext?.user?.selectedClient?.role}
                </Form.Item>
              </Col>
              <Col span={2} />
              <Col
                span={8}
                style={{ borderBottom: "1px rgba(255,255,255,0.2) solid" }}
              >
                <Form.Item
                  label="Mobile Number"
                  style={{ marginBottom: "10px" }}
                >
                  {user?.mobileNum}
                </Form.Item>
              </Col>
            </Row>
            <Row
              style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}
            >
              <Col span={8}>
                <Form.Item label="First Name" style={{ marginBottom: "10px" }}>
                  {user?.firstName}
                </Form.Item>
              </Col>
              <Col span={2} />
              <Col span={8}>
                <Form.Item label="Last Name" style={{ marginBottom: "10px" }}>
                  {user.lastName}
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Divider />
          {/* <Row style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}>
            <h4>Capabilities</h4>
          </Row>
          <Row style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}>
            <Table columns={capColumns} dataSource={capList}></Table>
          </Row> */}
          <Collapse
            defaultActiveKey={["info", "clients"]}
            style={{ margin: "5px" }}
          >
            <Panel header="Clients" key="clients">
              <Table
                className="client-table-dark"
                columns={clientColumns}
                dataSource={clientList}
                sticky
                scroll={{ x: 800 }}
                pagination={{
                  pageSize: currClientPageSize,
                  hideOnSinglePage: true,
                }}
              />
              <Row justify="end">
                <Pagination
                  showSizeChanger
                  className="client-pagination client-table-dark"
                  onChange={onClientPaginationChange}
                  pageSizeOptions={[1, 2, 10]}
                  total={clientsLength}
                />
              </Row>
            </Panel>
          </Collapse>
        </Content>
      </ConfigProvider>
    </React.Fragment>
  );
}
export default MyProfile;
