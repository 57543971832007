import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Display Filter commentted out because of the ANTD table Column behavior,
// not re-rendering the table after canEdit is updated

function ViewAssetList({
  filter,
  onFilterChange,
  assetDisplay,
  onAssetDisplayChange,
}: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [filterForm] = Form.useForm();
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [assetList, setAssetList] = useState<any[]>([]);
  const [assetOptions, setAssetOptions] = useState<any[]>();
  const [assetNetworkOptions, setAssetNetworkOptions] = useState<any[]>([]);
  const getAssetNetworks = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/networks",
      withCredentials: true,
    }).then((res) => {
      setAssetNetworkOptions(res.data.networks);
    });
  }, []);
  useEffect(() => {
    getAssetNetworks();
  }, [getAssetNetworks]);

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      filterForm.setFieldsValue(location.state.form);
    }
  }, [location, filterForm]);

  const [showFilter, setShowFilter] = useState<boolean>(filter ? true : false);
  const [assetTypeList, setAssetTypeList] = useState<any[]>();
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const initialColumns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      hidden: false,
      editable: false,
      render: (_: any, { name }: any) => <Space>{name}</Space>,
    },
    {
      title: "Ticker",
      dataIndex: "ticker",
      sorter: (a: any, b: any) => a.ticker.localeCompare(b.ticker),
      hidden: false,
      editable: false,
      render: (_: any, { ticker }: any) => <Space>{ticker}</Space>,
    },
    {
      title: "Type",
      dataIndex: "assetType",
      hidden: false,
      editable: false,
      ellipsis: true,
      render: (_: any, { assetType }: any) => (
        <Space>{assetType ? assetType.name : ""}</Space>
      ),
    },
    {
      title: "Is Stablecoin",
      dataIndex: "isStablecoin",
      hidden: false,
      editable: false,
      render: (_: any, { id, isStablecoin }: any) => (
        <Space size="small" id={`stablecoin_${id}`}>
          <Switch defaultChecked={isStablecoin} disabled />
        </Space>
      ),
    },
    {
      title: "Coingecko ID",
      dataIndex: "coingeckoId",
      hidden: false,
      editable: false,
      ellipsis: true,
      render: (_: any, { coingeckoId }: any) => <Space>{coingeckoId}</Space>,
    },
    {
      title: "Network",
      dataIndex: "assetNetwork",
      hidden: false,
      editable: false,
      ellipsis: true,
      render: (_: any, { assetAssetNetworkMap }: any) => (
        <React.Fragment>
          {assetAssetNetworkMap
            ? assetAssetNetworkMap.map((row: any, idx: number) => (
                <Row key={idx}>{row.assetNetwork.name}</Row>
              ))
            : ""}
        </React.Fragment>
      ),
    },
    {
      title: "Fee Policy",
      dataIndex: "feePolicyAssignment",
      hidden: false,
      editable: false,
      ellipsis: true,
      render: (_: any, { feePolicyAssignment }: any) => (
        <>
          {feePolicyAssignment.map((f: any) => {
            return f ? (
              <Row key={f.id}>
                <b>{f.feePolicyType?.name.trim()}: </b>
                {f.feePolicyType?.name.trim()}
              </Row>
            ) : (
              <></>
            );
          })}
        </>
      ),
    },

    {
      title: "Is Trading",
      dataIndex: "isTrading",
      hidden: false,
      editable: false,
      render: (_: any, { id, isTrading }: any) => (
        <Space size="small" id={id}>
          <Switch defaultChecked={isTrading} disabled />
        </Space>
      ),
    },
    {
      title: "Is Custody",
      dataIndex: "isCustody",
      hidden: false,
      editable: false,
      render: (_: any, { id, isCustody }: any) => (
        <Space size="small" id={id}>
          <Switch defaultChecked={isCustody} disabled />
        </Space>
      ),
    },
    {
      title: "Is Staking",
      dataIndex: "isStaking",
      hidden: false,
      editable: false,
      render: (_: any, { id, isStaking }: any) => (
        <Space size="small" id={id}>
          <Switch defaultChecked={isStaking} disabled />
        </Space>
      ),
    },
    {
      title: "Is Active",
      dataIndex: "isActive",
      hidden: false,
      editable: false,
      render: (_: any, { id, isActive }: any) => (
        <Space size="small" id={id}>
          <Switch defaultChecked={isActive} disabled />
        </Space>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      hidden: false,
      editable: false,
      //   fixed: "right",
      render: (_: any, { id }: any) => (
        <Space size="small" id={id}>
          <Popconfirm
            title="Are you sure you want delete this asset?"
            onConfirm={() => {
              axios({
                method: "PUT",
                url:
                  process.env.REACT_APP_AWS_BACKEND_URL + "/asset/delete-check",
                withCredentials: true,
                data: {
                  assetId: id,
                },
              })
                .then(() => {
                  axios({
                    method: "DELETE",
                    url:
                      process.env.REACT_APP_AWS_BACKEND_URL + "/asset/delete",
                    withCredentials: true,
                    data: {
                      assetId: id,
                    },
                  }).then(() => {
                    toast.success("Asset deleted successfully", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    fetchData();
                  });
                })
                .catch(() => {
                  toast.error("Cannot delete this asset.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                });
            }}
          >
            <Button disabled={!canEdit}>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      hidden: false,
      // width: "15%",
      editable: false,
      render: (text: any, record: any) => (
        <Button
          disabled={!canEdit}
          onClick={() => {
            const url = "/bo/asset/update/" + encodeURIComponent(record.id);
            navigate(url, {
              state: {
                from: window.location.pathname,
                form: filterForm.getFieldsValue(),
              },
            });
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const fetchData = useCallback(
    (params = {}) => {
      setOnFinishLoad(false);
      if (!assetTypeList) {
        axios({
          method: "Get",
          url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/asset-type",
          withCredentials: true,
        })
          .then((res) => {
            setAssetTypeList(res.data);
          })
          .catch((err) => {
            console.error("Failed to fetch asset type");
          });
      }
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset",
        params: {
          assetIds: JSON.stringify(filterForm.getFieldValue("assetNames")),
          assetNetworkIds: JSON.stringify(
            filterForm.getFieldValue("assetNetworks"),
          ),
          assetType: filterForm.getFieldValue("assetType"),
          isActive: filterForm.getFieldValue("isActive"),
          isTrading: filterForm.getFieldValue("isTrading"),
          isCustody: filterForm.getFieldValue("isCustody"),
          isStaking: filterForm.getFieldValue("isStaking"),
        },
        withCredentials: true,
      })
        .then((res) => {
          const data = res.data.assetList;
          setCanEdit(res.data.canEdit);
          for (const row of data) {
            //console.log(row);
            row.key = row.id;
          }
          setAssetList(data);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        })
        .finally(() => {
          setOnFinishLoad(true);
        });
    },
    [filterForm, assetTypeList, navigate],
  );
  const onReset = useCallback(() => {
    location.state = {};
    filterForm.resetFields();
    fetchData();
    onFilterChange(undefined);
  }, [fetchData, filterForm, location, onFilterChange]);
  const filterAsset = (form: any) => {
    setOnFinishLoad(false);
    onFilterChange(form);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/",
      withCredentials: true,
      params: {
        assetIds: JSON.stringify(form.assetNames),
        assetNetworkIds: JSON.stringify(form.assetNetworks),
        assetType: form.assetType,
        isActive: form.isActive,
        isTrading: form.isTrading,
        isCustody: form.isCustody,
        isStaking: form.isStaking,
      },
    })
      .then((res: any) => {
        for (const row of res.data.assetList) {
          row.key = row.id;
        }
        setAssetList(res.data.assetList);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };

  const getAssetList = useCallback((params = {}) => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        options.sort((a, b) => a.label.localeCompare(b.label));
        setAssetOptions(options);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, []);
  useEffect(() => {
    getAssetList();
  }, [getAssetList]);

  useEffect(() => {
    if (filterForm && filter) {
      for (const [key, val] of Object.entries(filter)) {
        filterForm.setFieldValue(key, val);
      }
    }
    fetchData();
  }, [fetchData, filter, filterForm]);

  const [downloadingCSV, setDownloadingCSV] = useState<boolean>(false);
  const downloadCSV = useCallback(() => {
    setDownloadingCSV(true);
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/export-csv",
      withCredentials: true,
      data: {
        data: assetList,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_asset.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setDownloadingCSV(false);
      });
  }, [assetList, navigate]);

  return (
    <React.Fragment>
      <Content id="view-asset-list">
        <Row className="dcl-filter-row">
          <Col md={{ span: 16 }} sm={{ span: 16 }}>
            <Space>
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
                Filters
                {showFilter ? (
                  <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
                ) : (
                  <FontAwesomeIcon
                    icon={"fa-solid fa-caret-down" as IconProp}
                  />
                )}
              </Button>
            </Space>
            <Form
              title="Filter"
              form={filterForm}
              onFinish={filterAsset}
              hidden={!showFilter}
              className="dcl-toggled-content dcl-toggled-content-filter"
            >
              <Row>
                <Form.Item name="assetNames" className="dcl-filter-item">
                  <Select
                    mode="multiple"
                    allowClear
                    className="dcl-asset-select"
                    placeholder="Assets"
                    options={assetOptions}
                    popupMatchSelectWidth={false}
                    filterOption={(input, option: any) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  />
                </Form.Item>
                <Form.Item name="assetNetworks" className="dcl-filter-item">
                  <Select
                    mode="multiple"
                    allowClear
                    className="dcl-asset-select"
                    placeholder="Asset Network"
                    options={assetNetworkOptions}
                    popupMatchSelectWidth={false}
                    filterOption={(input, option: any) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  />
                </Form.Item>
                <Form.Item name="assetType" className="dcl-filter-item">
                  <Select
                    placeholder="Asset Type"
                    popupMatchSelectWidth={false}
                    className="dcl-asset-type-select"
                  >
                    {assetTypeList?.map((assetType) => (
                      <Select.Option
                        value={assetType["id"]}
                        key={assetType["id"]}
                        id={assetType["id"]}
                      >
                        {assetType["name"]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="isActive" className="dcl-filter-item">
                  <Select
                    placeholder="Status"
                    popupMatchSelectWidth={false}
                    className="dcl-status-select"
                  >
                    <Select.Option key={"true"} value={true}>
                      Active
                    </Select.Option>
                    <Select.Option key={"false"} value={false}>
                      Inactive
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  name="isTrading"
                  //   label="Is Trading"
                  className="dcl-filter-item"
                >
                  <Select
                    className="dcl-status-select"
                    placeholder="Is Trading"
                    popupMatchSelectWidth={false}
                  >
                    <Select.Option key={"true"} value={true}>
                      True
                    </Select.Option>
                    <Select.Option key={"false"} value={false}>
                      False
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="isCustody"
                  className="dcl-filter-item dcl-status-select"
                >
                  <Select
                    placeholder="Is Custody"
                    popupMatchSelectWidth={false}
                  >
                    <Select.Option key={"true"} value={true}>
                      True
                    </Select.Option>
                    <Select.Option key={"false"} value={false}>
                      False
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="isStaking"
                  className="dcl-filter-item dcl-status-select"
                >
                  <Select
                    placeholder="Is Staking"
                    popupMatchSelectWidth={false}
                  >
                    <Select.Option key={"true"} value={true}>
                      True
                    </Select.Option>
                    <Select.Option key={"false"} value={false}>
                      False
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Row>
              <Row justify="end">
                <Space>
                  {assetList.length}
                  <b>Results</b>
                </Space>
                <Space>
                  <Button htmlType="submit" className="ant-btn-primary">
                    Apply
                  </Button>
                  <Button onClick={onReset}>Reset</Button>
                </Space>
              </Row>
            </Form>
          </Col>
          <Col md={{ span: 8 }} sm={{ span: 8 }}>
            <Row justify="end">
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                loading={downloadingCSV}
                onClick={downloadCSV}
              >
                <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
                CSV Export
              </Button>
              <Button
                type="primary"
                className="dcl-btn-toggle"
                disabled={!canEdit}
                onClick={() => {
                  navigate("/bo/asset/create", {
                    state: {
                      from: window.location.pathname,
                      form: filterForm.getFieldsValue(),
                    },
                  });
                }}
              >
                <FontAwesomeIcon
                  icon={"fa-solid fa-plus" as IconProp}
                  className="white-plus"
                />
                New Asset
              </Button>
            </Row>
          </Col>
        </Row>
        {onFinshiLoad ? (
          <Table
            columns={initialColumns}
            showSorterTooltip={false}
            dataSource={assetList}
            sticky
            loading={!onFinshiLoad}
            scroll={{
              x: 1600,
            }}
            //   onChange={handleTableChange}
          />
        ) : (
          <Row justify={"center"}>
            <Spin size="large" />
          </Row>
        )}
      </Content>
    </React.Fragment>
  );
}
export default ViewAssetList;
