import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DisplayContext } from "../../Context/displayContext";
import CreateOperation from "./CreateOperation";
import DeleteOperationModal from "./DeleteOperationModal";
import ViewOperationDetail from "./ViewOperationDetail";
import ViweOperationXTradeModal from "./ViewOperationXTradeModal";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

export default function ViewOperations({ filter }: any) {
  const [filterForm] = Form.useForm();
  const [showFilter, setShowFilter] = useState<boolean>(filter ? true : false);
  const location = useLocation();

  const navigate = useNavigate();
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);
  const [loading, setLoading] = useState<boolean>(false);
  const [clientList, setClientList] = useState<any[]>([]);
  const [operationList, setOperationList] = useState<any[]>([]);
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [selectOperationId, setSelectedOperationId] = useState<any>();
  const [canEditTerminated, setCanEditTerminated] = useState<boolean>(false);
  const [canUnterminate, setCanUnterminate] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [operationStatusList, setOperationStatusList] = useState<any[]>([]);
  const [xSelectedTradeOperationId, setSelectedXTradeOperationId] =
    useState<String>();
  const [showOperationXTradeModal, setShowOperationXTradeModal] =
    useState<boolean>(false);
  const [xTradesSelectedDetails, setXTradesSelectedDetails] = useState<any>();
  const [itemCount, setItemCount] = useState<number>(0);
  const [appliedFilter, setAppliedFilter] = useState<any>({
    clients: undefined,
    dateRange: undefined,
    hasXTrade: undefined,
    isPropTrade: undefined,
    operationStatus: "Open",
  });
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 100,
    sortOrder: "descend",
    sortField: "opDate",
  });
  const [pageTotal, setPageTotal] = useState<number>(100);

  const [transactionWayList, setTransactionWayList] = useState<any[]>([]);
  const getTransactionWayList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/transaction-way",
      params: { type: "trade" }, // Buy and Sell Specific
      withCredentials: true,
    }).then((res) => {
      setTransactionWayList(res.data);
    });
  }, []);
  useEffect(() => {
    getTransactionWayList();
  }, [getTransactionWayList]);

  const fetchXtradeDetails = useCallback(() => {
    if (xSelectedTradeOperationId) {
      axios({
        method: "Get",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/util/operations/item/x-trade",
        params: {
          operationId: xSelectedTradeOperationId,
          includeSelf: "true",
        },
        withCredentials: true,
      }).then((res) => {
        setXTradesSelectedDetails(res.data);
      });
    } else {
      setXTradesSelectedDetails(undefined);
    }
  }, [xSelectedTradeOperationId]);
  useEffect(() => {
    fetchXtradeDetails();
  }, [fetchXtradeDetails]);

  useEffect(() => {
    const initalColumns = [
      {
        title: "ID",
        dataIndex: "id",
        width: "70px",
      },
      {
        title: "Operation Date",
        dataIndex: "opDate",
        width: "200px",
        sorter: true,
        sortOrder:
          pagination.sortField === "opDate" ? pagination.sortOrder : undefined,
        render: (_: any, { opDate }: any) => (
          <>
            {opDate
              ? dayjs(opDate).tz("America/New_York", true).format("MM/DD/YYYY")
              : undefined}
          </>
        ),
      },
      {
        title: "Client",
        dataIndex: "clientName",
        width: "150px",
        sorter: true,
        sortOrder:
          pagination.sortField === "clientName" ? pagination.sortOrder : undefined,
      },
      {
        title: "# of Operation Items",
        dataIndex: "operationItemCount",
        width: "100px",
        align: "center",
      },
      {
        title: "Label",
        dataIndex: "label",
        width: "150px",
        sorter: true,
        sortOrder:
          pagination.sortField === "label" ? pagination.sortOrder : undefined,
      },
      {
        title: "Operation Way",
        dataIndex: "transactionWay",

        width: "100px",
      },
      {
        title: "Assets",
        dataIndex: "assets",
        render: (text: string) => (
          <div>
            {text.split("\n").map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </div>
        ),
        width: "100px",
      },
      {
        title: "Created By",
        dataIndex: "createdBy",
        width: "150px",
      },
      {
        title: "Completed By",
        dataIndex: "completedBy",
        width: "150px",
        render: (_: any, { completedBy, completionDate }: any) => (
          <Tooltip
            title={new Date(completionDate).toLocaleString("en-us", {
              timeZone: displayTimezone,
              timeZoneName: "short",
            })}
          >
            {completedBy}
          </Tooltip>
        ),
      },
      {
        title: "Created at",
        dataIndex: "createdAt",
        render: (_: any, { createdAt }: any) =>
          new Date(createdAt).toLocaleString("en-us", {
            timeZone: displayTimezone,
            timeZoneName: "short",
          }),
        width: "150px",
      },
      {
        title: "Status",
        dataIndex: "status",
        width: "130px",
        align: "center" as const,
        render: (
          _: any,
          { status, hasXTrade, id, isPropTrade, xTradeDetails }: any,
        ) => (
          <React.Fragment>
            <Row justify={"space-between"} align="middle">
              <Col span={8}>
                <FontAwesomeIcon
                  className={
                    status?.toLocaleLowerCase() === "open" ? "open" : "closed"
                  }
                  icon={
                    (status?.toLocaleLowerCase() === "open"
                      ? "fa-solid fa-lock-open"
                      : "fa-solid fa-lock") as IconProp
                  }
                />
              </Col>
              <Col span={8}>
                {hasXTrade ? (
                  <Tooltip
                    title={
                      <React.Fragment>
                        {xTradeDetails.map(
                          (xTradeDetail: any, xTradeDetailIndex: number) => (
                            <React.Fragment key={xTradeDetailIndex}>
                              <Row>
                                <b>{xTradeDetail.xTradeLabel}</b>
                              </Row>
                              <React.Fragment>
                                {xTradeDetail.operations?.map(
                                  (operation: any, operationIndex: number) => (
                                    <Row key={operationIndex}>
                                      <List.Item style={{ marginLeft: "2em" }}>
                                        {operation?.operation.label}
                                      </List.Item>
                                    </Row>
                                  ),
                                )}
                              </React.Fragment>
                            </React.Fragment>
                          ),
                        )}
                      </React.Fragment>
                    }
                  >
                    <Button
                      onClick={() => {
                        setShowOperationXTradeModal(true);
                        setSelectedXTradeOperationId(id);
                      }}
                      style={{
                        border: "none",
                        background: "none",
                        boxShadow: "none",
                        padding: "2px 5px",
                        margin: "0 5px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={"fa-solid fa-shuffle" as IconProp}
                      />
                    </Button>
                  </Tooltip>
                ) : (
                  <></>
                )}{" "}
              </Col>
              <Col span={8}>
                {isPropTrade ? (
                  <Tooltip title="Prop Trade">
                    <FontAwesomeIcon
                      icon={"fa-solid fa-arrows-spin" as IconProp}
                    />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </React.Fragment>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        width: "200px",
        align: "center" as const,
        render: (_: any, { id, label, status, operationItemCount }: any) => (
          <Row justify={"space-between"}>
            <Col span={8}>
              <Tooltip title="Detail">
                <Button
                  onClick={() => {
                    setShowDetailModal(true);
                    setSelectedXTradeOperationId(id);
                    setSelectedOperationId(id);
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                  <FontAwesomeIcon icon={"fa-regular fa-eye" as IconProp} />
                </Button>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title="Edit">
                <Button
                  disabled={
                    status?.toLocaleLowerCase() !== "open" &&
                    !canEditTerminated &&
                    !canUnterminate
                  }
                  onClick={() => {
                    const form = {
                      ...filterForm.getFieldsValue(),
                      dateRange: [
                        filterForm
                          .getFieldsValue()
                          ?.dateRange?.[0]?.toISOString(),
                        filterForm
                          .getFieldsValue()
                          ?.dateRange?.[1]?.toISOString(),
                      ],
                    };
                    navigate(`/bo/operation-edit/${id}`, {
                      state: {
                        from: window.location.pathname,
                        form: form,
                        label: label,
                        pagination: pagination,
                      },
                    });
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                  <FontAwesomeIcon
                    icon={"fa-regular fa-pen-to-square" as IconProp}
                  />
                </Button>
              </Tooltip>
            </Col>
            <Col span={8}>
              {canDelete || (canCreate && !operationItemCount) ? (
                <Tooltip title="Delete">
                  <Button
                    disabled={
                      !(canDelete || (canCreate && !operationItemCount))
                    }
                    style={{
                      border: "none",
                      background: "none",
                      boxShadow: "none",
                    }}
                    onClick={() => {
                      setShowDeleteModal(true);
                      setSelectedOperationId(id);
                      setItemCount(operationItemCount);
                    }}
                  >
                    <FontAwesomeIcon
                      className="delete-icon"
                      icon={"fa-regular fa-square-minus" as IconProp}
                    />
                  </Button>
                </Tooltip>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        ),
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "300px",
        render: (_: any, { description }: any) => (
          <p style={{ whiteSpace: "pre-line", color: "black" }}>
            {description}
          </p>
        ),
      },
      {
        title: "Updated at",
        dataIndex: "updatedAt",
        render: (_: any, { updatedAt }: any) =>
          new Date(updatedAt).toLocaleString("en-us", {
            timeZone: displayTimezone,
            timeZoneName: "short",
          }),
        width: "150px",
      },
    ];
    setColumns(initalColumns);
  }, [
    canCreate,
    canDelete,
    filterForm,
    canEditTerminated,
    canUnterminate,
    displayTimezone,
    loading,
    navigate,
    pagination,
  ]);

  const [operationTypes, setOperationTypes] = useState<any>();

  const fetchOperationTypes = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/types",
      withCredentials: true,
    }).then((res) => {
      setOperationTypes(res.data);
    });
  }, []);
  useEffect(() => {
    fetchOperationTypes();
  }, [fetchOperationTypes]);

  const [columns, setColumns] = useState<any[]>([]);

  const getClientList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    })
      .then((res) => {
        for (const c of res.data.clients) {
          c.label = `${c.label} (${c.deltecAccount})`;
        }
        setClientList(res.data.clients);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  const [isDownloadingCSV, setIsDownloadingCSV] = useState<boolean>(false);
  const downloadCSV = useCallback(() => {
    const body = {
      startDate: appliedFilter?.dateRange
        ? appliedFilter.dateRange[0].tz("America/New_York").format()
        : undefined,
      endDate: appliedFilter?.dateRange
        ? appliedFilter.dateRange[1].tz("America/New_York").format()
        : undefined,
      operationStatus: appliedFilter?.operationStatus || "Open",
      clientIds: appliedFilter?.clients,
      label: appliedFilter?.label,
      sortField: pagination?.sortField,
      sortOrder: pagination?.sortOrder || "descend",
    };
    setIsDownloadingCSV(true);
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/export-csv",
      withCredentials: true,
      data: body,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_operation.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setIsDownloadingCSV(false);
      });
  }, [appliedFilter, pagination, navigate]);

  const fetchOperationStatusList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/status",
      withCredentials: true,
      params: { includeAll: "true" },
    }).then((res) => {
      const list = res.data.map((row: any) => ({
        label: row.label,
        value: row.label,
        key: row.ke,
      }));
      list.unshift({
        label: "All",
        value: "all",
        key: "all",
      });
      setOperationStatusList(list);
    });
  }, []);
  useEffect(() => {
    fetchOperationStatusList();
  }, [fetchOperationStatusList]);

  const fetchOperations = useCallback(
    (form?: any, paginationParams?: any) => {
      const params = {
        startDate: form?.dateRange
          ? form.dateRange[0]?.tz("America/New_York")?.format()
          : undefined,
        endDate: form?.dateRange
          ? form.dateRange[1]?.tz("America/New_York")?.format()
          : undefined,
        operationStatus: form?.operationStatus || "Open",
        clients: form?.clients ? JSON.stringify(form.clients) : undefined,
        label: form?.label,
        page: paginationParams?.current || 1,
        pageSize: paginationParams?.pageSize || 100,
        sortField: paginationParams?.sortField,
        sortOrder: paginationParams?.sortOrder || "descend",
        isPropTrade: form?.isPropTrade || "all",
        hasXTrade: form?.hasXTrade || "all",
      };
      setLoading(true);
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations",
        withCredentials: true,
        params: params,
      })
        .then((res) => {
          setOperationList(res.data.operations);
          setCanCreate(res.data.canCreate);
          setCanDelete(res.data.canDeleteOperation);
          setCanEditTerminated(res.data.canEditTerminated);
          setCanUnterminate(res.data.canUnterminate);
          setPageTotal(res.data.totalCount);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [navigate],
  );

  useEffect(() => {
    getClientList();
    setShowFilter(true);
    if (location?.state?.form) {
      const form = {
        ...location?.state?.form,
        dateRange:
          location?.state?.form?.dateRange?.[0] &&
          location?.state?.form?.dateRange?.[1]
            ? [
                dayjs(location?.state?.form?.dateRange?.[0]),
                dayjs(location?.state?.form?.dateRange?.[1]),
              ]
            : undefined,
      };
      filterForm.setFieldsValue(form);
      if (location.state.pagination) {
        setPagination(location.state.pagination);
      }
      fetchOperations(form, location.state.pagination || undefined);
    } else {
      filterForm.setFieldValue("operationStatus", "Open");
      fetchOperations(undefined, pagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterForm, location, fetchOperations, getClientList]);

  return (
    <Content id="view-operation-list">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Button
            className="dcl-btn-toggle"
            style={{ marginRight: "10px" }}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
            Filters
            {showFilter ? (
              <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
            ) : (
              <FontAwesomeIcon icon={"fa-solid fa-caret-down" as IconProp} />
            )}
          </Button>
          <Form
            title="Filter"
            form={filterForm}
            className="dcl-toggled-content dcl-toggled-content-filter"
            hidden={!showFilter}
          >
            <Row>
              <Form.Item name="dateRange" className="dcl-filter-item">
                <RangePicker className="dcl-daterange-select" />
              </Form.Item>
              <Form.Item name="clients" className="dcl-filter-item">
                <Select
                  className="dcl-client-select"
                  mode="multiple"
                  allowClear
                  options={clientList}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Clients"
                />
              </Form.Item>
              <Form.Item name="operationStatus" className="dcl-filter-item">
                <Select
                  className="dcl-status-select"
                  allowClear
                  options={operationStatusList}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Status"
                />
              </Form.Item>
              <Form.Item name="label" className="dcl-filter-item">
                <Input allowClear placeholder="Label" />
              </Form.Item>
              <Form.Item name="hasXTrade" className="dcl-filter-item">
                <Select
                  className="dcl-status-select"
                  allowClear
                  options={[
                    { key: "all", label: "All", value: "all" },
                    { key: "yes", label: "Yes", value: "yes" },
                    { key: "no", label: "No", value: "no" },
                  ]}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Has X-Trade?"
                />
              </Form.Item>
              <Form.Item name="isPropTrade" className="dcl-filter-item">
                <Select
                  className="dcl-status-select"
                  allowClear
                  options={[
                    { key: "all", label: "All", value: "all" },
                    { key: "yes", label: "Yes", value: "yes" },
                    { key: "no", label: "No", value: "no" },
                  ]}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Is Prop Trade?"
                />
              </Form.Item>
            </Row>
            <Row justify={"end"} align="middle">
              {loading ? undefined : (
                <Row>
                  {pageTotal} &nbsp;<b>Results</b>
                </Row>
              )}
              <Button
                type="primary"
                onClick={() => {
                  setAppliedFilter(filterForm.getFieldsValue());
                  setPagination({ ...pagination, current: 1 });
                  fetchOperations(filterForm.getFieldsValue(), {
                    ...pagination,
                    current: 1,
                  });
                }}
                loading={loading}
              >
                Apply
              </Button>
              <Button
                onClick={() => {
                  setAppliedFilter(undefined);
                  filterForm.resetFields();
                  setPagination({ ...pagination, current: 1 });
                  filterForm.setFieldValue("operationStatus", "Open");
                  fetchOperations(undefined, { ...pagination, current: 1 });
                }}
              >
                Reset
              </Button>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8 }} sm={{ span: 8 }}>
          <Row justify={"end"}>
            <Button
              type="primary"
              onClick={() => setShowCreateModal(true)}
              disabled={!canCreate}
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-plus" as IconProp}
                className="white-plus"
              />
              &nbsp; New Operation
            </Button>
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={downloadCSV}
              loading={isDownloadingCSV}
            >
              <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
              CSV Export
            </Button>
          </Row>
        </Col>
      </Row>
      {loading ? (
        <Row justify={"center"}>
          <Spin size="large" />
        </Row>
      ) : (
        <Table
          rowKey={"id"}
          dataSource={operationList}
          sticky
          scroll={{ x: 1700 }}
          loading={loading}
          columns={columns}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pageTotal,
            onChange: (page, pageSize) =>
              setPagination({
                ...pagination,
                current: page,
                pageSize,
              }),
          }}
          onChange={(pagination, _, sorter: any) => {
            console.log(sorter);
            setPagination({
              ...pagination,
              sortOrder: sorter.order,
              sortField: sorter.field,
            });
            fetchOperations(filterForm.getFieldsValue(), {
              current: pagination.current,
              pageSize: pagination.pageSize,
              sortOrder: sorter.order,
              sortField: sorter.field,
            });
          }}
        />
      )}

      <Modal
        style={{
          minWidth: "70%",
        }}
        open={showDetailModal}
        footer={[]}
        closable={true}
        onCancel={() => {
          setSelectedOperationId(undefined);
          setShowDetailModal(false);
        }}
      >
        <ViewOperationDetail
          operationId={selectOperationId}
          transactionWayList={transactionWayList}
        />
        {xTradesSelectedDetails && xTradesSelectedDetails.length ? (
          <ViweOperationXTradeModal xtradeDetails={xTradesSelectedDetails} />
        ) : (
          <></>
        )}
      </Modal>
      <Modal
        open={showCreateModal}
        footer={[]}
        closable={true}
        onCancel={() => {
          setShowCreateModal(false);
          setClientList([...clientList]);
        }}
      >
        <CreateOperation
          transactionWayList={transactionWayList}
          clientList={clientList}
          operationTypes={operationTypes}
          setShowCreateModal={setShowCreateModal}
          fetchOperations={fetchOperations}
        />
      </Modal>
      <Modal
        open={showDeleteModal}
        footer={[]}
        closable={true}
        onCancel={() => {
          setShowDeleteModal(false);
          setItemCount(0);
          setSelectedOperationId(undefined);
        }}
      >
        <DeleteOperationModal
          operationId={selectOperationId}
          fetchOperations={fetchOperations}
          operationItemCount={itemCount}
          setOperationItemCount={setItemCount}
          setSelectedOperationId={setSelectedOperationId}
          setShowDeleteModal={setShowDeleteModal}
        />
      </Modal>
      <Modal
        open={showOperationXTradeModal}
        footer={[]}
        closable={true}
        onCancel={() => {
          setShowOperationXTradeModal(false);
          setSelectedXTradeOperationId(undefined);
        }}
      >
        <ViweOperationXTradeModal xtradeDetails={xTradesSelectedDetails} />
      </Modal>
    </Content>
  );
}
