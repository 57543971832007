import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Divider, Row } from "antd";
import React from "react";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";

interface ViewScanResultProps {
  scanResult: any;
}

const ViewScanResult: React.FC<ViewScanResultProps> = ({ scanResult }) => {
  const formatArray = (type: string, array: any[], level = 0) => {
    // Helper function to format key-value pairs
    const formatEntries = (entries: any) =>
      entries.map(([key, value]: any) => {
        const formattedKey = (key.charAt(0).toUpperCase() + key.slice(1))
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2"); // Capitalize first letter
        const indent = { paddingLeft: `${level * 20}px` }; // Indentation for nested levels

        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          // Recursive handling for nested objects
          return (
            <div key={key} style={{ marginBottom: "8px" }}>
              <div style={indent}>
                <strong>{formattedKey}:</strong>
              </div>
              {formatArray(type, [value], level + 1)}
            </div>
          );
        } else if (Array.isArray(value)) {
          // Check if array contains objects
          const isArrayOfObjects = value.every(
            (item) => typeof item === "object" && item !== null,
          );

          if (isArrayOfObjects) {
            // Handle array of objects with proper prefix and consistent indentation
            return (
              <div key={key} style={{ marginBottom: "8px" }}>
                <div style={indent}>
                  <strong>{formattedKey}:</strong>
                </div>
                <div style={{ paddingLeft: `${(level + 1) * 20}px` }}>
                  {value.map((item, index) => (
                    <div key={index} style={{ marginBottom: "8px" }}>
                      <div>
                        <strong>{index + 1}.</strong>{" "}
                        {formatArray(type, [item], level + 2)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          } else {
            // Handle simple arrays
            return (
              <div key={key} style={{ marginBottom: "8px" }}>
                <div style={indent}>
                  <strong>{formattedKey}:</strong> [{value.join(", ")}]
                </div>
              </div>
            );
          }
        } else {
          // Handle primitive values
          return (
            <div key={key} style={{ marginBottom: "8px" }}>
              <div style={indent}>
                <strong>{formattedKey}:</strong> {value || "N/A"}
              </div>
            </div>
          );
        }
      });

    if (!array || array.length === 0) {
      return <div>No {type}s.</div>;
    }

    return (
      <>
        {array.map((alert: any, index: number) => (
          <div key={index} style={{ marginBottom: "16px" }}>
            <strong>
              {type.charAt(0).toUpperCase() + type.slice(1)} {index + 1}:
            </strong>
            <div style={{ paddingLeft: `${(level + 1) * 20}px` }}>
              {formatEntries(Object.entries(alert))}
            </div>
          </div>
        ))}
      </>
    );
  };
  const [, copy] = useCopyToClipboard();

  const copyExternalId = (externalId: string) => {
    copy(externalId);
    toast.success(`${externalId} copied to clipboard`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <React.Fragment>
      {scanResult ? (
        <Card
          style={{
            width: "100%",
            height: "100%",
            marginTop: "2em",
            marginBottom: "2em",
            maxHeight: "80vh",
            overflow: "auto",
          }}
          title={
            <Row justify="start" align="middle">
              <b>External ID: </b> &nbsp;
              {scanResult?.externalId}
              <Button
                onClick={() => {
                  copyExternalId(scanResult?.externalId);
                }}
                style={{
                  border: "none",
                  background: "none",
                  boxShadow: "none",
                }}
              >
                <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
              </Button>
            </Row>
          }
        >
          <Row gutter={[10, 5]}>
            <Col span={12} key="txn-scan-col-1">
              <Row>
                <b>Updated At:</b> &nbsp;
                {scanResult?.transferDetail?.updatedAt}
              </Row>
              <Divider>
                <b>Alerts</b>
              </Divider>
              {formatArray("alert", scanResult?.alertsDetail?.alerts || [])}
              <Divider>
                <b>Exposures</b>
              </Divider>

              {scanResult?.exposureDetail?.direct?.name ? (
                <React.Fragment>
                  <Row>
                    <b>Name: </b> &nbsp;
                    {scanResult?.exposureDetail?.direct?.name}
                  </Row>
                  {scanResult?.exposureDetail?.direct?.categoryId ? (
                    <Row>
                      <b>Category Id: </b>&nbsp;
                      {scanResult?.exposureDetail?.direct?.categoryId}
                    </Row>
                  ) : undefined}
                </React.Fragment>
              ) : (
                <Row>No Exposures</Row>
              )}
            </Col>
            <Col span={12} key="txn-scan-col-2">
              <Divider style={{ marginTop: "0px" }}>
                <b>Summary</b>
              </Divider>
              {scanResult?.transferDetail ? Object.entries(scanResult?.transferDetail).map(
                ([key, value]: any) => (
                  <div key={key} style={{ marginBottom: "8px" }}>
                    <div
                      style={{
                        wordBreak: "break-word",
                      }}
                    >
                      <strong>
                        {(key.charAt(0).toUpperCase() + key.slice(1))
                          .replace(/([a-z])([A-Z])/g, "$1 $2")
                          .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")}
                        :&nbsp;
                      </strong>
                      {value}
                    </div>
                  </div>
                ),
              ) : undefined}
            </Col>
          </Row>
        </Card>
      ) : undefined}
    </React.Fragment>
  );
};

export default ViewScanResult;
