import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Modal, Row, Spin, Table, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../../Context/displayContext";
import CreateNewLoan from "./CreateNewLoan";
import EditLoan from "./EditLoan";
import ViewLoanDetails from "./ViewLoanDetail";

export default function ViewLoans({ clientFacing = false }) {
  const navigate = useNavigate();

  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const [clientLoans, setClientLoans] = useState<any[]>([]);
  const [openViewDetailModal, setOpenViewDetailModal] =
    useState<boolean>(false);
  const [selectedLoanDetail, setSelectedLoanDetail] = useState<any>();
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const fetchClientLoans = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/bo/financial-services/loans",
      withCredentials: true,
    })
      .then((res) => {
        setClientLoans(res.data.clientLoans);
        setCanCreate(res.data.canCreate);
        setCanEdit(res.data.canEdit);
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);
  useEffect(() => {
    fetchClientLoans();
  }, [fetchClientLoans]);
  const createNewLoan = useCallback(() => {
    setIsModalLoading(true);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/bo/financial-services/loans",
      withCredentials: true,
      data: selectedLoanDetail,
    })
      .then(() => {
        toast.success("Created Successfully!");
      })
      .finally(() => {
        setIsModalLoading(false);
        setOpenCreateModal(false);
        setSelectedLoanDetail(undefined);
        fetchClientLoans();
      });
  }, [selectedLoanDetail, fetchClientLoans]);

  const updateLoan = useCallback(
    (data: any) => {
      setIsModalLoading(true);
      axios({
        method: "Put",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/bo/financial-services/loans",
        withCredentials: true,
        data: {
          id: data.id,
          marginRatioThreshold: Number(data.marginRatioThreshold),
          initialMarginRatio: Number(data.initialMarginRatio),
          emailTo: JSON.stringify(data.emailTo),
          telegramGroup: data.telegramGroup,
          assetPriceUrl: data.assetPriceUrl,
        },
      })
        .then(() => {
          toast.success("Created Successfully!");
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setIsModalLoading(false);
          setOpenEditModal(false);
          setSelectedLoanDetail(undefined);
          fetchClientLoans();
        });
    },
    [fetchClientLoans],
  );

  const columns = [
    {
      title: "Client",
      dataIndex: "clientName",
      key: "clientName",
      render: (_: any, { clientName }: any) => <b>{clientName}</b>,
      width: "150px",
    },

    {
      title: "Loan Date",
      dataIndex: "dateOfLoan",
      key: "dateOfLoan",
      render: (_: any, { dateOfLoan }: any) =>
        dayjs(dateOfLoan).format("YYYY-MM-DD"),
      width: "120px",
    },
    {
      title: "Loan Amount",
      dataIndex: "loanAmount",
      key: "loanAmount",
      render: (_: any, { loanAmount }: any) =>
        loanAmount?.toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      align: "right" as const,
      width: "100px",
    },
    {
      title: "Loan Asset",
      dataIndex: "loanAssetTicker",
      key: "loanAssetTicker",
      width: "100px",
    },
    {
      title: "Total Accrual Loan Asset",
      dataIndex: "totalAccrual",
      key: "totalAccrual",
      align: "right" as const,
      render: (_: any, { loanAssetTicker, totalAccrual }: any) => (
        <>
          {totalAccrual.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          ({loanAssetTicker})
        </>
      ),
      width: "100px",
    },
    {
      title: "Loan Asset USD Price",
      dataIndex: "loanAssetPrice",
      key: "loanAsset",
      width: "100px",
      align: "right" as const,
      render: (_: any, { loanAssetPrice }: any) => (
        <Link
          to={"https://pro.kraken.com/app/trade/usdt-usd"}
          target={"_blank"}
          className={clientFacing ? "client-link-dark" : undefined}
        >
          $
          {loanAssetPrice?.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          })}
        </Link>
      ),
    },
    {
      title: "Loan Asset USD Value",
      dataIndex: "loanUSDValue",
      key: "loanUSDValue",
      width: "100px",
      align: "right" as const,
      render: (_: any, { loanUSDValue }: any) =>
        loanUSDValue?.toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 8,
        }),
    },

    {
      title: "Collateral Asset",
      dataIndex: "collateralAsset",
      key: "collateralAsset",
      width: "100px",
    },

    {
      title: "Custody Wallet Address",
      dataIndex: "custodyWalletAddress",
      key: "custodyWalletAddress",
      width: "80px",
      render: (_: any, { custodyWalletAddress, subwallet }: any) =>
        clientFacing ? (
          custodyWalletAddress
        ) : (
          <Link
            to={"/bo/wallet/view/" + encodeURIComponent(subwallet?.walletId)}
            state={{
              from: window.location.pathname,
            }}
          >
            {custodyWalletAddress}
          </Link>
        ),
    },
    {
      title: "Collateral Amount",
      dataIndex: "collateralAmount",
      key: "collateralAmount",
      render: (_: any, { collateralAmount }: any) =>
        collateralAmount?.toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      align: "right" as const,
      width: "100px",
    },

    {
      title: "Last collateral price (USD)",
      dataIndex: "lastCollateralPrice",
      key: "lastCollateralPrice",
      width: "150px",
      align: "right" as const,
      render: (
        _: any,
        {
          lastCollateralPriceDatetime,
          lastCollateralPrice,
          assetPriceUrl,
        }: any,
      ) => (
        <Tooltip
          title={new Date(lastCollateralPriceDatetime)
            .toLocaleString("en-US", {
              timeZone: displayTimezone,
              timeZoneName: "short",
            })
            .split(", ")
            .join(" ")}
        >
          <Link
            to={assetPriceUrl}
            target={"_blank"}
            className={clientFacing ? "client-link-dark" : undefined}
          >
            $
            {lastCollateralPrice?.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Link>
        </Tooltip>
      ),
    },

    {
      title: "Collateral value",
      dataIndex: "collateralValue",
      key: "collateralValue",
      render: (_: any, { collateralValue }: any) =>
        collateralValue?.toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 8,
        }),
      align: "right" as const,
      width: "100px",
    },
    {
      title: "Margin Ratio Threshold",
      dataIndex: "threshold",
      key: "threshold",
      width: "250px",
      alignt: "right",
      render: (_: any, { marginRatioThreshold, initialMarginRatio }: any) => (
        <Row>
          {(marginRatioThreshold * 100)?.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          % -{" "}
          {(initialMarginRatio * 100)?.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          %
        </Row>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "80px",
      render: (_: any, { status, currentRatio }: any) => (
        <Row justify={"center"}>
          <Tooltip title={<b>{currentRatio?.toFixed(2)}%</b>}>
            <i className={`dcl_state ${status}`}></i>
          </Tooltip>
        </Row>
      ),
    },
  ];
  const actionColumn = {
    title: "Actions",
    dataIndex: "actions",
    key: "status",
    width: "130px",
    align: "center" as const,
    render: (_: any, loanDetail: any, index: number) => (
      <Row justify={"space-between"}>
        <Col span={12}>
          <Tooltip title="Detail">
            <Button
              onClick={() => {
                setOpenViewDetailModal(true);
                setSelectedLoanDetail(loanDetail);
                setSelectedIndex(index);
              }}
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
            >
              <FontAwesomeIcon icon={"fa-regular fa-eye" as IconProp} />
            </Button>
          </Tooltip>
        </Col>
        <Col span={12}>
          <Tooltip title="Edit">
            <Button
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
              disabled={!canEdit}
              onClick={() => {
                setOpenEditModal(true);
                setSelectedLoanDetail(loanDetail);
              }}
            >
              <FontAwesomeIcon
                icon={"fa-regular fa-pen-to-square" as IconProp}
              />
            </Button>
          </Tooltip>
        </Col>
      </Row>
    ),
  };
  return (
    <Content id="view-loans">
      {clientFacing ? undefined : (
        <Row justify={"end"} style={{ marginRight: "10px" }}>
          <Button
            type="primary"
            onClick={() => {
              setSelectedLoanDetail({});
              setOpenCreateModal(true);
            }}
            disabled={!canCreate}
          >
            <FontAwesomeIcon
              icon={"fa-solid fa-plus" as IconProp}
              className="white-plus"
            />
            &nbsp; New Loan
          </Button>
        </Row>
      )}
      <Table
        sticky
        className={clientFacing ? "client-table-dark" : undefined}
        scroll={{ x: "max-content" }}
        dataSource={clientLoans}
        columns={clientFacing ? columns : [...columns, actionColumn]}
        rowKey={"id"}
      />
      <Modal
        open={openViewDetailModal}
        onCancel={() => {
          setOpenViewDetailModal(false);
          setSelectedLoanDetail(undefined);
          setSelectedIndex(undefined);
        }}
        width={"80%"}
        footer={[]}
      >
        {selectedLoanDetail ? (
          <ViewLoanDetails
            loanDetail={selectedLoanDetail}
            index={selectedIndex}
          />
        ) : (
          <></>
        )}
      </Modal>
      <Modal
        open={openCreateModal}
        width={"60%"}
        footer={[]}
        onCancel={() => {
          setOpenCreateModal(false);
          setSelectedLoanDetail(undefined);
        }}
      >
        {isModalLoading ? (
          <Row justify={"center"}>
            <Spin size="large" />
          </Row>
        ) : (
          <CreateNewLoan
            createNewLoan={createNewLoan}
            loanDetail={selectedLoanDetail}
            setOpenCreateModal={setOpenCreateModal}
          />
        )}
      </Modal>
      <Modal
        open={openEditModal}
        width={"60%"}
        footer={[]}
        onCancel={() => {
          setOpenEditModal(false);
          setSelectedLoanDetail(undefined);
        }}
      >
        {isModalLoading ? (
          <Row justify={"center"}>
            <Spin size="large" />
          </Row>
        ) : (
          <EditLoan
            loanDetail={selectedLoanDetail}
            setOpenEditModal={setOpenEditModal}
            editLoan={updateLoan}
          />
        )}
      </Modal>
    </Content>
  );
}
