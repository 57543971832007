import {
  Button, Col,
  ConfigProvider, Input,
  Modal,
  Row,
  Space,
  Spin,
  Table
} from "antd";
import { Content } from "antd/es/layout/layout";
import { Form } from "antd/lib";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { DisplayContext } from "../../Context/displayContext";
import ViewScanResult from "./ViewScanResult";

export default function ComplianceTransactionScanList() {
  const displayContext = useContext(DisplayContext);

  const [filterForm] = Form.useForm();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [count, setCount] = useState<number>();
  const [tableData, setTableData] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 50,
  });
  const [scanResult, setScanResult] = useState<any>();
  const fetchData = useCallback((form?: any, pagination?: any) => {
    const offset =
      !pagination || pagination?.current === 1
        ? undefined
        : pagination?.current * pagination?.pageSize;
    setIsFetchingData(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/risk",
      params: {
        ...form,
        type: "transaction",
        offset: offset || 0,
        pageSize: pagination?.pageSize || 50,
        excludeAuto: "true",
      },
      withCredentials: true,
    })
      .then((res) => {
        setTableData(res.data.result);
        setCount(res.data.total);
        if (!pagination?.current) {
          setPagination({
            current: 1,
            pageSize: pagination?.pageSize || 50,
            total: res.data.count,
          });
        }
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const onReset = useCallback(() => {
    fetchData();
    filterForm.resetFields();
  }, [filterForm, fetchData]);
  const applyFilter = useCallback(() => {
    const form = filterForm.getFieldsValue();
    fetchData(form, {
      current: 0,
      pageSize: pagination?.pageSize,
    });
    setPagination({ ...pagination, current: 1 });
  }, [fetchData, filterForm, pagination]);

  const onPaginationChange = useCallback(
    (pagination: { current?: number; pageSize?: number }) => {
      setPagination(pagination);
      fetchData(filterForm.getFieldsValue(), pagination);
    },
    [filterForm, fetchData],
  );

  
  const columns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      width: 250,
      render: (_: any, { scanJSON }: any) => (
        <Space>
          {scanJSON?.transferDetail?.timestamp
            ? new Date(scanJSON?.transferDetail?.timestamp).toLocaleString(
                "en-US",
                {
                  timeZone: displayContext?.displayContext.timezone,
                  timeZoneName: "short",
                },
              )
            : ""}
        </Space>
      ),
    },
    {
      title: "User",
      dataIndex: "user",
      width: 200,
    },
    {
      title: "Asset",
      dataIndex: "asset",
      width: 75,
    },
    {
      title: "Network",
      dataIndex: "network",
      width: 100,
    },
    {
      title: "Tx",
      dataIndex: "tx",
      width: 250,
    },
    {
      title: "Risk",
      dataIndex: "risk",
      hidden: false,
      sorter: false,
      render: (_: any, { scanJSON }: any) => (
        <Row align={"middle"}>
          <Button
            className={
              scanJSON?.alertsDetail?.alerts?.length
                ? `dcl-risk-button dcl-risk-${scanJSON?.alertsDetail?.alerts?.[0]?.alertLevel?.toLocaleLowerCase()}`
                : "dcl-risk-button dcl-risk-empty"
            }
            onClick={() => {
              setScanResult(scanJSON);
            }}
            shape="circle"
            style={{
              fontWeight: "bold",
            }}
          >
            {scanJSON?.alertsDetail?.alerts?.length
              ? scanJSON?.alertsDetail?.alerts?.[0]?.alertLevel?.[0]
              : "N"}
          </Button>
        </Row>
      ),
      width: 100,
    },
  ];
  return (
    <Content id="transaction-scan-list">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Form
            form={filterForm}
            className="dcl-toggled-content dcl-toggled-content-filter"
          >
            <Form.Item name="transferReference" className="dcl-filter-item">
              <Input placeholder="Transfer Reference" />
            </Form.Item>
            <Row justify="end">
              <Space>
                <b>Total: {count}</b>
                <Button
                  onClick={applyFilter}
                  className="ant-btn-primary"
                  loading={isFetchingData}
                >
                  Apply
                </Button>
                <Button onClick={onReset} loading={isFetchingData}>
                  Reset
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
      </Row>
      {isFetchingData ? (
        <Row justify={"center"} style={{ margin: "12px" }}>
          <Spin />
        </Row>
      ) : (
        <ConfigProvider
         theme={{
             components: {
               Button: {
                colorText: "#fff",
               },
             },
           }}
        >
          <Table
            dataSource={tableData}
            pagination={pagination}
            sticky
            onChange={(pagination) => {
              onPaginationChange(pagination);
            }}
            rowKey={"id"}
            scroll={{ x: 1200 }}
            columns={columns}
          />
        </ConfigProvider>
      )}
      <Modal
        open={scanResult}
        onCancel={() => {
          setScanResult(undefined);
        }}
        onClose={() => {
          setScanResult(undefined);
        }}
        width={"80%"}
      >
        {scanResult ? (
          <ViewScanResult scanResult={scanResult} />
        ) : undefined}
      </Modal>
    </Content>
  );
}
