import { Col, ConfigProvider, Row, Table, Tabs } from "antd";
import dayjs from "dayjs";
import React from "react";

export default function OperationReportTabs({
  previewData: {
    tradeActivities,
    inflowAmounts,
    outflowAmounts,
    startDate,
    endDate,
    assetMap,
    finalAmount,
    walletAddressMap,
    initialAmount,
  },
  theme = "dark",
}: any) {
  const operationDetailColumns = [
    {
      title: "Date",
      dataIndex: "referenceTimestamp",
      key: "referenceTimestamp",
      width: "110px",
      render: (_: any, { referenceTimestamp }: any) =>
        dayjs(referenceTimestamp).format("MMM DD YYYY"),
    },
    {
      title: "CCY",
      dataIndex: "ticker",
      key: "ticker",
      width: "80px",
    },
    {
      title: "In",
      dataIndex: "referenceAmount",
      key: "in",
      align: "right" as const,
      render: (_: any, { referenceAmount }: any, index: number) =>
        referenceAmount > 0
          ? `${
              referenceAmount
                ? referenceAmount.toLocaleString("en-us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 8,
                  })
                : index === 0
                ? ""
                : "0.00"
            }`
          : "",
      width: "150px",
    },
    {
      title: "Out",
      dataIndex: "referenceAmount",
      key: "out",
      align: "right" as const,
      render: (_: any, { referenceAmount }: any, index: number) =>
        referenceAmount < 0
          ? `${
              referenceAmount
                ? Math.abs(referenceAmount).toLocaleString("en-us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 8,
                  })
                : index === 0
                ? ""
                : "0.00"
            }`
          : "",
      width: "150px",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      align: "right" as const,
      render: (_: any, { balance, ticker }: any, index: number) =>
        Number(index) > 0
          ? `${balance.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}`
          : (initialAmount?.[ticker] || 0)?.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            }),
      width: "150px",
    },
    {
      title: "Type",
      dataIndex: "operationItemType",
      key: "typeLabel",
      width: "200px",
    },
    {
      title: "Remarks",
      dataIndex: "opDescription",
      key: "opDescription",
      width: "300px",
      render: (_: any, { opDescription }: any, index: number) =>
        index === 0 ? <b>{opDescription}</b> : opDescription,
    },
    {
      title: "Txid",
      dataIndex: "referenceRealId",
      key: "referenceRealId",
      width: "200px",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "200px",
    },
    {
      title: "Trade ID",
      dataIndex: "opLabel",
      key: "opLabel",
      width: "90px",
      render: (_: any, { opLabel, opId }: any) =>
        opId ? (
          <Row align={"middle"} key={opId}>
            {opLabel}
            {/* <Tooltip title="Detail">
              <Button
                onClick={() => {
                  setShowDetailModal(true);
                  setSelectedOperationId(opId);
                }}
                style={{
                  border: "none",
                  background: "none",
                  boxShadow: "none",
                  padding: "5px",
                }}
              >
                <FontAwesomeIcon icon={"fa-regular fa-eye" as IconProp} />
              </Button>
            </Tooltip> */}
          </Row>
        ) : undefined,
    },
  ];
  const tradeActivitiesColumns = [
    {
      title: "Trade Date",
      dataIndex: "tradeDate",
      key: "tradeDate",
      width: "110px",
      render: (_: any, { tradeDate }: any) =>
        dayjs(tradeDate).format("MMM DD YYYY"),
    },
    {
      title: "Trade ID",
      dataIndex: "tradeId",
      key: "tradeId",
      width: "100px",
    },
    ...(theme !== "dark"
      ? [
          {
            title: "Client DBT #",
            dataIndex: "clientDBT",
            key: "clientDBT",
            width: "100px",
          },
        ]
      : []), // Only show DBT to Employee
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      width: "150px",
    },
    {
      title: "Trade Symbol",
      dataIndex: "symbol",
      key: "symbol",
      width: "100px",
    },
    {
      title: "Base Asset",
      dataIndex: "baseAsset",
      key: "baseAsset",
      width: "100px",
    },
    {
      title: "Quote Asset",
      dataIndex: "quoteAsset",
      key: "quoteAsset",
      width: "100px",
    },
    {
      title: "Base Amount",
      dataIndex: "baseAmount",
      key: "baseAmount",
      width: "150px",
      align: "right" as const,
      render: (_: any, { baseAmount }: any) =>
        baseAmount ? (
          <Row
            style={{
              color:
                baseAmount > 0 ? (theme === "dark" ? "white" : "black") : "red",
            }}
            justify={"end"}
          >
            {baseAmount > 0
              ? baseAmount.toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                })
              : `(${Math.abs(baseAmount).toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                })})`}
          </Row>
        ) : (
          <Row justify={"end"}>0.00</Row>
        ),
    },
    {
      title: "Quote Amount",
      dataIndex: "quoteAmount",
      key: "quoteAmount",
      width: "150px",
      align: "right" as const,
      render: (_: any, { quoteAmount }: any) =>
        quoteAmount ? (
          <Row
            style={{
              color:
                quoteAmount > 0
                  ? theme === "dark"
                    ? "white"
                    : "black"
                  : "red",
            }}
            justify={"end"}
          >
            {quoteAmount > 0
              ? quoteAmount.toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                })
              : `(${Math.abs(quoteAmount).toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                })})`}
          </Row>
        ) : (
          <Row justify={"end"}>0.00</Row>
        ),
    },
    {
      title: "Trade Price",
      dataIndex: "tradePrice",
      key: "tradePrice",
      width: "100px",
      render: (_: any, { tradePrice }: any) =>
        tradePrice?.toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 8,
        }),
    },
  ];
  return (
    <ConfigProvider
      theme={
        theme === "dark"
          ? {
              components: {
                Tabs: {
                  itemActiveColor: "white",
                  itemSelectedColor: "#fff",
                  inkBarColor: "#fff",
                },
                Table: {
                  borderColor: "rgba(255,255,255,0.1)",
                  colorBgBase: "#22293B",
                  headerBg: "#22293B",
                  rowHoverBg: "#14171E",
                  headerSplitColor: "rgba(255,255,255,0.1)",
                },
              },
              token: {
                colorPrimary: "#fffa",
              },
            }
          : undefined
      }
    >
      <Tabs
        items={[
          {
            label: "Trade Activity",
            key: "trade-activity",
            children: (
              <React.Fragment>
                <Table
                  sticky
                  className={theme === "dark" ? "client-table-dark" : undefined}
                  scroll={{
                    x: "max-content",
                  }}
                  dataSource={tradeActivities}
                  columns={tradeActivitiesColumns}
                />
              </React.Fragment>
            ),
          },
          {
            label: "Currency Movements",
            key: "currency-movement",
            children: (
              <React.Fragment>
                <Tabs
                  style={{ margin: "5px" }}
                  items={
                    assetMap
                      ? [
                          ...Object.keys(assetMap)?.map((row: any) => {
                            return {
                              label: row,
                              key: `currency-mvmt-${row}`,
                              children: (
                                <Table
                                  sticky
                                  scroll={{
                                    x: "max-content",
                                  }}
                                  className={
                                    theme === "dark"
                                      ? "client-table-dark"
                                      : undefined
                                  }
                                  dataSource={[
                                    {
                                      key: "initialAmount",
                                      referenceAmount: 0,
                                      referenceRealId: "",
                                      referenceTimestamp:
                                        dayjs(startDate).format(),
                                      ticker: row,
                                      opDescription: "Opening Balance",
                                    },
                                    ...assetMap?.[row],
                                  ]}
                                  columns={operationDetailColumns}
                                  summary={() => {
                                    return (
                                      <Table.Summary key={`${row}-summary`}>
                                        <Table.Summary.Row>
                                          <Table.Summary.Cell
                                            index={3}
                                            colSpan={1}
                                          >
                                            {dayjs(endDate)?.format(
                                              "MMM DD YYYY",
                                            )}
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell
                                            index={2}
                                            colSpan={1}
                                          >
                                            {row}
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell
                                            index={3}
                                            colSpan={1}
                                          ></Table.Summary.Cell>

                                          <Table.Summary.Cell
                                            index={4}
                                            colSpan={1}
                                          />
                                          <Table.Summary.Cell
                                            index={5}
                                            align="right"
                                          >
                                            {finalAmount?.[row]
                                              ? finalAmount?.[
                                                  row
                                                ].toLocaleString("en-us", {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 8,
                                                })
                                              : "0.00"}
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell
                                            index={6}
                                            colSpan={1}
                                          />
                                          <Table.Summary.Cell
                                            index={7}
                                            colSpan={1}
                                          >
                                            <b>Ending Balance</b>
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell
                                            index={8}
                                            colSpan={3}
                                          />
                                        </Table.Summary.Row>
                                      </Table.Summary>
                                    );
                                  }}
                                />
                              ),
                            };
                          }),
                        ]
                      : undefined
                  }
                />
              </React.Fragment>
            ),
          },
          {
            label: "Currency Balance Summary",
            key: "currency-balance-summary",
            children: (
              <React.Fragment>
                <Tabs
                  style={{ margin: "5px" }}
                  items={
                    assetMap
                      ? [
                          ...Object.keys(assetMap)?.map((row: any) => {
                            return {
                              label: row,
                              key: `currency-balance-summary-${row}`,
                              children: (
                                <Table
                                  className={
                                    theme === "dark"
                                      ? "client-table-dark"
                                      : undefined
                                  }
                                  key={`currency-balance-summary-table-${row}`}
                                  columns={[
                                    {
                                      title: "Title",
                                      dataIndex: "operationItemType",
                                      key: "typeLabel",
                                      width: "200px",
                                    },
                                    {
                                      title: "Amount",
                                      dataIndex: "amount",
                                      key: "amount",
                                      align: "right",
                                      width: "200px",
                                      render: (_: any, { amount }: any) =>
                                        amount ? (
                                          <b
                                            style={{
                                              color:
                                                amount > 0
                                                  ? theme === "dark"
                                                    ? "white"
                                                    : "black"
                                                  : "red",
                                            }}
                                          >
                                            {amount > 0
                                              ? amount.toLocaleString("en-us", {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 8,
                                                })
                                              : `(${Math.abs(
                                                  amount,
                                                ).toLocaleString("en-us", {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 8,
                                                })})`}
                                          </b>
                                        ) : (
                                          <b>0.00</b>
                                        ),
                                    },
                                  ]}
                                  dataSource={[
                                    {
                                      key: "initialAmount",
                                      operationItemType:
                                        "Balance as of 1st date of report",
                                      amount: initialAmount?.[row],
                                      ticker: row,
                                    },
                                    {
                                      key: "inflow",
                                      operationItemType: `Add: ${row} Inflow (Deposits and Purchases)`,
                                      amount: inflowAmounts?.[row],
                                      ticker: row,
                                    },
                                    {
                                      key: "outflow",
                                      operationItemType: `Less: ${row} Outflow (Withdrawals and Sales)`,
                                      amount: outflowAmounts?.[row],
                                      ticker: row,
                                    },
                                    {
                                      key: "walletBalance",
                                      operationItemType: `Balance in custody wallet`,
                                      amount: walletAddressMap?.[row]?.length
                                        ? walletAddressMap?.[row]?.reduce(
                                            (
                                              accumulator: number,
                                              currentValue: any,
                                            ) =>
                                              accumulator + currentValue.amount,
                                            0,
                                          )
                                        : 0,
                                      ticker: row,
                                    },
                                    {
                                      key: "final",
                                      operationItemType: `Balance as of end date of report`,
                                      amount:
                                        finalAmount?.[row] +
                                        (walletAddressMap?.[row]?.length
                                          ? walletAddressMap?.[row]?.reduce(
                                              (
                                                accumulator: number,
                                                currentValue: any,
                                              ) =>
                                                accumulator +
                                                currentValue.amount,
                                              0,
                                            )
                                          : 0),
                                      ticker: row,
                                    },
                                  ]}
                                  summary={() => {
                                    return walletAddressMap?.[row]?.length ? (
                                      <Table.Summary>
                                        <Table.Summary.Row>
                                          <Table.Summary.Cell
                                            index={1}
                                            colSpan={1}
                                          >
                                            <Row>
                                              <Col span={10}>
                                                Custody wallet address:
                                              </Col>
                                              <Col span={14}>
                                                {walletAddressMap?.[row]?.map(
                                                  (t: any, idx: number) => (
                                                    <Row
                                                      key={`${t.address}-${idx}`}
                                                    >
                                                      {t.address}
                                                    </Row>
                                                  ),
                                                )}
                                              </Col>
                                            </Row>
                                          </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                      </Table.Summary>
                                    ) : (
                                      <></>
                                    );
                                  }}
                                />
                              ),
                            };
                          }),
                        ]
                      : undefined
                  }
                />
              </React.Fragment>
            ),
          },
        ]}
      />
    </ConfigProvider>
  );
}
