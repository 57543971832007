// import axios from "axios";
import { ConfigProvider } from "antd";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import "./assets/scss/dlc-global.scss";
import "./assets/scss/otp.scss";
import CreateAsset from "./components/AdminComponents/AssetPages/CreateAsset";
import EditAsset from "./components/AdminComponents/AssetPages/EditAsset";
import SupportedAssetList from "./components/AdminComponents/AssetPages/SupportedAssetList";
import ViewAssetPriceList from "./components/AdminComponents/AssetPages/ViewAssetPrice";
import ViewAssetSettingContainer from "./components/AdminComponents/AssetPages/ViewAssetSettingContainer";
import CreateClient from "./components/AdminComponents/ClientPages/CreateClient";
import UpdateClient from "./components/AdminComponents/ClientPages/UpdateClient";
import ViewClients from "./components/AdminComponents/ClientPages/ViewClients";
// import ComplianceAddressScan from "./components/AdminComponents/CompliancePages/ComplianceAddressScan";
import ComplianceAddressScanContainer from "./components/AdminComponents/CompliancePages/ComplianceAddressScanContainer";
import ComplianceTransactionScanContainer from "./components/AdminComponents/CompliancePages/ComplianceTransactionScanContainer";
import ComplianceWhitelist from "./components/AdminComponents/CompliancePages/ComplianceWhitelist";
import CustodyReport from "./components/AdminComponents/CustodyPages/CustodyReport";
import CustodyTransaction from "./components/AdminComponents/CustodyPages/CustodyTransaction";
import ViewEbankingBalanceContainer from "./components/AdminComponents/EbankingPages/ViewEbankingBalanceContainer";
import ViewEBankingLedger from "./components/AdminComponents/EbankingPages/ViewEBankingLedger";
import AssignDefaultFeePolicy from "./components/AdminComponents/FeePages/AssginDefaultFeePolicy";
import CreateFeePolicy from "./components/AdminComponents/FeePages/CreateFeePolicy";
import UpdateFeePolicy from "./components/AdminComponents/FeePages/UpdateFeePolicy";
import ViewFeePolicyList from "./components/AdminComponents/FeePages/ViewFeePolicyList";
import ViewLoans from "./components/AdminComponents/FinancialServicePages/ViewLoans";
import ViewTechLogs from "./components/AdminComponents/LogPages/ViewTechLogs";
import DclQuoteContainer from "./components/AdminComponents/MasterLedgerPage/OrderBuilderComponents/DclQuoteContainer";
import EditDclQuote from "./components/AdminComponents/MasterLedgerPage/OrderBuilderComponents/EditDclQuote";
import ViewMasterLedger from "./components/AdminComponents/MasterLedgerPage/ViewMasterLedger";
import EditOperationDetails from "./components/AdminComponents/OperationPages/EditOperationDetails";
import OperationBuilder from "./components/AdminComponents/OperationPages/OperationBuilder";
import OperationContainer from "./components/AdminComponents/OperationPages/OperationContainer";
import OperationReportContainer from "./components/AdminComponents/OperationPages/OperationReportContainer";
import ViewAssetVenueMap from "./components/AdminComponents/SettingPages/AssetVenuePages/ViewAssetVenueMap";
import DSPSettings from "./components/AdminComponents/SettingPages/DSPSettings";
import ViewDspTermsPageContainer from "./components/AdminComponents/SettingPages/DspTermPages/ViewDspTermsContainer";
import EditRolePrivileges from "./components/AdminComponents/SettingPages/EditRolePrivileges";
import EmailSettings from "./components/AdminComponents/SettingPages/EmailSetting";
import MenuSettings from "./components/AdminComponents/SettingPages/MenuSetting";
import TechSettings from "./components/AdminComponents/SettingPages/TechSettings";
import TelegramBackupContainer from "./components/AdminComponents/SettingPages/TelegramPages/TelegramBackupContainer";
import TelegramBroadcastContainer from "./components/AdminComponents/SettingPages/TelegramPages/TelegrampBroadcastContainer";
import ViewCapabilityList from "./components/AdminComponents/SettingPages/ViewCapability";
import StakingReport from "./components/AdminComponents/StakingPages/StakingReport";
import CreateUser from "./components/AdminComponents/UserPages/CreateUser";
import UpdateUser from "./components/AdminComponents/UserPages/UpdateUser";
import ViewUsers from "./components/AdminComponents/UserPages/ViewUsers";
import ViewDelchainBalances from "./components/AdminComponents/VenuePages/BalancePages/ViewDelchainBalance";
import CreateVenue from "./components/AdminComponents/VenuePages/CreateVenue";
import EditVenue from "./components/AdminComponents/VenuePages/EditVenue";
import SubaccountTransferContainer from "./components/AdminComponents/VenuePages/SubAccountTransferPages/SubccountTransferContainer";
import ViewVenueTradingReportContainer from "./components/AdminComponents/VenuePages/TradingReport/TradingReportContainer";
import ViewVenueAccounts from "./components/AdminComponents/VenuePages/ViewVenueAccounts";
import ViewVenueDepositAddresses from "./components/AdminComponents/VenuePages/ViewVenueDepositAddresses";
import ViewVenueList from "./components/AdminComponents/VenuePages/ViewVenueList";
import ViewVenueMarket from "./components/AdminComponents/VenuePages/ViewVenueMarket";
import ViewVenueTransactions from "./components/AdminComponents/VenuePages/ViewVenueTransactions";
import ViewVenueUploads from "./components/AdminComponents/VenuePages/ViewVenueUpload";
import CreateNewWallet from "./components/AdminComponents/WalletPages/CreateNewWallet";
import EditWallet from "./components/AdminComponents/WalletPages/EditWallet";
import WalletInfo from "./components/AdminComponents/WalletPages/ViewWalletInfo";
import ViewWalletsContainer from "./components/AdminComponents/WalletPages/ViewWalletsContainer";
import ViewWalletTransactions from "./components/AdminComponents/WalletPages/ViewWalletTransactions";
import ClientCustodyReport from "./components/ClientFacingComponents/ClientCustodyReport";
import ClientCustodyTransactions from "./components/ClientFacingComponents/ClientCustodyTransactions";
import ClientDashboard from "./components/ClientFacingComponents/ClientDashboard";
import ClientLedgerTransactions from "./components/ClientFacingComponents/ClientLedgerTransactions";
import ClientReports from "./components/ClientFacingComponents/ClientReport";
import ClientStakingReport from "./components/ClientFacingComponents/ClientStakingReport";
import MyCompanyProfile from "./components/ClientFacingComponents/MyCompanyProfile";
import MyProfile from "./components/ClientFacingComponents/MyProfile";
import ConfirmationPage from "./components/ConfirmationPage";
import {
  DisplayContext,
  IDisplayContext,
} from "./components/Context/displayContext";
import { ITitleContext, TitleContext } from "./components/Context/titleContext";
import { IUser, UserContext } from "./components/Context/userContext";
import Dashboard from "./components/Dashboard/Dashboard";
import ProtectedLayout from "./components/Layouts/ProtectectLayout";
import ChangePassword from "./components/Login/ChangePwd";
import Login from "./components/Login/Login";
import LoginTroubleContainer from "./components/Login/LoginTrouble/LoginTroubleContainer";
import ViewChangelog from "./components/ViewChangeLog";
import ClientInfo from "./components/ViewInfoComponents/ClientInfo";
import UserInfo from "./components/ViewInfoComponents/UserInfo";

function App() {
  const [user, setUser] = useState<IUser>({
    id: 0,
    email: "",
    roleId: 0,
    capabilities: [],
    fullname: "",
    roleName: "",
  });
  const [displayContext, setDisplayContext] = useState<IDisplayContext>({
    timezone: "US/Eastern",
  });
  const [titleContext, setTitleContext] = useState<ITitleContext>({
    title: "",
    helperText: "",
  });
  const [userFilter, setUserFilter] = useState<any>();
  const [userDisplay, setUserDisplay] = useState<any>();
  // const [assetFilter, setAssetFilter] = useState<any>();
  // const [assetDisplay, setAssetDisplay] = useState<any>();
  const [clientDisplay, setClientDisplay] = useState<any>();
  const [clientFilter, setClientFilter] = useState<any>();
  useEffect(() => {
    // console.log(process.env.REACT_APP_DSP_ENV_NAME);
    const handleTabClose = (event: any) => {
      event.stopImmediatePropagation();
    };

    const handleTabClosing = async (event: any) => {
      event.stopImmediatePropagation();
    };

    window.addEventListener("beforeunload", handleTabClose);
    window.addEventListener("unload", handleTabClosing);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
      window.removeEventListener("unload", handleTabClosing);
    };
  }, []);

  return (
    <div className="App">
      <React.StrictMode>
        <ToastContainer
          position={"top-right"}
          hideProgressBar={false}
          closeOnClick={true}
          draggable={false}
          autoClose={3000}
          theme={"colored"}
        />
        <ConfigProvider
          theme={{
            components: {
              Modal: {
                contentBg: "#0e3654",
              },
            },
            token: {
              colorPrimary: "#19bdc2",
              fontFamily: `"Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif`,
            },
          }}
        >
          <UserContext.Provider value={{ user, setUser }}>
            <DisplayContext.Provider
              value={{ displayContext, setDisplayContext }}
            >
              <TitleContext.Provider value={{ titleContext, setTitleContext }}>
                <BrowserRouter>
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/login/issues"
                      element={<LoginTroubleContainer />}
                    />
                    <Route
                      path="change-pwd/:username"
                      element={<ChangePassword />}
                    />
                    <Route path="bo" element={<ProtectedLayout />}>
                      <Route path="asset">
                        <Route path="create" element={<CreateAsset />} />
                        <Route path="update/:assetId" element={<EditAsset />} />
                      </Route>
                      <Route path="changelog" element={<ViewChangelog />} />
                      <Route path="scan-tools">
                        <Route
                          path="address-scan"
                          element={<ComplianceAddressScanContainer />}
                        />
                        <Route
                          path="address-scan/:tabName"
                          element={<ComplianceAddressScanContainer />}
                        />
                        <Route
                          path="transaction-scan"
                          element={<ComplianceTransactionScanContainer />}
                        />
                        <Route
                          path="transaction-scan/:tabName"
                          element={<ComplianceTransactionScanContainer />}
                        />
                        <Route
                          path="whitelist"
                          element={<ComplianceWhitelist />}
                        />
                      </Route>
                      <Route
                        path="dashboard"
                        element={
                          <Dashboard pageType="Back Office  (Just for identification, will remove later)" />
                        }
                      />
                      <Route
                        path="delchain_master_ledger"
                        element={<ViewMasterLedger />}
                      />
                      <Route
                        path="delchain_master_ledger/:tabName"
                        element={<ViewMasterLedger />}
                      />
                      <Route path="ebanking">
                        <Route path="ledger" element={<ViewEBankingLedger />} />
                        <Route
                          path="ledger/:clientAccountId"
                          element={<ViewEBankingLedger />}
                        />
                        <Route
                          path="balance"
                          element={<ViewEbankingBalanceContainer />}
                        />
                        <Route
                          path="balance/:tabName"
                          element={<ViewEbankingBalanceContainer />}
                        />
                      </Route>
                      <Route path="fee">
                        <Route
                          path="default-policies"
                          element={<AssignDefaultFeePolicy />}
                        />
                        <Route
                          path="policy-list"
                          element={<ViewFeePolicyList />}
                        />
                        <Route
                          path="create-policy"
                          element={<CreateFeePolicy />}
                        />
                        <Route
                          path="update-policy/:feePolicyId"
                          element={<UpdateFeePolicy />}
                        />
                      </Route>
                      <Route path="financial-services">
                        <Route path="custody">
                          <Route path="report" element={<CustodyReport />} />
                          <Route
                            path="transactions"
                            element={<CustodyTransaction />}
                          />
                        </Route>
                        <Route
                          path="loans"
                          element={<ViewLoans clientFacing={false} />}
                        />
                        <Route path="staking">
                          <Route path="report" element={<StakingReport />} />
                        </Route>
                      </Route>
                      <Route path="logs">
                        <Route path="tech_logs" element={<ViewTechLogs />} />
                      </Route>
                      <Route
                        path="operation/view"
                        element={<OperationContainer />}
                      />

                      <Route
                        path="operation/view/:tabName"
                        element={<OperationContainer />}
                      />
                      <Route
                        path="operation/builder"
                        element={<OperationBuilder />}
                      />
                      <Route
                        path="operation/builder/:tabName"
                        element={<OperationBuilder />}
                      />
                      <Route
                        path="operation/report"
                        element={<OperationReportContainer />}
                      />

                      <Route
                        path="operation/report/:tabName"
                        element={<OperationReportContainer />}
                      />
                      <Route
                        path="operation-edit/:operationId"
                        element={<EditOperationDetails />}
                      />
                      <Route path="settings">
                        <Route
                          path="assets"
                          element={<ViewAssetSettingContainer />}
                        />
                        <Route
                          path="assets/:tabName"
                          element={<ViewAssetSettingContainer />}
                        />
                        <Route
                          path="asset-venue-map"
                          element={<ViewAssetVenueMap />}
                        />
                        <Route
                          path="capabilities"
                          element={<ViewCapabilityList />}
                        />
                        <Route path="" element={<DSPSettings />} />
                        <Route
                          path="tech-settings"
                          element={<TechSettings />}
                        />
                        <Route
                          path="tech-settings/:tabName"
                          element={<TechSettings />}
                        />
                        <Route
                          path="telegram-broadcast"
                          element={<TelegramBroadcastContainer />}
                        />
                        <Route
                          path="telegram-backup"
                          element={<TelegramBackupContainer />}
                        />
                        <Route
                          path="telegram-backup/:tabName"
                          element={<TelegramBackupContainer />}
                        />
                        <Route
                          path="telegram-broadcast/:tabName"
                          element={<TelegramBroadcastContainer />}
                        />

                        <Route
                          path="privileges"
                          element={<EditRolePrivileges />}
                        />
                        <Route path="emails" element={<EmailSettings />} />
                        <Route
                          path="dsp_terms"
                          element={<ViewDspTermsPageContainer />}
                        />
                        <Route
                          path="dsp_terms/:tabName"
                          element={<ViewDspTermsPageContainer />}
                        />
                        <Route path="venues">
                          <Route path="" element={<ViewVenueList />} />
                          <Route path="edit/:venueId" element={<EditVenue />} />
                          <Route path="create" element={<CreateVenue />} />
                        </Route>
                        <Route path="menus" element={<MenuSettings />} />
                      </Route>
                      <Route path="venues">
                        <Route
                          path="asset_prices"
                          element={<ViewAssetPriceList />}
                        />
                        <Route
                          path="delchain_balances"
                          element={<ViewDelchainBalances />}
                        />
                        <Route
                          path="delchain_balances/:tabName"
                          element={<ViewDelchainBalances />}
                        />
                        <Route
                          path="delchain_transactions"
                          element={<ViewVenueTransactions />}
                        />
                        <Route
                          path="delchain_transactions/:tabName"
                          element={<ViewVenueTransactions />}
                        />
                        <Route path="market" element={<ViewVenueMarket />} />
                        <Route
                          path="market/:tabName"
                          element={<ViewVenueMarket />}
                        />
                        <Route
                          path="trading_report"
                          element={<ViewVenueTradingReportContainer />}
                        />
                        <Route
                          path="trading_report/:tabName"
                          element={<ViewVenueTradingReportContainer />}
                        />
                        <Route path="upload" element={<ViewVenueUploads />} />
                        <Route
                          path="deposit_addresses"
                          element={<ViewVenueDepositAddresses />}
                        />
                        <Route
                          path="venue_accounts"
                          element={<ViewVenueAccounts />}
                        />
                        <Route
                          path="subaccount_transfer"
                          element={<SubaccountTransferContainer />}
                        />
                        <Route
                          path="subaccount_transfer/:tabName"
                          element={<SubaccountTransferContainer />}
                        />

                        <Route
                          path="quote_builder"
                          element={<DclQuoteContainer />}
                        />
                        <Route
                          path="quote_builder/edit/:dclOrderId"
                          element={<EditDclQuote />}
                        />
                        <Route
                          path="quote_builder/:tabName"
                          element={<DclQuoteContainer />}
                        />
                      </Route>
                      <Route
                        path="wallets/transactions"
                        element={<ViewWalletTransactions />}
                      />
                      <Route
                        path="wallets"
                        element={<ViewWalletsContainer />}
                      />
                      <Route
                        path="wallets/list"
                        element={<ViewWalletsContainer />}
                      />
                      <Route
                        path="wallets/list/:tabName"
                        element={<ViewWalletsContainer />}
                      />
                      <Route
                        path="wallet/create"
                        element={<CreateNewWallet />}
                      />
                      <Route
                        path="wallet/view/:walletId"
                        element={<WalletInfo />}
                      />
                      <Route
                        path="wallet/update/:walletId"
                        element={<EditWallet />}
                      />
                      <Route
                        path="confirmation"
                        element={<ConfirmationPage />}
                      />
                      <Route path="user/create" element={<CreateUser />} />
                      <Route
                        path="user/update/:encodedId"
                        element={<UpdateUser />}
                      />
                      <Route
                        path="user/info/:encodedId"
                        element={<UserInfo />}
                      ></Route>
                      <Route path="client/create" element={<CreateClient />} />
                      <Route
                        path="client/update/:clientId"
                        element={<UpdateClient />}
                      />

                      <Route
                        path="users"
                        element={
                          <ViewUsers
                            filter={userFilter}
                            onFilterChange={(filter: any) => {
                              setUserFilter(filter);
                            }}
                            userDisplay={userDisplay}
                            onDisplayChange={(columns: any) => {
                              setUserDisplay(columns);
                            }}
                          />
                        }
                      />
                      <Route
                        path="clients"
                        element={
                          <ViewClients
                            filter={clientFilter}
                            onFilterChange={(filter: any) => {
                              setClientFilter(filter);
                            }}
                            clientDisplay={clientDisplay}
                            onDisplayChange={(columns: any) => {
                              setClientDisplay(columns);
                            }}
                          />
                        }
                      />
                      <Route
                        path="client/info/:clientId"
                        element={<ClientInfo />}
                      />
                      <Route
                        path="suppported-assets"
                        element={<SupportedAssetList clientFacing={true} />}
                      />
                    </Route>
                    <Route path="client" element={<ProtectedLayout />}>
                      <Route path="dashboard" element={<ClientDashboard />} />
                      <Route
                        path="my_profile/:encodedId"
                        element={<MyProfile />}
                      />
                      <Route
                        path="my_company_profile/:clientId"
                        element={<MyCompanyProfile />}
                      />
                      <Route
                        path="custody"
                        element={<ClientCustodyTransactions />}
                      />
                      <Route
                        path="custody/report"
                        element={<ClientCustodyReport />}
                      />
                      <Route
                        path="staking/report"
                        element={<ClientStakingReport />}
                      />
                      <Route
                        path="ledger/transactions"
                        element={<ClientLedgerTransactions />}
                      />
                      <Route
                        path="loans"
                        element={<ViewLoans clientFacing={true} />}
                      />
                      <Route
                        path="asset_prices"
                        element={<ViewAssetPriceList canExport={false} />}
                      />
                      <Route path="reports" element={<ClientReports />} />
                      <Route
                        path="suppported-assets"
                        element={<SupportedAssetList clientFacing={true} />}
                      />
                    </Route>
                    <Route path="*" element={<ProtectedLayout />} />
                  </Routes>
                </BrowserRouter>
              </TitleContext.Provider>
            </DisplayContext.Provider>
          </UserContext.Provider>
        </ConfigProvider>
      </React.StrictMode>
    </div>
  );
}

export default App;
