import { ConfigProvider, Layout, Menu } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const { Sider } = Layout;

function SideBar({
  items,
  handleMenuClick,
  routeMap,
  collapsed,
  onCollapse,
}: any) {
  function findValueInMap(map: Map<any, any>, targetValue: string) {
    if (!targetValue) return false;
    const withoutTab = targetValue.split("/").slice(0, -1).join("/");
    for (const [key, value] of map) {
      if (value === targetValue) {
        return key; // or the value itself, or the corresponding key, depending on your requirement
      }
    }
    for (const [key, value] of map) {
      if (value === withoutTab) {
        return key; // or the value itself, or the corresponding key, depending on your requirement
      }
    }
    return false; // or any other appropriate value to indicate that the targetValue was not found
  }

  const findParentKey = useCallback(
    (targetKey: string) => {
      for (const item of items) {
        // Check if this item has children
        if (item.children && item.children.length > 0) {
          // Check if any of the children has the targetKey
          const foundChild = item.children.some(
            (child: any) => child.key === targetKey,
          );
          if (foundChild) {
            // Return the parent key if a child matches
            return item.key;
          }
        }
      }
      return null; // Return null if no parent is found
    },
    [items],
  );

  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "client/dashboard"
      : location.pathname,
  );
  const [currentKeys, setCurrentKeys] = useState<any>();
  const [openKeys, setOpenKeys] = useState<any>();
  // const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (collapsed) {
      setCurrentKeys([]);
      setOpenKeys([]);
      return;
    }
    const keys = [];
    if (location) {
      const tempKey = findValueInMap(routeMap, location.pathname);
      const parentKey = findParentKey(tempKey); // Use the new function here
      if (tempKey) keys.push(tempKey);
      setCurrentKeys(keys);
      setOpenKeys(parentKey ? [parentKey] : []);
      setCurrentPath(location.pathname);
    }
  }, [collapsed, location, currentPath, routeMap, findParentKey]);

  return (
    <Sider
      breakpoint="lg"
      collapsible
      collapsedWidth={0}
      collapsed={collapsed}
      onCollapse={onCollapse}
      className="sidebar"
      trigger={null}
      style={{ borderRight: "1px rgba(255,255,255,0.2) solid" }}
    >
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              darkItemSelectedBg: "#22293B",
              darkItemColor: "rgba(255, 255, 255, 0.8)",
              darkItemSelectedColor: "#fff",
              darkItemBg: "#14171E",
              itemBorderRadius: 300,
              subMenuItemBg: "#14171E",
              popupBg: "#14171E",
            },
          },
        }}
      >
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          selectedKeys={currentKeys}
          openKeys={openKeys}
          onOpenChange={(e) => {
            setOpenKeys(e);
          }}
          mode="inline"
          items={items}
          onClick={(info) => handleMenuClick(info)}
        />
      </ConfigProvider>
    </Sider>
  );
}

export default SideBar;
