import { Row } from "antd";

export default function ComingSoon() {
  return (
    <Row
      justify={"center"}
      style={{
        marginTop: "5em",
      }}
    >
      <h2>Coming Soon...</h2>
    </Row>
  );
}
