import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Row, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";

export default function ClientDashboard() {
  const gridStyleLeft: React.CSSProperties = {
    width: "45%",
    textAlign: "left",
    borderRight: "1px rgba(255,255,255,0.2) solid",
    borderTop: "1px rgba(255,255,255,0.2) solid",
  };
  const gridStyleRight: React.CSSProperties = {
    width: "55%",
    textAlign: "left",
    borderTop: "1px rgba(255,255,255,0.2) solid",
  };

  const [assetDataList, setAssetDataList] = useState<any[]>([]);
  const [assetDataFetched, setAssetDataFetched] = useState<boolean>(false);
  const [walletAumDataFetched, setWalletAumDataFetched] =
    useState<boolean>(false);
  const [walletAucData, setWalletAucData] = useState<any[]>([]);
  const [walletAusData, setWalletAusData] = useState<any[]>([]);
  const [custodyWalletCount, setCustodyWalletCount] = useState<number>();
  const [stakingWalletCount, setStakingWalletCount] = useState<number>();

  const getWalletAssetUnderManagement = useCallback(() => {
    setWalletAumDataFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallet/aum",
      withCredentials: true,
    })
      .then((res) => {
        setCustodyWalletCount(res.data.activeCustodyWalletCount);
        setStakingWalletCount(res.data.activeStakingWalletCount);
        setWalletAucData([
          {
            label: "Total AUC:",
            key: "totalAuc",
            value: res.data.walletAuc,
          },
          {
            label: "# Active Custody Wallets:",
            key: "activeCustodyWalletCount",
            value: res.data.activeCustodyWalletCount,
          },
        ]);
        setWalletAusData([
          {
            label: "Total AUS:",
            key: "totalAuc",
            value: res.data.walletAus,
          },
          {
            label: "# Active Staking Wallets:",
            key: "activeStakingWalletCount",
            value: res.data.activeStakingWalletCount,
          },
        ]);
        // setWalletAus(res.data.walletAus);
        // setActiveCsdySubWalletCount(res.data.activeCustodySubsalletsCount);
        // setActiveStkgSubWalletCount(res.data.activeStakingSubwalletsCount);
      })
      .catch(() => {})
      .finally(() => {
        setWalletAumDataFetched(true);
      });
  }, []);

  const getAssetInfo = useCallback(() => {
    setAssetDataFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/dashboard",
      withCredentials: true,
    })
      .then((res) => {
        setAssetDataList([
          {
            label: "Crypto Assets",
            key: "cryptoAssets",
            value: res.data.cryptoCount,
          },
          {
            label: "Fiat Assets",
            key: "fiatAssets",
            value: res.data.fiatCount,
          },
          {
            label: "Asset Networks",
            key: "assetNetworks",
            value: res.data.assetNetworkCount,
          },
        ]);
      })
      .finally(() => {
        setAssetDataFetched(true);
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    getAssetInfo();
  }, [getAssetInfo]);
  useEffect(() => {
    getWalletAssetUnderManagement();
  }, [getWalletAssetUnderManagement]);

  const [clientLoans, setClientLoans] = useState<any[]>([]);
  const [isClientLoanLoaded, setIsClientLoanLoaded] = useState<boolean>(false);

  const fetchClientLoans = useCallback(() => {
    setIsClientLoanLoaded(false);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/bo/financial-services/loans",
      withCredentials: true,
    })
      .then((res) => {
        setClientLoans(res.data?.clientLoans);
      })
      .finally(() => {
        setIsClientLoanLoaded(true);
      });
  }, []);
  useEffect(() => {
    fetchClientLoans();
  }, [fetchClientLoans]);
  return (
    <Content id="client-dashboard">
      <Row>
        <Col lg={8} md={24} sm={24} xs={24}>
          <Card
            title={
              <Row>
                <Col span={8}>Assets</Col>
              </Row>
            }
            extra={
              <FontAwesomeIcon
                icon={"fa-solid fa-dollar-sign" as IconProp}
                size={"lg"}
              />
            }
            bordered={false}
            loading={!assetDataFetched}
            style={{
              margin: "10px",
              borderRadius: "10px",
            }}
            styles={{
              body: {
                padding: 0,
              },
            }}
          >
            {assetDataList?.map((row) => (
              <div key={row.key} style={{ display: "flex", width: "100%" }}>
                <Card.Grid style={gridStyleLeft}>
                  <b>{row.label}</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>{row.value}</Card.Grid>
              </div>
            ))}
          </Card>
        </Col>
        <Col lg={8} md={24} sm={24} xs={24}>
          <Card
            title={
              <Row>
                <Col span={8}>Custody</Col>
              </Row>
            }
            bordered={false}
            extra={
              <FontAwesomeIcon
                icon={"fa-solid fa-vault" as IconProp}
                size={"lg"}
              />
            }
            loading={!walletAumDataFetched}
            style={{
              margin: "10px",
              borderRadius: "10px",
            }}
            styles={{
              body: { padding: 0 },
            }}
          >
            {custodyWalletCount ? (
              walletAucData?.map((row) => (
                <div key={row.key} style={{ display: "flex", width: "100%" }}>
                  <Card.Grid style={gridStyleLeft}>
                    <b>{row.label}</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>{row.value}</Card.Grid>
                </div>
              ))
            ) : (
              <div style={{ display: "flex", width: "100%" }}>
              <Card.Grid
                style={{
                  width: "100%",
                  borderTop: "1px rgba(255,255,255,0.2) solid",
                  borderBottomRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
              >
                <Row
                  justify={"center"}
                  style={{
                    padding: "24px",
                  }}
                >
                  <b>No Custody Wallets</b>
                </Row>
              </Card.Grid>
            </div>
            )}
          </Card>
        </Col>
        <Col lg={8} md={24} sm={24} xs={24}>
          <Card
            title={
              <Row>
                <Col span={8}>Staking</Col>
              </Row>
            }
            bordered={false}
            extra={
              <FontAwesomeIcon
                icon={"fa-solid fa-vault" as IconProp}
                size={"lg"}
              />
            }
            loading={!walletAumDataFetched}
            style={{
              margin: "10px",
              borderRadius: "10px",
            }}
            styles={{
              body: { padding: 0 },
            }}
          >
            {stakingWalletCount ? (
              walletAusData?.map((row) => (
                <div key={row.key} style={{ display: "flex", width: "100%" }}>
                  <Card.Grid style={gridStyleLeft}>
                    <b>{row.label}</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>{row.value}</Card.Grid>
                </div>
              ))
            ) : (
              <div style={{ display: "flex", width: "100%" }}>
                <Card.Grid
                  style={{
                    width: "100%",
                    borderTop: "1px rgba(255,255,255,0.2) solid",
                    borderBottomRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                >
                  <Row
                    justify={"center"}
                    style={{
                      padding: "24px",
                    }}
                  >
                    <b>No Staking Wallets</b>
                  </Row>
                </Card.Grid>
              </div>
            )}
          </Card>
        </Col>

        <Col lg={8} md={24} sm={24} xs={24}>
          <Card
            title={
              <Row>
                <Col span={8}>Client Loans</Col>
              </Row>
            }
            extra={
              <FontAwesomeIcon
                icon={"fa-solid fa-hand-holding-dollar" as IconProp}
                size={"lg"}
              />
            }
            bordered={false}
            loading={!isClientLoanLoaded}
            style={{
              margin: "10px",
              borderRadius: "10px",
            }}
            styles={{
              body: {
                padding: 0,
              },
            }}
          >
            {clientLoans?.length ? (
              clientLoans.map((clientLoan, i) => (
                <Card.Grid
                  style={{
                    width: "100%",
                    borderTop: "1px rgba(255,255,255,0.2) solid",
                  }}
                  key={i}
                >
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Row>
                          <b>Collateral Amount:&nbsp;</b>
                          {`${Number(
                            clientLoan?.collateralAmount || 0,
                          )?.toLocaleString("en-us")} ${
                            clientLoan?.collateralAsset?.ticker
                          }`}
                        </Row>
                        <Row>
                          <b>Loan Amount:&nbsp;</b>
                          {`${Number(
                            clientLoan?.loanAmount || 0,
                          )?.toLocaleString("en-us")} ${
                            clientLoan?.loanAsset?.ticker
                          }`}
                        </Row>
                        <Row>
                          <b>Loan EndDate:&nbsp;</b>
                          {clientLoan?.loanEndDate}
                        </Row>
                      </React.Fragment>
                    }
                  >
                    <i className={`dcl_state ${clientLoan.status}`} />
                  </Tooltip>
                  <b>{clientLoan.clientName}</b>
                </Card.Grid>
              ))
            ) : (
              <div style={{ display: "flex", width: "100%" }}>
                <Card.Grid
                  style={{
                    width: "100%",
                    borderTop: "1px rgba(255,255,255,0.2) solid",
                    borderBottomRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                >
                  <Row
                    justify={"center"}
                    style={{
                      padding: "24px",
                    }}
                  >
                    <b>No Loans</b>
                  </Row>
                </Card.Grid>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </Content>
  );
}
