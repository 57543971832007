import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Spin,
  Switch,
} from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { tailFormItemLayout } from "../UserPages/CreateUser";

function EditAsset() {
  const location = useLocation();
  const navigate = useNavigate();
  const { assetId } = useParams();
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [assetTypeList, setAssetTypeList] = useState<any[]>();
  const [assetApiUrlTypeList, setAssetApiUrlTypeList] = useState<any[]>();
  const [assetApiUrlEnvironmentsList, setAssetApiUrlEnvironmentsList] =
    useState<any[]>();
  const [assetNetworkList, setApiNetworkList] = useState<any>();
  const [custodyFeeTypeId, setCustodyFeeTypeId] = useState<number>();
  const [stakingFeeTypeId, setStakingFeeTypeId] = useState<number>();
  const [tradingFeeTypeId, setTradingFeeTypeId] = useState<number>();
  const [custodyeePolicyList, setCustodyFeePolicyList] = useState<any[]>();
  const [stakingFeePolicyList, setStakingFeePolicyList] = useState<any[]>();
  const [tradingFeePolicyList, setTradingFeePolicyList] = useState<any[]>();
  const [isFiat, setIsFiat] = useState<boolean>(true);
  const [ApiUrls, setApiUrls] = useState<any[]>([]);
  const [toDeletepiUrls] = useState<any[]>([]);
  const [form] = useForm();

  const [venuePairs, setVenuePairs] = useState<any[]>([]);
  const [venueOptions, setVenueOptions] = useState<any[]>();
  const [assetOptions, setAssetOptions] = useState<any>();

  const [filteredVenuePairs, setFilteredVenuePairs] = useState<any[]>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [venuePairAssetFilter, setVenuePairAssetFilter] = useState<number[]>();
  const [venuePairVenueFilter, setVenuePairVenueFilter] = useState<number[]>();

  const [assetTickerVenuePairList, setAssetTickerVenuePairList] = useState<
    any[]
  >([]);

  const [selectedNetworks, setSelectedNetworks] = useState<any[]>([]);
  const [selectedNetworkOptions, setSelectedNetworkOptions] = useState<any[]>(
    [],
  );
  useEffect(() => {
    const filterdAfterAsset =
      venuePairAssetFilter && venuePairAssetFilter.length
        ? venuePairs.filter((t) =>
            venuePairAssetFilter.includes(t.counterCurrencyId),
          )
        : venuePairs;
    const filterAfterVenue =
      venuePairVenueFilter && venuePairVenueFilter.length
        ? filterdAfterAsset.filter((t) =>
            venuePairVenueFilter.includes(t.venueId),
          )
        : filterdAfterAsset;
    setFilteredVenuePairs(filterAfterVenue);
  }, [venuePairs, venuePairAssetFilter, venuePairVenueFilter]);

  const fetchAssetOptions = useCallback(() => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        setAssetOptions(options);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, []);
  const fetchVenueOptions = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
    })
      .then((res) => {
        setVenueOptions(res.data.list);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  const fetchAssetVenuePairList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset-venue/",
      params: {
        assetId: assetId,
      },
      withCredentials: true,
    })
      .then((res) => {
        setAssetTickerVenuePairList(res.data.assetVenueList);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [assetId, navigate]);

  useEffect(() => {
    fetchAssetOptions();
    fetchVenueOptions();
  }, [fetchAssetOptions, fetchVenueOptions]);

  const addNewApiURL = useCallback(() => {
    const newList = [...ApiUrls];
    newList.push({
      id: new Date().getUTCMilliseconds(),
      apiTypeId: 1,
      assetNetworkId:
        selectedNetworks && selectedNetworks.length
          ? selectedNetworks[0]
          : undefined,
      method: "GET",
      dictionaries: [{ id: 0, key: "", value: "" }],
      newlyCreated: true,
    });
    setApiUrls(newList);
  }, [ApiUrls, selectedNetworks]);

  const addNewPair = useCallback(() => {
    venuePairs.push({
      venueId: undefined,
      counterCurrencyId: undefined,
      pair: "",
      type: "tickerPrice",
    });
    setVenuePairs([...venuePairs]);
  }, [venuePairs]);

  const updateVenuePairField = useCallback(
    (venuePair: any, field: string, value: number | string) => {
      const index = venuePairs.indexOf(venuePair);
      venuePairs[index][field] = value;
      setVenuePairs([...venuePairs]);
    },
    [venuePairs],
  );

  const deletePair = useCallback(
    (pair: any) => {
      const newPairs = [...venuePairs];
      const index = newPairs.indexOf(pair);
      newPairs.splice(index, 1);
      setVenuePairs(newPairs);
    },
    [venuePairs],
  );

  const updateATVPairValue = useCallback(
    (
      venuePair: any,
      field: string,
      value: number | string | { toFetch: boolean; ticker: string },
    ) => {
      const index = assetTickerVenuePairList.indexOf(venuePair);
      assetTickerVenuePairList[index][field] = value;
      setAssetTickerVenuePairList([...assetTickerVenuePairList]);
    },
    [assetTickerVenuePairList],
  );

  const deleteAtvPair = useCallback(
    (pair: any) => {
      const newPairs = [...assetTickerVenuePairList];
      const index = newPairs.indexOf(pair);
      newPairs.splice(index, 1);
      setAssetTickerVenuePairList(newPairs);
    },
    [assetTickerVenuePairList],
  );

  const fetchAssetTypeList = useCallback(() => {
    setOnFinishLoad(false);

    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/asset-type",
      withCredentials: true,
    })
      .then((res) => {
        setAssetTypeList(res.data);
      })
      .catch((err) => {
        console.error("Failed to fetch asset type", err);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, []);

  const fetchFeePolicyList = useCallback(() => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "custody" },
      withCredentials: true,
    })
      .then((res) => {
        res.data.feePolicyList.forEach((t: any) => {
          t.label = t.name;
          t.value = t.id;
        });
        setCustodyFeePolicyList(res.data.feePolicyList);
        setCustodyFeeTypeId(res.data.feePolicyTypeId);
      })

      .finally(() => {
        setOnFinishLoad(true);
      });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "staking" },
      withCredentials: true,
    })
      .then((res) => {
        res.data.feePolicyList.forEach((t: any) => {
          t.label = t.name;
          t.value = t.id;
        });
        setStakingFeePolicyList(res.data.feePolicyList);
        setStakingFeeTypeId(res.data.feePolicyTypeId);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "trading" },
      withCredentials: true,
    })
      .then((res) => {
        res.data.feePolicyList.forEach((t: any) => {
          t.label = t.name;
          t.value = t.id;
        });
        setTradingFeePolicyList(res.data.feePolicyList);
        setTradingFeeTypeId(res.data.feePolicyTypeId);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, []);

  const fetchData = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset",
      params: {
        assetId: assetId,
      },
      withCredentials: true,
    })
      .then((res) => {
        res.data.assetApiUrls.forEach((element: any) => {
          element.edited = false;
        });
        setApiUrls(res.data.assetApiUrls);
        res.data.assetType = res.data.assetType?.id;
        setVenuePairs(res.data.venuePairs);
        setIsFiat(res.data.isFiat);
        const assetNetworkList = res.data.assetAssetNetworkMap.map(
          (row: any) => row.assetNetworkId,
        );
        res.data.assetNetworks = assetNetworkList;
        setSelectedNetworks(assetNetworkList);
        form.setFieldsValue(res.data);
      })
      .catch((err) => {
        console.error("Failed to fetch asset type", err);
      })
      .finally(() => {
        axios({
          method: "Post",
          url:
            process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy-assgined",
          data: {
            id: assetId,
            type: "asset",
          },
          withCredentials: true,
        }).then((res) => {
          const custodyPolicy = res.data.find(
            (t: any) =>
              t.feePolicyType &&
              t.feePolicyType.name.toLowerCase() === "custody",
          );
          if (custodyPolicy) {
            form.setFieldValue(
              "custodyFeePolicyId",
              Number(custodyPolicy.feePolicyId),
            );
            form.setFieldValue(
              "custodyFeePolicyStartDate",
              dayjs(custodyPolicy.startDate),
            );
          }

          const stakingPolicy = res.data.find(
            (t: any) =>
              t.feePolicyType &&
              t.feePolicyType.name.toLowerCase() === "staking",
          );
          if (stakingPolicy) {
            form.setFieldValue(
              "stakingFeePolicyId",
              Number(stakingPolicy.feePolicyId),
            );
            form.setFieldValue(
              "stakingFeePolicyStartDate",
              dayjs(custodyPolicy.startDate),
            );
          }

          const tradingPolicy = res.data.find(
            (t: any) =>
              t.feePolicyType &&
              t.feePolicyType.name.toLowerCase() === "trading",
          );
          if (tradingPolicy) {
            form.setFieldValue(
              "tradingFeePolicyId",
              Number(tradingPolicy.feePolicyId),
            );
            form.setFieldValue(
              "tradingFeePolicyStartDate",
              dayjs(tradingPolicy.startDate),
            );
          }
        });
      });
  }, [assetId, form]);

  const fetchAssetApiUrlUtils = useCallback(() => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/asset-url-utils",
      withCredentials: true,
    })
      .then((res) => {
        setAssetApiUrlTypeList(res.data.apiTypes);
        setAssetApiUrlEnvironmentsList(res.data.environments);
        setApiNetworkList(res.data.networks);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, []);

  const onFinish = useCallback(
    (form: any) => {
      setOnFinishLoad(false);
      const editedApiUrls = ApiUrls.filter(
        (apiUrl: any) => apiUrl.edited && !apiUrl.newlyCreated,
      );
      editedApiUrls.forEach((apiUrl: any) => {
        apiUrl.editedParams = apiUrl.dictionaries.filter(
          (param: any) => param.edited && !param.newlyCreated,
        );
        apiUrl.newParams = apiUrl.dictionaries.filter(
          (param: any) => param.newlyCreated,
        );
      });
      const newApiUrls = ApiUrls.filter((apiUrl: any) => apiUrl.newlyCreated);
      axios({
        method: "Put",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/update",
        withCredentials: true,
        data: {
          assetId: assetId,
          assetData: {
            name: form.name,
            ticker: form.ticker,
            assetType: form.assetType,
            isActive: form.isActive,
            isStablecoin: form.isStablecoin,
            // isTrading: form.isTrading,
            // isCustody: form.isCustody,
            // isStaking: form.isStaking,
            coingeckoId: form.coingeckoId,
            assetNetworks: form.assetNetworks,
            custodyFeePolicyId: form.custodyFeePolicyId,
            custodyFeePolicyTypeId: custodyFeeTypeId,
            custodyFeePolicyStartDate: form.custodyFeePolicyStartDate,
            stakingFeePolicyTypeId: stakingFeeTypeId,
            stakingFeePolicyId: form.stakingFeePolicyId,
            stakingFeePolicyStartDate: form.stakingFeePolicyStartDate,
            tradingFeePolicyTypeId: tradingFeeTypeId,
            tradingFeePolicyId: form.tradingFeePolicyId,
            tradingFeePolicyStartDate: form.tradingFeePolicyStartDate,
            venuePairs: venuePairs,
            atvPairs: assetTickerVenuePairList,
          },
          editedApiUrls: editedApiUrls,
          newApiUrls: newApiUrls,
          deltedApiUrls: toDeletepiUrls,
        },
      })
        .then(() => {
          navigate("/bo/confirmation", {
            state: {
              pageType: "Asset",
              createOrUpdate: "Update",
              displayName: form.name,
              // createUrl: "/bo/asset/create",
              editUrl: "/bo/asset/update/" + assetId,
            },
          });
          toast.success("Asset Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
          toast.error("Failed to update asset", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .finally(() => {});
      setOnFinishLoad(true);
    },
    [
      ApiUrls,
      assetTickerVenuePairList,
      assetId,
      custodyFeeTypeId,
      navigate,
      stakingFeeTypeId,
      toDeletepiUrls,
      tradingFeeTypeId,
      venuePairs,
    ],
  );

  const onFinishFailed = (errorInfo: any) => {
    toast.error("Please fill required fields ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    console.log("Failed:", errorInfo);
  };
  const onNetworkChange = useCallback(
    (e: any) => {
      const networkIdsInUse = [
        ...new Set(
          [
            ...ApiUrls.map((row) => Number(row.assetNetworkId)),
            ...ApiUrls.map((row) => row.assetAssetNetworkMap?.assetNetworkId),
          ].filter((n) => !isNaN(n)),
        ),
      ];
      const temp = [...new Set([...e, ...networkIdsInUse])];
      if (e.length < temp.length) {
        toast.warn(
          "Cannot Remove this network. It is used by at leasat one api url. Please remove the url first.",
        );
      }
      form.setFieldValue("assetNetworks", temp);
      setSelectedNetworks(temp);
    },
    [ApiUrls, form],
  );

  useEffect(() => {
    fetchFeePolicyList();
  }, [fetchFeePolicyList]);

  useEffect(() => {
    fetchData();
    fetchAssetVenuePairList();
    fetchAssetTypeList();
    fetchAssetApiUrlUtils();
  }, [
    fetchData,
    fetchAssetVenuePairList,
    fetchAssetTypeList,
    fetchAssetApiUrlUtils,
  ]);

  useEffect(() => {
    if (assetNetworkList && assetNetworkList.length) {
      const filteredNetworkOptions = assetNetworkList
        .filter((row: any) => selectedNetworks.includes(row.id))
        .map((row: any) => ({
          label: row.name,
          value: row.id,
          name: row.name,
        }));
      setSelectedNetworkOptions(filteredNetworkOptions);
    }
  }, [assetNetworkList, selectedNetworks]);

  return onFinshiLoad ? (
    <React.Fragment>
      <Content id="edit-asset-page">
        <Row>
          <Col md={{ span: 16 }} sm={{ span: 16 }}>
            <Button
              onClick={() => {
                if (location.state) {
                  navigate(location.state.from, {
                    state: { form: location.state.form },
                  });
                } else {
                  navigate("/bo/settings/assets");
                }
              }}
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-circle-chevron-left" as IconProp}
              />
            </Button>
          </Col>
        </Row>
        <Form
          form={form}
          labelWrap
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="form-body"
          labelCol={{
            sm: {
              span: 8,
              offset: 0,
            },
          }}
          wrapperCol={{
            sm: {
              span: 16,
              offset: 0,
            },
          }}
          style={{
            padding: "1em",
            margin: "auto",
          }}
        >
          <Row justify="center" align="middle">
            <h2>Update Asset</h2>
          </Row>
          <Row>
            <Col sm={{ span: 9, order: 1 }} xs={{ span: 24, order: 1 }}>
              <Form.Item
                label="Asset Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input asset name",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                label="Asset Ticker"
                name="ticker"
                rules={[
                  {
                    required: true,
                    message: "Please input asset ticker",
                  },
                ]}
              >
                <Input placeholder="Ticker" />
              </Form.Item>
              <Form.Item label="Asset Type" name="assetType">
                <Select placeholder="Asset Type" popupMatchSelectWidth={false}>
                  {assetTypeList?.map((assetType) => (
                    <Select.Option
                      value={assetType["id"]}
                      key={assetType["id"]}
                      id={assetType["id"]}
                    >
                      {assetType["name"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="isStablecoin"
                label="Is Stablecoin"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col sm={{ span: 8, order: 2 }} xs={{ span: 24, order: 2 }}>
              <Form.Item name="assetNetworks" label="Network" hidden={isFiat}>
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={assetNetworkList}
                  placeholder="Network"
                  value={selectedNetworks}
                  onChange={(e) => onNetworkChange(e)}
                />
              </Form.Item>
              <Form.Item label="Coingecko ID" name="coingeckoId">
                <Input placeholder="Coingecko ID" />
              </Form.Item>
            </Col>
            <Col sm={{ span: 7, order: 3 }} xs={{ span: 24, order: 3 }}>
              <Form.Item
                name="isActive"
                label="Is Active"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                name="isTrading"
                label="Is Trading"
                valuePropName="checked"
              >
                <Switch disabled />
              </Form.Item>
              <Form.Item
                name="isCustody"
                label="Is Custody"
                valuePropName="checked"
              >
                <Switch disabled />
              </Form.Item>
              <Form.Item
                name="isStaking"
                label="Is Staking"
                valuePropName="checked"
              >
                <Switch disabled />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Venue Tickers</Divider>
          {assetTickerVenuePairList?.map((atvPair: any, i: number) => (
            <Row
              key={`atvp_${i}`}
              justify={"space-evenly"}
              style={{ marginBottom: "1em" }}
            >
              <Col lg={6} md={24} sm={24} xs={24}>
                <Select
                  value={atvPair.venueId}
                  onChange={(e) => {
                    updateATVPairValue(atvPair, "venueId", e);
                  }}
                  className="dcl-venue-select"
                  style={{ width: "100%" }}
                  options={venueOptions}
                  allowClear
                  placeholder="Venue"
                  showSearch
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Col>
              <Col lg={6} md={24} sm={24} xs={24}>
                <Input
                  placeholder="Pair Name"
                  value={atvPair.tickerValue}
                  onChange={(e) =>
                    updateATVPairValue(atvPair, "tickerValue", e.target.value)
                  }
                />
              </Col>
              <Col lg={6} md={24} sm={24} xs={24}>
                <Form.Item label="Fetch on Venue" style={{ width: "100%" }}>
                  <Switch
                    title="Fetch on venue"
                    defaultChecked={true}
                    value={atvPair.json?.toFetch}
                    onChange={(e) =>
                      updateATVPairValue(atvPair, "json", {
                        ticker: atvPair.json?.ticker,
                        toFetch: e,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={1} md={24} sm={24} xs={24}>
                <Button
                  danger
                  style={{ marginTop: 0 }}
                  onClick={() => deleteAtvPair(atvPair)}
                >
                  <MinusCircleOutlined style={{ color: "red" }} />
                </Button>
              </Col>
            </Row>
          ))}
          <Row justify={"space-evenly"} align="middle">
            <Button
              type="dashed"
              block
              icon={<PlusOutlined />}
              onClick={() => {
                const newList = [...assetTickerVenuePairList];
                newList.push({
                  id: new Date().getUTCMilliseconds(),
                  assetId: assetId,
                  venueId: undefined,
                  tickerValue: "",
                  json: {
                    ticker: "",
                    toFetch: true,
                  },
                });
                setAssetTickerVenuePairList(newList);
              }}
            >
              Add a ticker on venue
            </Button>
          </Row>
          <Divider orientation="left">Fee Policy</Divider>
          <Row>
            <Col lg={8} md={24} sm={24} xs={24}>
              <Card title="Custody">
                <Form.Item label="Custody Fee Policy" name="custodyFeePolicyId">
                  <Select
                    showSearch
                    allowClear
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={custodyeePolicyList}
                    placeholder="Custody Fee Policy"
                  />
                </Form.Item>
                <Form.Item
                  label="Custody Fee Policy Start Date"
                  name="custodyFeePolicyStartDate"
                >
                  <DatePicker />
                </Form.Item>
              </Card>
            </Col>
            <Col lg={8} md={24} sm={24} xs={24}>
              <Card title="Staking">
                <Form.Item label="Staking Fee Policy" name="stakingFeePolicyId">
                  <Select
                    showSearch
                    allowClear
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={stakingFeePolicyList}
                    placeholder="Staking Fee Policy"
                  />
                </Form.Item>
                <Form.Item
                  label="Staking Fee Policy Start Date"
                  name="stakingFeePolicyStartDate"
                >
                  <DatePicker />
                </Form.Item>
              </Card>
            </Col>
            <Col lg={8} md={24} sm={24} xs={24}>
              <Card title="Trading">
                <Form.Item label="Trading Fee Policy" name="tradingFeePolicyId">
                  <Select
                    showSearch
                    allowClear
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={tradingFeePolicyList}
                    placeholder="Ttaking Fee Policy"
                  />
                </Form.Item>
                <Form.Item
                  label="Ttaking Fee Policy Start Date"
                  name="tradingFeePolicyStartDate"
                >
                  <DatePicker />
                </Form.Item>
              </Card>
            </Col>
          </Row>

          <Divider orientation="left">API URLs</Divider>
          {ApiUrls.map((apiUrl: any) => (
            <React.Fragment key={apiUrl.id}>
              <Card>
                <Row align="middle" justify={"space-between"}>
                  <Col lg={5} md={5} sm={12} xs={12}>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Type"
                      popupMatchSelectWidth={false}
                      defaultValue={
                        apiUrl.assetApiType
                          ? apiUrl.assetApiType.id
                          : apiUrl.apiTypeId
                      }
                      onChange={(e: any) => {
                        apiUrl.edited = true;
                        apiUrl.apiTypeId = e;
                      }}
                    >
                      {assetApiUrlTypeList?.map((apiUrlType) => (
                        <Select.Option
                          key={apiUrlType.name}
                          value={apiUrlType.id}
                        >
                          {apiUrlType.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col lg={5} md={5} sm={12} xs={12}>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Envrionment"
                      popupMatchSelectWidth={false}
                      defaultValue={apiUrl.environmentId}
                      onChange={(e: any) => {
                        apiUrl.edited = true;
                        apiUrl.networkId = e;
                      }}
                    >
                      {assetApiUrlEnvironmentsList?.map((nework) => (
                        <Select.Option key={nework.name} value={nework.id}>
                          {nework.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col lg={5} md={5} sm={12} xs={12}>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Asset URL Network"
                      defaultValue={
                        apiUrl.assetAssetNetworkMap
                          ? apiUrl.assetAssetNetworkMap.assetNetworkId
                          : apiUrl.assetNetworkId
                      }
                      options={selectedNetworkOptions}
                      onChange={(e: any) => {
                        apiUrl.edited = true;
                        apiUrl.assetAssetNetworkMap = {
                          ...apiUrl.assetAssetNetworkMap,
                          assetNetworkId: e,
                        };
                      }}
                    />
                  </Col>
                  <Col lg={5} md={5} sm={12} xs={12}>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Method"
                      popupMatchSelectWidth={false}
                      defaultValue={apiUrl.method}
                      onChange={(e: any) => {
                        apiUrl.edited = true;
                        apiUrl.method = e;
                      }}
                    >
                      <Select.Option key={"POST"} value={"POST"}>
                        POST
                      </Select.Option>
                      <Select.Option key={"GET"} value={"GET"}>
                        GET
                      </Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row
                  align="middle"
                  justify={"space-around"}
                  style={{ marginTop: "5px" }}
                >
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <TextArea
                      placeholder="Url"
                      defaultValue={apiUrl.value ? apiUrl.value : ""}
                      onChange={(e) => {
                        apiUrl.edited = true;
                        apiUrl.url = e.target.value;
                      }}
                    />
                  </Col>
                </Row>
                <Row
                  align="middle"
                  justify={"space-around"}
                  style={{ marginTop: "5px" }}
                >
                  <Col lg={18} md={18} sm={24} xs={24}>
                    {apiUrl.dictionaries?.map((param: any) => (
                      <Row key={param.id}>
                        <Space className="edit-asset-dictionary-space">
                          <Select
                            style={{ width: "150px" }}
                            placeholder={"Dictionary Type"}
                            defaultValue={param.key}
                            options={[
                              { key: "Input", value: "Input", label: "Input" },
                              {
                                key: "Output",
                                value: "Output",
                                label: "Output",
                              },
                            ]}
                            onChange={(e) => {
                              apiUrl.edited = true;
                              param.edited = true;
                              param.key = e;
                            }}
                          />
                        </Space>
                        <Space className="edit-asset-dictionary-space">
                          <TextArea
                            style={{ minWidth: "400px" }}
                            defaultValue={param.value}
                            placeholder="Dictionary Value"
                            onChange={(e) => {
                              apiUrl.edited = true;
                              param.edited = true;
                              param.value = e.target.value;
                            }}
                          />
                        </Space>
                        <Space className="edit-asset-dictionary-space">
                          <MinusCircleOutlined
                            onClick={() => {
                              apiUrl.edited = true;
                              if (apiUrl.deletedParams) {
                                apiUrl.deletedParams.push(param);
                              } else {
                                apiUrl.deletedParams = [param];
                              }
                              apiUrl.dictionaries.splice(
                                apiUrl.dictionaries.indexOf(param),
                                1,
                              );
                              setApiUrls([...ApiUrls]);
                            }}
                          />
                        </Space>
                      </Row>
                    ))}
                  </Col>
                  <Col lg={4} md={4} sm={24} xs={24}>
                    <Button
                      onClick={() => {
                        apiUrl.dictionaries.push({
                          id: new Date().getUTCMilliseconds(),
                          key: "",
                          value: "",
                          newlyCreated: true,
                        });
                        setApiUrls([...ApiUrls]);
                      }}
                    >
                      Add Dictionary
                    </Button>
                    <Button
                      danger
                      onClick={() => {
                        ApiUrls.splice(ApiUrls.indexOf(apiUrl), 1);
                        if (!apiUrl.newlyCreated) {
                          toDeletepiUrls.push(apiUrl);
                        }
                        setApiUrls([...ApiUrls]);
                      }}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              </Card>
            </React.Fragment>
          ))}
          <Row justify="center" align="middle">
            <Button
              type="dashed"
              onClick={addNewApiURL}
              block
              icon={<PlusOutlined />}
            >
              Add a URL
            </Button>
          </Row>
          <Divider orientation="left">Venue Pairs</Divider>
          <Row>
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
              Filters
              {showFilter ? (
                <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
              ) : (
                <FontAwesomeIcon icon={"fa-solid fa-caret-down" as IconProp} />
              )}
            </Button>
          </Row>
          {showFilter ? (
            <Row className="dcl-filter-row">
              <Col
                span={16}
                className="dcl-toggled-content dcl-toggled-content-filter"
              >
                <Row>
                  <Col span={10}>
                    <Select
                      onChange={(e) => {
                        setVenuePairVenueFilter(e);
                      }}
                      className="dcl-venue-select"
                      style={{ width: "100%" }}
                      mode="multiple"
                      options={venueOptions}
                      allowClear
                      placeholder="Venue"
                      showSearch
                      popupMatchSelectWidth={false}
                      filterOption={(input, option: any) => {
                        return option.label
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    />
                  </Col>
                  <Col span={10} offset={2}>
                    <Select
                      onChange={(e) => {
                        setVenuePairAssetFilter(e);
                      }}
                      className="dcl-asset-select"
                      options={assetOptions}
                      mode="multiple"
                      style={{ width: "100%" }}
                      allowClear
                      placeholder="Counter Currency"
                      popupMatchSelectWidth={false}
                      showSearch
                      filterOption={(input, option: any) => {
                        return option.label
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <></>
          )}

          {filteredVenuePairs?.map((venuePair: any, i: number) => (
            <Row
              key={`vp_${i}`}
              justify={"space-evenly"}
              style={{ marginBottom: "1em" }}
            >
              <Col lg={6} md={24} sm={24} xs={24}>
                <Select
                  value={venuePair.venueId}
                  onChange={(e) => {
                    updateVenuePairField(venuePair, "venueId", e);
                  }}
                  className="dcl-venue-select"
                  style={{ width: "100%" }}
                  options={venueOptions}
                  allowClear
                  placeholder="Venue"
                  popupMatchSelectWidth={false}
                  showSearch
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Col>
              <Col lg={6} md={24} sm={24} xs={24}>
                <Select
                  value={venuePair.counterCurrencyId}
                  onChange={(e) => {
                    updateVenuePairField(venuePair, "counterCurrencyId", e);
                  }}
                  className="dcl-asset-select"
                  options={assetOptions}
                  style={{ width: "100%" }}
                  allowClear
                  placeholder="Counter Currency"
                  popupMatchSelectWidth={false}
                  showSearch
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Col>
              <Col lg={6} md={24} sm={24} xs={24}>
                <Input
                  placeholder="Pair Name"
                  value={venuePair.pair}
                  onChange={(e) =>
                    updateVenuePairField(venuePair, "pair", e.target.value)
                  }
                />
              </Col>
              <Col lg={1} md={24} sm={24} xs={24}>
                <Button
                  danger
                  style={{ marginTop: 0 }}
                  onClick={() => deletePair(venuePair)}
                >
                  <MinusCircleOutlined style={{ color: "red" }} />
                </Button>
              </Col>
            </Row>
          ))}
          <Row justify={"space-evenly"} align="middle">
            <Button
              type="dashed"
              block
              icon={<PlusOutlined />}
              onClick={addNewPair}
            >
              Add a Pair
            </Button>
          </Row>
          <Divider />

          <Form.Item {...tailFormItemLayout}>
            <Button
              htmlType="submit"
              className="submit-button primary-button"
              loading={!onFinshiLoad}
            >
              Update Asset
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </React.Fragment>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
export default EditAsset;
