import { Content } from "antd/es/layout/layout";
import ComingSoon from "./ClientComingSoon";

export default function ClientStakingReport() {
  return (
    <Content>
      <ComingSoon />
    </Content>
  );
}
