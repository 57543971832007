import {
  Button,
  ConfigProvider,
  DatePicker,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function CreateXTradeOrder({
  operationId,
  setShowCreateXTradeOrderModal,
  fetchOperationItems,
}: any) {
  const navigate = useNavigate();
  // Transaction Way
  const [txnWayList, setTxnWayList] = useState<any[]>([]);
  const [selectedTxnWay, setSelectedTxnWay] = useState<number>();
  const fetchTxnWayList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/transaction-way",
      params: {
        type: "trade",
      },
      withCredentials: true,
    })
      .then((res) => {
        setTxnWayList(res.data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);
  useEffect(() => {
    fetchTxnWayList();
  }, [fetchTxnWayList]);
  // Venue List
  const [venueList, setVenueList] = useState<any[]>([]);
  const [selectedVenue, setSelectedVenue] = useState<number>();
  const [isVenueListLoading, setIsVenueListLoading] = useState<boolean>(false);
  const fetchVenueList = useCallback(() => {
    setIsVenueListLoading(true);
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
    })
      .then((res) => {
        setVenueList(res.data.list);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setIsVenueListLoading(false);
      });
  }, [navigate]);
  useEffect(() => {
    fetchVenueList();
  }, [fetchVenueList]);
  // Asset List
  const [assetList, setAssetList] = useState<any[]>([]);
  const [isAssetListLoading, setIsAssetListLoading] = useState<boolean>(false);
  const fetchAssetOptions = useCallback(() => {
    setIsAssetListLoading(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        setAssetList(options);
      })
      .finally(() => {
        setIsAssetListLoading(false);
      });
  }, []);
  useEffect(() => {
    fetchAssetOptions();
  }, [fetchAssetOptions]);
  const [baseAmount, setBaseAmount] = useState<any>(0);
  const [baseAssetId, setBaseAssetId] = useState<number>();
  const [quoteAmount, setQuoteAmount] = useState<any>(0);
  const [quoteAssetId, setQuoteAssetId] = useState<number>();
  const [selectedDate, setSelectedDate] = useState<any>();

  const resetFields = useCallback(() => {
    setBaseAmount(0);
    setQuoteAmount(0);
    setBaseAssetId(undefined);
    setQuoteAssetId(undefined);
    setSelectedDate(undefined);
    setSelectedTxnWay(undefined);
    setSelectedVenue(undefined);
  }, []);

  const [savingOrder, setSavingOrder] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  useEffect(() => {
    setIsSaveDisabled(
      !(
        baseAmount &&
        baseAssetId &&
        quoteAmount &&
        quoteAssetId &&
        selectedDate &&
        selectedTxnWay &&
        selectedVenue
      ),
    );
  }, [
    baseAmount,
    baseAssetId,
    quoteAmount,
    quoteAssetId,
    selectedDate,
    selectedTxnWay,
    selectedVenue,
  ]);
  const saveXtradeOrder = useCallback(() => {
    setSavingOrder(true);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/x-trade/order",
      data: {
        timestamp: selectedDate.format("YYYY-MM-DD HH:mm:ss"),
        venueId: selectedVenue,
        baseAssetId: baseAssetId,
        quoteAssetId: quoteAssetId,
        baseAmount: baseAmount,
        quoteAmount: quoteAmount,
        operationId: operationId ? operationId : undefined,
        transactionWayId: selectedTxnWay,
      },
      withCredentials: true,
    })
      .then(() => {
        toast.success("Virtrual order created");
        resetFields();
        setShowCreateXTradeOrderModal();
      })
      .catch(() => {
        toast.error("Failed to create virtual order");
      })
      .finally(() => {
        setSavingOrder(false);
        fetchOperationItems();
      });
  }, [
    baseAmount,
    baseAssetId,
    quoteAmount,
    quoteAssetId,
    resetFields,
    selectedDate,
    selectedTxnWay,
    selectedVenue,
    setShowCreateXTradeOrderModal,
    operationId,
    fetchOperationItems,
  ]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            labelColor: "white",
            labelFontSize: 16,
          },
        },
      }}
    >
      <Content id="create-xtrade-order">
        <Row justify={"center"}>
          <h2 style={{ color: "white" }}>Virtual Trade Order</h2>
        </Row>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            marginTop: "40px",
          }}
        >
          <Form.Item label={<b>Order Date</b>} required>
            <DatePicker
              onChange={(e) => {
                setSelectedDate(e);
              }}
              value={selectedDate}
              renderExtraFooter={() => (
                <Row justify={"end"}>
                  <b>America/New York</b>
                </Row>
              )}
              showTime={{ format: "HH:mm:ss" }}
            />
          </Form.Item>
          <Form.Item label={<b>Venue</b>} required>
            <Select
              key={"value"}
              className="dcl-venue-select"
              allowClear
              placeholder="Venue"
              options={venueList}
              onChange={(e) => {
                setSelectedVenue(e);
              }}
              showSearch
              value={selectedVenue}
              loading={isVenueListLoading}
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
            />
          </Form.Item>
          <Form.Item label={<b>Transaction Way</b>} required>
            <Radio.Group
              value={selectedTxnWay}
              onChange={(e) => setSelectedTxnWay(e.target.value)}
              buttonStyle="solid"
            >
              {txnWayList.map((transanctionWay: any) => (
                <Radio.Button
                  key={transanctionWay.value}
                  value={transanctionWay.value}
                  className={`dcl-${transanctionWay.label.toLowerCase()}`}
                >
                  {transanctionWay.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label={<b>Base Amount</b>} required>
            <InputNumber
              style={{ width: "100%" }}
              value={baseAmount}
              formatter={(value) => {
                const parts = `${value}`.split(".");
                // Apply the original regex only to the first part (integer part) to add commas.
                const integerPartWithCommas = parts[0].replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ",",
                );
                // Reassemble the parts, including the decimal part if it was present.
                return parts.length > 1
                  ? `${integerPartWithCommas}.${parts[1]}`
                  : integerPartWithCommas;
              }}
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              onChange={(e) => setBaseAmount(e)}
            />
          </Form.Item>
          <Form.Item label={<b>Base Asset</b>} required>
            <Select
              key={"value"}
              className="dcl-asset-select"
              allowClear
              showSearch
              placeholder="Base Asset"
              options={assetList}
              value={baseAssetId}
              onChange={(e) => {
                setBaseAssetId(e);
              }}
              loading={isAssetListLoading}
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
            />
          </Form.Item>
          <Form.Item label={<b>Quote Amount</b>} required>
            <InputNumber
              style={{ width: "100%" }}
              value={quoteAmount}
              formatter={(value) => {
                const parts = `${value}`.split(".");
                // Apply the original regex only to the first part (integer part) to add commas.
                const integerPartWithCommas = parts[0].replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ",",
                );
                // Reassemble the parts, including the decimal part if it was present.
                return parts.length > 1
                  ? `${integerPartWithCommas}.${parts[1]}`
                  : integerPartWithCommas;
              }}
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              onChange={(e) => setQuoteAmount(e)}
            />
          </Form.Item>
          <Form.Item label={<b>Quote Asset</b>} required>
            <Select
              className="dcl-asset-select"
              allowClear
              showSearch
              placeholder="Quote Asset"
              options={assetList}
              onChange={(e) => {
                setQuoteAssetId(e);
              }}
              value={quoteAssetId}
              loading={isAssetListLoading}
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
            />
          </Form.Item>
        </Form>
        <Row justify={"center"}>
          <Button
            type="primary"
            onClick={() => {
              saveXtradeOrder();
            }}
            style={{
              color: "white",
            }}
            loading={savingOrder}
            disabled={isSaveDisabled}
          >
            Save
          </Button>
        </Row>
      </Content>
    </ConfigProvider>
  );
}
