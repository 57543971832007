import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { Buffer } from "buffer";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { DisplayContext } from "../../Context/displayContext";
import ViewFeePolicyDetail from "../FeePages/ViewFeePolicyDetail";

const { RangePicker } = DatePicker;
export default function CustodyReport() {
  const [, copy] = useCopyToClipboard();
  const navigate = useNavigate();
  const displayContext = useContext(DisplayContext);
  const location = useLocation();
  const [displayTimezone, setDisplayTimezone] = useState<string>();

  const [datePeriod, setDatePeriod] = useState<any>();
  const [selectedClient, setSelectClient] = useState<any>();
  const [clientAccountList, setClientAccountList] = useState<any[]>();
  const [clientOptions, setClientOptions] = useState<any[]>();
  const [selectClientAccount, setSelectClientAccount] = useState<any>();
  const [tableDataLoaded, setTableDataLoaded] = useState<boolean>(false);

  const [reportData, setReportData] = useState<any>();
  const [reportDataDatePeriod, setReportDataDatePeriod] = useState<any>();

  const [encodedClientId, setEncodedClientId] = useState<string>("");

  const [balanceModalIsOpen, setBalanceModalIsOpen] = useState<boolean>(false);
  const [dailyBalanceData, setDailyBalanceData] = useState<any>(undefined);
  const [sendingRequest, setSendingRequest] = useState<boolean>(false);

  const [isIncludeInactive, setIsIncludeInactive] = useState<boolean>(false);
  const [isOnlyInReport, setIsOnlyInReport] = useState<boolean>(true);

  const [isHidingFeeInReport, setIsHidingFeeInReport] = useState<boolean>(true);

  const [feePolicyJSON, setFeePolicyJSON] = useState<any>();

  const [stakingReportExists, setStakingReportExists] =
    useState<boolean>(false);
  const [concactStakingReport, setConcatStakingReport] =
    useState<boolean>(false);
  const [stakingReportData, setStakingReportData] = useState<any>();
  const [currentSelectedDatePeriod, setCurrentSelectedDatePeriod] =
    useState<any>();
  const [currentSelectedClientAccount, setCurrentSelectedClientAccount] =
    useState<number>();
  const [custodyReportExists, setCustodyReportExists] =
    useState<boolean>(false);
  const [isDeletingReportModalOpen, setIsDeletingReportModalOpen] =
    useState<boolean>(false);

  const [isHidingVenueFee, setIsHidingVenueFee] = useState<boolean>(true);
  const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false);
  const [isGeneratingCSV, setIsGeneratingCSV] = useState<boolean>(false);

  const [currPageSize, setCurrPageSize] = useState<number>(30);
  const [currPageNum, setCurrPageNum] = useState<number>(1);

  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [showResetBalanceConfirmModal, setShowResetBalanceConfirmModal] =
    useState<boolean>(false);

  const [showFullAddressInReport, setShowFullAddressInReport] =
    useState<boolean>(false);

  const disabledDate: RangePickerProps["disabledDate"] = (current: any) => {
    // Can not select days after today and today
    return current > dayjs().endOf("d");
  };
  const VENUE_FEE_INDEX = 12;

  const openDeletingConfirmationModal = useCallback(() => {
    setIsDeletingReportModalOpen(true);
  }, []);

  const closeDeletingConfirmationModal = useCallback(() => {
    setIsDeletingReportModalOpen(false);
  }, []);
  const [balanceKey, setBalanceKey] = useState<any>();

  const VENUE_FEE_COLUMN = {
    title: "Venue Fee ($)",
    dataIndex: "venueFeeAmount",
    width: 180,
    align: "right" as const,
    render: (
      _: any,
      { venueFeeAmount, feeOverwritten }: any,
      index: number,
    ) => (
      <Space>
        $
        <ConfigProvider direction="rtl">
          <InputNumber<string>
            onChange={(e) => handleInputChange(index, e, "venueFeeAmount")}
            value={Number(venueFeeAmount).toFixed(2)}
            disabled={!feeOverwritten}
            defaultValue={Number(venueFeeAmount).toFixed(2)}
            stringMode
          />
        </ConfigProvider>
      </Space>
    ),
  };
  const handleHidingVenueFeeCheckbox = useCallback((e: boolean) => {
    setIsHidingVenueFee(e);
  }, []);

  const calculateFeeAmount = useCallback(
    (
      index: number,
      feePolicyJSON: any,
      averageAUM: any,
      overwritten: boolean = false,
    ) => {
      const actualIndex =
        currPageNum === 0 ? index : (currPageNum - 1) * currPageSize + index;
      const newReportData = [...reportData];
      if (overwritten) {
        newReportData[actualIndex].averageAUM = averageAUM;
        setReportData(newReportData);
        return;
      }

      let venueFeeAmount = 0,
        dclFeeAmount = 0;

      if (feePolicyJSON.rangeFees) {
        let amountLeft = averageAUM;
        for (const range of feePolicyJSON.rangeFees) {
          const threshold = range.to - range.from;
          if (amountLeft >= threshold) {
            if (range.dclFeeType === "dollar") dclFeeAmount += range.dclFees;
            else dclFeeAmount += threshold * range.dclFees * 0.0001;
            if (range.venueFeeType === "dollar")
              venueFeeAmount += range.venueFee;
            else venueFeeAmount += threshold * range.venueFee * 0.0001;
          } else {
            if (range.dclFeeType === "dollar") dclFeeAmount += range.dclFees;
            else dclFeeAmount += amountLeft * range.dclFees * 0.0001;
            if (range.venueFeeType === "dollar")
              venueFeeAmount += range.venueFee;
            else venueFeeAmount += amountLeft * range.venueFee * 0.0001;
          }
          amountLeft -= threshold;
          if (amountLeft <= 0) break;
        }
      } else {
        // Get Direct Dollar Amount or BPS for DCL Fees
        if (feePolicyJSON.dclFeeType === "bps") {
          dclFeeAmount = feePolicyJSON.dclFees * averageAUM * 0.0001;
        } else if (feePolicyJSON.dclFeeType === "dollar") {
          dclFeeAmount = feePolicyJSON.dclFees;
        }

        // Get Direct Dollar Amount or BPS for Venue Fees
        if (feePolicyJSON.venueFeeType === "bps") {
          venueFeeAmount = feePolicyJSON.venueFee * averageAUM * 0.0001;
        } else if (feePolicyJSON.venueFeeType === "dollar") {
          venueFeeAmount = feePolicyJSON.venueFee;
        }
      }
      // Minimum Fee
      if (
        feePolicyJSON.feesBelow &&
        dclFeeAmount &&
        dclFeeAmount < feePolicyJSON.feesBelow &&
        !feePolicyJSON.feesBelowIsGlboal
      )
        dclFeeAmount = feePolicyJSON.feesBelowDclFee;
      if (
        feePolicyJSON.feesBelow &&
        venueFeeAmount &&
        venueFeeAmount < feePolicyJSON.feesBelow &&
        !feePolicyJSON.feesBelowIsGlboal
      )
        venueFeeAmount = feePolicyJSON.feesBelowVenueFee;

      newReportData[actualIndex].averageAUM = averageAUM;
      newReportData[actualIndex].dclFeeAmount = dclFeeAmount;
      newReportData[actualIndex].venueFeeAmount = venueFeeAmount;
      setReportData(newReportData);
    },
    [currPageNum, currPageSize, reportData],
  );

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const handleClientSelect = useCallback(
    (option: any, optionElement: any) => {
      setSelectClient(option);
      const ca = clientOptions?.find((t) => t.value === option).clientAccounts;
      if (ca && ca.length === 1) setSelectClientAccount(ca[0].value);
      else setSelectClientAccount(undefined);
    },
    [clientOptions],
  );

  const handleInputChange = useCallback(
    (index: number, number: any, key: string) => {
      const actualIndex =
        currPageNum === 0 ? index : (currPageNum - 1) * currPageSize + index;
      const newData = [...reportData];
      newData[actualIndex][key] = Number(number);
      setReportData(newData);
    },
    [currPageNum, currPageSize, reportData],
  );

  const handleMemoInputChange = useCallback(
    (index: number, memo: any) => {
      const actualIndex =
        currPageNum === 0 ? index : (currPageNum - 1) * currPageSize + index;
      const newData = [...reportData];
      newData[actualIndex].memo = memo;
      setReportData(newData);
    },
    [currPageNum, currPageSize, reportData],
  );

  const handleCheckboxChange = useCallback(
    (index: number, value: any, key: string) => {
      const actualIndex =
        currPageNum === 0 ? index : (currPageNum - 1) * currPageSize + index;
      const newData = [...reportData];
      newData[actualIndex][key] = value;
      if (!value && key === "aumOverwritten") {
        newData[actualIndex].averageAUM = String(
          Number(newData[actualIndex].originAUM).toFixed(2),
        );
      }
      setReportData(newData);
      if (
        key === "aumOverwritten" &&
        !value &&
        !newData[actualIndex].feeOverwritten
      )
        calculateFeeAmount(
          actualIndex,
          reportData[actualIndex].feePolicyJSON,
          newData[actualIndex].averageAUM,
          false,
        );
      if (key === "feeOverwritten" && !value)
        calculateFeeAmount(
          actualIndex,
          reportData[actualIndex].feePolicyJSON,
          reportData[actualIndex].averageAUM,
          false,
        );
    },
    [currPageNum, currPageSize, reportData, calculateFeeAmount],
  );

  const savePeriodFee = useCallback(() => {
    // console.log(reportData);
    // return;
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/custody/save-period-fee",
      withCredentials: true,
      data: {
        startDate: dayjs
          .utc(reportDataDatePeriod[0].format("YYYYMMDD"))
          .startOf("d")
          .toDate(),
        endDate: dayjs
          .utc(reportDataDatePeriod[1].format("YYYYMMDD"))
          .endOf("d")
          .toDate(),
        periodFeeData: reportData,
      },
    })
      .then(() => {
        toast.success("Period Fee saved successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reportData, reportDataDatePeriod]);

  const generateReport = useCallback(() => {
    setIsGeneratingReport(true);
    const reportDataSending = reportData.filter((t: any) => t.isReport);
    const CA = clientAccountList?.find((t) => t.value === selectClientAccount);
    const globalMinimumFeeApplied =
      [
        ...new Set(
          reportData.map((t: any) =>
            t.feePolicyJSON ? t.feePolicyJSON.feesBelowIsGlboal : true,
          ),
        ),
      ].length === 1 &&
      [...new Set(reportData.map((t: any) => t.feePolicyId))].length === 1;
    let globalMinimumFeeAmount = undefined;
    if (globalMinimumFeeApplied) {
      globalMinimumFeeAmount = reportData[0].feePolicyJSON.feesBelowDclFee;
    }
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/custody/pdf-report-generation",
      data: {
        clientAccountNumber: CA ? CA.accountNumber : "",
        clientAccountName:
          CA && CA.label.split("(") ? CA.label.split("(")[0].trim() : "",
        data: reportDataSending,
        startDate: reportDataDatePeriod[0].format("YYYY-MM-DD"),
        endDate: reportDataDatePeriod[1].format("YYYY-MM-DD"),
        isHidingFeeInReport: isHidingFeeInReport,
        globalMinimumFeeApplied: globalMinimumFeeApplied,
        globalMinimumFeeAmount: globalMinimumFeeAmount,
        concactStakingReport: concactStakingReport,
        stakingReportData: stakingReportData,
        showFullAddress: showFullAddressInReport,
      },
      withCredentials: true,
    })
      .then((res) => {
        const buffer = Buffer.from(res.data);
        const blob = new Blob([buffer]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${
            clientAccountList?.find((t) => t.value === selectClientAccount)
              ? clientAccountList?.find((t) => t.value === selectClientAccount)
                  .label
              : ""
          }_${
            reportDataDatePeriod
              ? reportDataDatePeriod[0].format("YYYYMMDD")
              : ""
          }_${
            reportDataDatePeriod
              ? reportDataDatePeriod[1].format("YYYYMMDD")
              : ""
          }_custody_report${
            isHidingFeeInReport ? "" : "_with_fee_details"
          }.pdf`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsGeneratingReport(false);
      });
  }, [
    isHidingFeeInReport,
    clientAccountList,
    selectClientAccount,
    reportData,
    reportDataDatePeriod,
    concactStakingReport,
    stakingReportData,
    showFullAddressInReport,
  ]);

  const exportCSV = useCallback(() => {
    setIsGeneratingCSV(true);
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/custody/export-csv",
      withCredentials: true,
      data: {
        data: reportData,
        isHidingVenueFee,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${reportDataDatePeriod[0].format(
            "YYYY-MM-DD",
          )}_${reportDataDatePeriod[1].format(
            "YYYY-MM-DD",
          )}_custody_period_fee.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsGeneratingCSV(false);
      });
  }, [reportData, reportDataDatePeriod, isHidingVenueFee]);

  const displayColumnsWithoutFee = [
    {
      title: "Label",
      dataIndex: "label",
      ellipse: true,
      width: 120,
      fixed: "left" as const,
      render: (_: any, { label }: any) => (
        <Tooltip title={label}>
          <b>{label.length > 8 ? `${label.substring(0, 8)}...` : label}</b>
        </Tooltip>
      ),
    },
    {
      title: "Wallet Type",
      dataIndex: "walletType",
      width: 100,
      render: (_: any, { walletType }: any) => <b>{walletType}</b>,
    },
    {
      title: "Asset Ticker",
      dataIndex: "assetTicker",
      width: 100,
      render: (_: any, { assetTicker }: any) => <b>{assetTicker}</b>,
    },
    {
      title: "Public Address",
      dataIndex: "address",
      width: 200,
      render: (_: any, { address, walletId }: any) => (
        <Link
          to={"/bo/wallet/view/" + encodeURIComponent(walletId)}
          state={{
            from: window.location.pathname,
            startDate: datePeriod ? datePeriod[0].format() : undefined,
            endDate: datePeriod ? datePeriod[1].format() : undefined,
            selectedClient: selectedClient,
            selectClientAccount: selectClientAccount,
          }}
        >
          {address}
        </Link>
      ),
    },
    {
      title: "Overwrite Balance, Price, and AUM",
      dataIndex: "aumOverwriiten",
      width: 100,
      align: "center" as const,
      render: (_: any, { aumOverwritten }: any, index: number) => (
        <Checkbox
          defaultChecked={aumOverwritten}
          checked={aumOverwritten}
          onChange={(e) => {
            handleCheckboxChange(index, e.target.checked, "aumOverwritten");
          }}
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: 240,
      align: "right" as const,
      render: (
        _: any,
        { isPlaceholder, quantity, aumOverwritten }: any,
        index: number,
      ) => (
        <ConfigProvider direction="rtl">
          <InputNumber
            style={{ width: "100%" }}
            disabled={!aumOverwritten && !isPlaceholder}
            onChange={(e) => {
              handleInputChange(index, e, "quantity");
            }}
            defaultValue={quantity}
          />
        </ConfigProvider>
      ),
    },
    {
      title: "Price",
      dataIndex: "unitPrice",
      width: 240,
      align: "right" as const,
      render: (
        _: any,
        { isPlaceholder, unitPrice, aumOverwritten }: any,
        index: number,
      ) => (
        <ConfigProvider direction="rtl">
          <InputNumber
            style={{ width: "100%" }}
            disabled={!aumOverwritten && !isPlaceholder}
            onChange={(e) => {
              handleInputChange(index, e, "unitPrice");
            }}
            defaultValue={unitPrice}
          />
        </ConfigProvider>
      ),
    },
    {
      title: "Asset Value ($)",
      dataIndex: "usdValue",
      width: 150,
      align: "right" as const,
      render: (_: any, { quantity, unitPrice }: any) => (
        <Space>
          {quantity && unitPrice
            ? (quantity * unitPrice).toLocaleString("en-us", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 8,
              })
            : "0.00"}
        </Space>
      ),
    },
    {
      title: "Average AUM ($)",
      dataIndex: "averageAUM",
      width: 180,
      align: "right" as const,
      render: (
        _: any,
        { averageAUM, feePolicyJSON, feeOverwritten, aumOverwritten }: any,
        index: number,
      ) => (
        <Space>
          <ConfigProvider direction="rtl">
            <InputNumber<string>
              style={{ width: "150px" }}
              disabled={!aumOverwritten}
              defaultValue={
                averageAUM ? String(Number(averageAUM).toFixed(2)) : "0.00"
              }
              value={
                averageAUM ? String(Number(averageAUM).toFixed(2)) : "0.00"
              }
              onChange={(e) => {
                calculateFeeAmount(index, feePolicyJSON, e, feeOverwritten);
              }}
              stringMode
            />
          </ConfigProvider>
        </Space>
      ),
    },
    {
      title: "Fee Policy",
      dataIndex: "feePolicyName",
      width: 150,
      render: (_: any, { feePolicyName, feePolicyJSON }: any) => (
        <Link
          to={""}
          onClick={() => {
            setFeePolicyJSON(feePolicyJSON);
          }}
        >
          {feePolicyName} {feePolicyJSON.rangeFees ? "(Tiered Fee)" : ""}
        </Link>
      ),
    },

    {
      title: "Overwrite Fees",
      dataIndex: "overwriiten",
      width: 100,
      align: "center" as const,
      render: (
        _: any,
        { feeOverwritten, dclFeeAmount }: any,
        index: number,
      ) => (
        <Checkbox
          defaultChecked={feeOverwritten}
          checked={feeOverwritten}
          onChange={(e) => {
            handleInputChange(
              index,
              Number(dclFeeAmount.toFixed(2)),
              "dclFeeAmount",
            );
            handleCheckboxChange(index, e.target.checked, "feeOverwritten");
          }}
        />
      ),
    },
    {
      title: "DCL Fee ($)",
      dataIndex: "dclFeeAmount",
      width: 200,
      align: "right" as const,
      render: (
        _: any,
        { feePolicyJSON, dclFeeAmount, feeOverwritten }: any,
        index: number,
      ) => (
        <Space>
          $
          <ConfigProvider direction="rtl">
            <InputNumber<string>
              onChange={(e) => handleInputChange(index, e, "dclFeeAmount")}
              value={Number(dclFeeAmount).toFixed(2)}
              disabled={!feeOverwritten}
              defaultValue={Number(dclFeeAmount).toFixed(2)}
              stringMode
            />
            {feePolicyJSON && !feePolicyJSON.feesBelowIsGlboal ? (
              <Tooltip
                title="Minimum Fee Applied"
                style={{ fontSize: "small" }}
              >
                <ExclamationCircleOutlined />
              </Tooltip>
            ) : (
              <></>
            )}
          </ConfigProvider>
        </Space>
      ),
    },
    {
      title: "Memo",
      dataIndex: "memo",
      width: 250,
      render: (_: any, { memo }: any, index: number) => (
        <Space>
          <TextArea
            onChange={(e) => handleMemoInputChange(index, e.target.value)}
            value={memo}
            defaultValue={memo}
            rows={2}
          />
        </Space>
      ),
    },
    {
      title: "Is Active",
      dataIndex: "",
      width: 100,
      align: "center" as const,
      render: (_: any, { isActive }: any) => (
        <Checkbox checked={isActive} disabled />
      ),
    },
    {
      title: "In Report",
      dataIndex: "",
      width: 100,
      align: "center" as const,
      render: (_: any, { isReport }: any) => (
        <Checkbox checked={isReport} disabled />
      ),
    },
    {
      title: "Is Placeholder",
      dataIndex: "",
      width: 100,
      align: "center" as const,
      render: (_: any, { isPlaceholder }: any) => (
        <Checkbox checked={isPlaceholder} disabled />
      ),
    },
    {
      title: "Daily Balances",
      dataIndex: "dailyBlances",
      width: 100,
      // fixed: "right" as const,
      render: (_: any, { balances, key }: any) => (
        <Space>
          {balances && balances.length ? (
            <Button
              onClick={() => {
                setBalanceKey(key);
                setBalanceModalIsOpen(true);
                // setDailyBalanceData(balances);
              }}
            >
              Show
            </Button>
          ) : (
            <></>
          )}
        </Space>
      ),
    },
  ];
  const displayColumnsWithFee = [...displayColumnsWithoutFee];
  displayColumnsWithFee.splice(VENUE_FEE_INDEX, 0, VENUE_FEE_COLUMN);

  const balanceColumns = [
    {
      title: "Date",
      dataIndex: "timestamp",
      width: 50,
      render: (_: any, { timestamp }: any) => (
        <b>{dayjs.utc(timestamp).format("YYYY-MM-DD").toString()}</b>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "value",
      align: "right" as const,
      width: 50,
      render: (_: any, { value }: any) => (
        <b>
          {value
            ? value.toLocaleString("en-us", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 8,
              })
            : 0.0}
        </b>
      ),
    },
    {
      title: "Digital Asset Price",
      dataIndex: "price",
      align: "right" as const,
      width: 50,
      render: (_: any, { price }: any) => (
        <b>
          {price
            ? price.toLocaleString("en-us", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 8,
              })
            : "N/A"}
        </b>
      ),
    },
    {
      title: "USD Value",
      dataIndex: "usdValue",
      width: 50,
      align: "right" as const,
      render: (_: any, { usdValue }: any) => (
        <Space>
          {usdValue
            ? usdValue.toLocaleString("en-us", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 8,
              })
            : "$0.00"}
        </Space>
      ),
    },
  ];

  const prePopulateReport = useCallback(() => {
    setReportData(undefined);
    setTableDataLoaded(false);
    setSendingRequest(true);
    setCurrentSelectedDatePeriod(datePeriod);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/custody/pre-populate-report",
      withCredentials: true,
      data: {
        startDate: dayjs
          .utc(datePeriod[0].format("YYYYMMDD"))
          .startOf("d")
          .toDate(),
        endDate: dayjs
          .utc(datePeriod[1].format("YYYYMMDD"))
          .endOf("d")
          .toDate(),
        clientAccountId: selectClientAccount,
        clientId: selectedClient,
        isIncludeInactive: isIncludeInactive,
        isOnlyInReport: isOnlyInReport,
      },
    })
      .then((res) => {
        setCurrPageNum(1);
        setCurrPageSize(30);
        setReportData(res.data.data);
        setEncodedClientId(res.data.encodedClientId);
        setReportDataDatePeriod(datePeriod);
        setStakingReportExists(res.data.stakingReportExists);
        setCustodyReportExists(res.data.custodyFeeReportExists);
        setCurrentSelectedClientAccount(selectClientAccount);
        if (res.data.stakingReportExists) {
          setConcatStakingReport(true);
          setStakingReportData(res.data.stakingReportData);
        } else {
          setConcatStakingReport(false);
          setStakingReportData(undefined);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setSendingRequest(false);
        setTableDataLoaded(true);
        setModalLoading(false);
      });
  }, [
    navigate,
    isOnlyInReport,
    isIncludeInactive,
    selectClientAccount,
    datePeriod,
    selectedClient,
  ]);

  const reCalculateBalances = useCallback(() => {
    setModalLoading(true);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/custody/re-calculate-balances",
      withCredentials: true,
      data: {
        startDate: dayjs
          .utc(reportDataDatePeriod[0].format("YYYYMMDD"))
          .startOf("d")
          .toDate(),
        endDate: dayjs
          .utc(reportDataDatePeriod[1].format("YYYYMMDD"))
          .endOf("d")
          .toDate(),
        clientAccountId: selectClientAccount,
      },
    })
      .then(() => {
        prePopulateReport();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate, prePopulateReport, reportDataDatePeriod, selectClientAccount]);

  const confirmDeleteReport = useCallback(() => {
    axios({
      method: "Delete",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/custody/delete",
      withCredentials: true,
      data: {
        clientAccountId: currentSelectedClientAccount,
        startDate: dayjs
          .utc(currentSelectedDatePeriod[0].format("YYYYMMDD"))
          .startOf("d")
          .toDate(),
        endDate: dayjs
          .utc(currentSelectedDatePeriod[1].format("YYYYMMDD"))
          .endOf("d")
          .toDate(),
      },
    })
      .then(() => {
        toast.success("Custody Report Deleted Successfully");
      })
      .finally(() => {
        prePopulateReport();
      });
    prePopulateReport();
    setIsDeletingReportModalOpen(false);
  }, [
    prePopulateReport,
    currentSelectedClientAccount,
    currentSelectedDatePeriod,
  ]);

  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    })
      .then((res) => {
        for (const c of res.data.clients) {
          c.label = `${c.label} (${c.deltecAccount})`;
        }
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);

  const copyBalanceToClipboard = useCallback(() => {
    axios({
      method: "post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/custody/daily-balance/export",
      withCredentials: true,
      data: {
        balanceData: dailyBalanceData,
      },
    })
      .then((res) => {
        copy(res.data);
        toast.success("Data copied to clipboard", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, [dailyBalanceData, copy]);

  useEffect(() => {
    getClientOptions();
  }, [getClientOptions]);

  useEffect(() => {
    if (selectedClient && clientOptions) {
      const c = clientOptions?.find((t) => t.id === selectedClient);
      setClientAccountList(c.clientAccounts ? c.clientAccounts : []);
    }
  }, [selectedClient, clientOptions]);

  useEffect(() => {
    const temp = reportData?.find((row: any) => row.key === balanceKey);
    if (temp && temp.balances) {
      setDailyBalanceData(temp.balances);
    }
  }, [balanceKey, reportData]);

  useEffect(() => {
    if (location.state) {
      setSelectClient(location.state.selectedClient);
      setSelectClientAccount(location.state.selectClientAccount);
      setDatePeriod([
        dayjs(location.state.startDate),
        dayjs(location.state.endDate).startOf("d"),
      ]);
    }
  }, [location]);

  return (
    <Content id="custody-fee-report">
      <Row>
        <Col
          xl={{ span: 5 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <RangePicker
            className="dcl-daterange-select"
            value={datePeriod}
            disabledDate={disabledDate}
            onChange={(e) => {
              setDatePeriod(e);
            }}
          />
        </Col>
        <Col
          xl={{ span: 4, offset: 1 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Select
            placeholder={"Client"}
            options={clientOptions}
            onSelect={handleClientSelect}
            value={selectedClient}
            showSearch
            style={{
              width: "100%",
              maxWidth: "100%",
            }}
            filterOption={(input, option: any) => {
              return option.label.toLowerCase().includes(input.toLowerCase());
            }}
          />
        </Col>
        <Col
          xl={{ span: 4, offset: 1 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Select
            placeholder="Client Account"
            disabled={!selectedClient}
            options={clientAccountList}
            value={selectClientAccount}
            onSelect={(option) => {
              setSelectClientAccount(option);
            }}
            style={{
              width: "100%",
            }}
          />
        </Col>
        <Col
          xl={{ span: 4, offset: 1 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row>
            <Col span={24}>
              <Checkbox
                name="Display archived accounts:"
                // options={[
                //   { value: false, label: "Active Accounts", key: "false" },
                //   { value: true, label: "All accounts", key: "true" },
                // ]}
                style={{
                  minWidth: "300px",
                }}
                onChange={(e) => {
                  setIsIncludeInactive(e.target.checked);
                }}
                defaultChecked={false}
              >
                <b>Display archived accounts</b>
              </Checkbox>
            </Col>
          </Row>
        </Col>
        <Col
          xl={{ span: 3, offset: 1 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row>
            <Col span={24}>
              <Checkbox
                onChange={(e) => {
                  setIsOnlyInReport(e.target.checked);
                }}
                defaultChecked={true}
              >
                <b>Only in reports</b>
              </Checkbox>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="end" style={{ marginTop: "1em" }}>
        <Button
          onClick={prePopulateReport}
          disabled={!selectClientAccount || !datePeriod}
          type="primary"
          loading={sendingRequest}
        >
          Prepopulate the report
        </Button>
      </Row>
      {sendingRequest ? (
        <Row style={{ marginTop: "2em" }} justify={"center"}>
          <Spin size="large" />
        </Row>
      ) : (
        <></>
      )}
      {reportData ? (
        <Row style={{ marginTop: "2em" }}>
          <Col span={24}>
            <Card
              title={
                <Link
                  to={"/bo/client/info/" + encodeURIComponent(encodedClientId)}
                  state={{
                    from: window.location.pathname,
                    startDate: datePeriod ? datePeriod[0].format() : undefined,
                    endDate: datePeriod ? datePeriod[1].format() : undefined,
                    selectedClient: selectedClient,
                    selectClientAccount: selectClientAccount,
                  }}
                >
                  {clientAccountList?.find(
                    (t) => t.value === selectClientAccount,
                  )
                    ? clientAccountList?.find(
                        (t) => t.value === selectClientAccount,
                      ).label
                    : ""}
                </Link>
              }
              extra={
                <React.Fragment>
                  <Row
                    align="middle"
                    className="cutody-report-export-row"
                    justify={"end"}
                  >
                    {custodyReportExists ? (
                      <Button
                        type="primary"
                        onClick={openDeletingConfirmationModal}
                      >
                        <FontAwesomeIcon
                          icon={"fa-solid fa-trash" as IconProp}
                          style={{ marginRight: "10px" }}
                        />
                        Delete this report
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Row>
                  <Row align="middle" className="cutody-report-export-row">
                    <Checkbox
                      defaultChecked={showFullAddressInReport}
                      onChange={(e) =>
                        setShowFullAddressInReport(e.target.checked)
                      }
                    >
                      Display Full Address in PDF
                    </Checkbox>
                    <Checkbox
                      defaultChecked={isHidingVenueFee}
                      onChange={(e) => {
                        handleHidingVenueFeeCheckbox(e.target.checked);
                      }}
                    >
                      Hide Venue Fee
                    </Checkbox>
                    <Checkbox
                      defaultChecked={isHidingFeeInReport}
                      onChange={(e) => setIsHidingFeeInReport(e.target.checked)}
                    >
                      Hide fees in report
                    </Checkbox>
                    {stakingReportExists ? (
                      <Checkbox
                        defaultChecked={concactStakingReport}
                        onChange={(e) =>
                          setConcatStakingReport(e.target.checked)
                        }
                      >
                        Include Staking Report
                      </Checkbox>
                    ) : (
                      <></>
                    )}
                  </Row>
                  <Row
                    align="middle"
                    className="cutody-report-export-row"
                    justify={"end"}
                  >
                    <Button loading={isGeneratingCSV} onClick={exportCSV}>
                      <FontAwesomeIcon
                        icon={"fa-solid fa-file-csv" as IconProp}
                        style={{ marginRight: "10px" }}
                      />
                      CSV Export
                    </Button>

                    <Button
                      onClick={() => generateReport()}
                      type="primary"
                      loading={isGeneratingReport}
                    >
                      <FontAwesomeIcon
                        icon={"fa-solid fa-file-pdf" as IconProp}
                        style={{ marginRight: "10px" }}
                      />
                      PDF Export
                    </Button>
                  </Row>
                </React.Fragment>
              }
            >
              {isHidingVenueFee ? (
                <Table
                  columns={displayColumnsWithoutFee}
                  dataSource={reportData}
                  scroll={{ x: 2000 }}
                  loading={!tableDataLoaded}
                  sticky
                  summary={() => (
                    <Table.Summary>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          <b>Total Fees (Before Global Minimum Fee Applied)</b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={7} />
                        <Table.Summary.Cell index={2} align="right">
                          <b>
                            $
                            {reportData.reduce(
                              (accumulator: number, item: any) =>
                                item.dclFeeAmount || item.venueFeeAmount
                                  ? accumulator +
                                    Number(item.dclFeeAmount) +
                                    Number(item.venueFeeAmount)
                                  : accumulator,
                              0,
                            )
                              ? reportData
                                  .reduce(
                                    (accumulator: number, item: any) =>
                                      item.dclFeeAmount || item.venueFeeAmount
                                        ? accumulator +
                                          Number(item.dclFeeAmount) +
                                          Number(item.venueFeeAmount)
                                        : accumulator,
                                    0,
                                  )
                                  .toLocaleString("en-us", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                              : "0.00"}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3} colSpan={8} />
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          <b>Total Fees (Global Minimum Fee Applied)</b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={7} />
                        <Table.Summary.Cell
                          index={2}
                          align="right"
                          className={
                            reportData &&
                            reportData.length &&
                            ([
                              ...new Set(
                                reportData.map((t: any) =>
                                  t.feePolicyJSON
                                    ? t.feePolicyJSON.feesBelowIsGlboal
                                    : true,
                                ),
                              ),
                            ].length > 1 ||
                              [
                                ...new Set(
                                  reportData.map((t: any) => t.feePolicyId),
                                ),
                              ].length > 1)
                              ? "table-summary-cell-warning "
                              : ""
                          }
                        >
                          <b>
                            {(() => {
                              if (!reportData || reportData.length === 0)
                                return "$0.00";

                              const uniqueFeePolicyFlags = new Set(
                                reportData.map((t: any) =>
                                  t.feePolicyJSON
                                    ? t.feePolicyJSON.feesBelowIsGlboal
                                    : true,
                                ),
                              );

                              const uniqueFeePolicyIds = new Set(
                                reportData.map((t: any) => t.feePolicyId),
                              );

                              if (
                                uniqueFeePolicyFlags.size > 1 ||
                                uniqueFeePolicyIds.size > 1
                              ) {
                                return "Fees cannot be calculated due to fees calculation assignment inconsistency. Please change and revert";
                              }

                              const totalFees = reportData.reduce(
                                (acc: number, item: any) =>
                                  acc +
                                  (Number(item.dclFeeAmount) || 0) +
                                  (Number(item.venueFeeAmount) || 0),
                                0,
                              );

                              const roundedTotalFees = Number(
                                totalFees.toFixed(2),
                              );
                              if (roundedTotalFees === 0) return "$0.00";

                              const feesBelowDclFee =
                                reportData[0]?.feePolicyJSON?.feesBelowDclFee ||
                                0;

                              return totalFees > feesBelowDclFee
                                ? `${totalFees.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : `${feesBelowDclFee.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`;
                            })()}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3} colSpan={8} />
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                  pagination={{
                    onChange: (page, pageSize) => {
                      setCurrPageSize(pageSize);
                      setCurrPageNum(page);
                    },
                    showSizeChanger: true,
                    pageSizeOptions: [1, 2, 10],
                    defaultPageSize: 30,
                    showTotal: (total) => (
                      <Row>
                        <b>Count</b>: {total}
                      </Row>
                    ),
                  }}
                />
              ) : (
                <Table
                  columns={displayColumnsWithFee}
                  dataSource={reportData}
                  scroll={{ x: 2000 }}
                  loading={!tableDataLoaded}
                  summary={() => (
                    <Table.Summary>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={4}>
                          <b>Total Fees (Before Global Minimum Fee Applied)</b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          index={1}
                          colSpan={7}
                        ></Table.Summary.Cell>
                        <Table.Summary.Cell index={2} align="right">
                          <b>
                            $
                            {reportData
                              .reduce(
                                (accumulator: number, item: any) =>
                                  item.dclFeeAmount || item.venueFeeAmount
                                    ? accumulator +
                                      Number(item.dclFeeAmount) +
                                      Number(item.venueFeeAmount)
                                    : accumulator,
                                0,
                              )
                              .toLocaleString("en-us", {
                                type: "currency",
                                currency: "usd",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          index={2}
                          colSpan={8}
                        ></Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={4}>
                          <b>Total Fees (Global Minimum Fee Applied)</b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={7} />
                        <Table.Summary.Cell
                          index={2}
                          align="right"
                          className={
                            [
                              ...new Set(
                                reportData.map((t: any) =>
                                  t.feePolicyJSON
                                    ? t.feePolicyJSON.feesBelowIsGlboal
                                    : true,
                                ),
                              ),
                            ].length > 1 ||
                            [
                              ...new Set(
                                reportData.map((t: any) => t.feePolicyId),
                              ),
                            ].length > 1
                              ? "table-summary-cell-warning "
                              : ""
                          }
                        >
                          <b>
                            {(() => {
                              if (!reportData || reportData.length === 0)
                                return "$0.00";

                              const uniqueFeePolicyFlags = new Set(
                                reportData.map((t: any) =>
                                  t.feePolicyJSON
                                    ? t.feePolicyJSON.feesBelowIsGlboal
                                    : true,
                                ),
                              );

                              const uniqueFeePolicyIds = new Set(
                                reportData.map((t: any) => t.feePolicyId),
                              );

                              if (
                                uniqueFeePolicyFlags.size > 1 ||
                                uniqueFeePolicyIds.size > 1
                              ) {
                                return "Fees cannot be calculated due to fees calculation assignment inconsistency. Please change and revert";
                              }

                              const totalFees = reportData.reduce(
                                (acc: number, item: any) =>
                                  acc +
                                  (Number(item.dclFeeAmount) || 0) +
                                  (Number(item.venueFeeAmount) || 0),
                                0,
                              );

                              const roundedTotalFees = Number(
                                totalFees.toFixed(2),
                              );
                              if (roundedTotalFees === 0) return "$0.00";

                              const feesBelowDclFee =
                                reportData[0]?.feePolicyJSON?.feesBelowDclFee ||
                                0;

                              return totalFees > feesBelowDclFee
                                ? `${totalFees.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : `${feesBelowDclFee.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`;
                            })()}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          index={3}
                          colSpan={8}
                        ></Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                  pagination={{
                    onChange: (page, pageSize) => {
                      setCurrPageSize(pageSize);
                      setCurrPageNum(page);
                    },
                    defaultPageSize: 30,
                    showTotal: (total) => (
                      <Row>
                        <b>Count</b>: {total}
                      </Row>
                    ),
                  }}
                />
              )}
            </Card>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {reportData ? (
        <React.Fragment>
          <Row justify={"center"}>
            <Button
              style={{ width: "50vh" }}
              type="primary"
              onClick={savePeriodFee}
            >
              Save
            </Button>
          </Row>
        </React.Fragment>
      ) : (
        <></>
      )}
      <Modal
        width="80%"
        onCancel={() => {
          setDailyBalanceData(undefined);
          setBalanceModalIsOpen(false);
          setBalanceKey(undefined);
        }}
        footer={null}
        open={balanceModalIsOpen}
      >
        <Row
          style={{
            marginTop: "20px",
          }}
        >
          <Button
            onClick={() => {
              setShowResetBalanceConfirmModal(true);
            }}
            loading={modalLoading}
          >
            Reset Balance
          </Button>
          <Button
            type="primary"
            onClick={copyBalanceToClipboard}
            disabled={modalLoading}
          >
            <FontAwesomeIcon
              icon={"fa-regular fa-copy" as IconProp}
              style={{ marginRight: "3px" }}
            />
            Copy to clipboard
          </Button>
        </Row>
        {modalLoading ? (
          <Row justify={"center"} style={{ marginTop: "20px" }}>
            <Spin size="large" />
          </Row>
        ) : (
          <Table
            pagination={{
              style: {
                backgroundColor: "#fff",
                borderRadius: "0 0 5px 5px",
              },
            }}
            sticky
            size="small"
            scroll={{ x: 450 }}
            dataSource={dailyBalanceData}
            rowKey={"id"}
            columns={balanceColumns}
          />
        )}
      </Modal>
      <Modal
        width="80%"
        onCancel={() => {
          setShowResetBalanceConfirmModal(false);
        }}
        footer={null}
        open={showResetBalanceConfirmModal}
      >
        <Alert
          style={{
            marginTop: "30px",
          }}
          message={
            "Balances for this subaccount will be deleted: recalculation will be processed on pre-populating the report again"
          }
          type="warning"
          showIcon
        />
        <Row justify={"end"} style={{ marginTop: "20px" }}>
          <Button
            onClick={() => {
              setShowResetBalanceConfirmModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setShowResetBalanceConfirmModal(false);
              reCalculateBalances();
            }}
          >
            Confirm
          </Button>
        </Row>
      </Modal>

      <Modal
        width="80%"
        footer={null}
        open={feePolicyJSON}
        onCancel={() => {
          setFeePolicyJSON(undefined);
        }}
      >
        <ViewFeePolicyDetail feePolicyJSON={feePolicyJSON} />
      </Modal>
      <Modal
        width="50%"
        open={isDeletingReportModalOpen}
        onCancel={closeDeletingConfirmationModal}
        footer={
          <Row justify={"end"}>
            <Button onClick={closeDeletingConfirmationModal}>No</Button>
            <Button type="primary" onClick={confirmDeleteReport}>
              Yes
            </Button>
          </Row>
        }
      >
        <h3>
          This will remove the existing report from the report table, including
          manual data you added.
        </h3>
        <h3>Are you sure?</h3>
      </Modal>
    </Content>
  );
}
