import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../../Context/displayContext";
import { formItemLayout, tailFormItemLayout } from "../UserPages/CreateUser";

export default function EditWallet() {
  const { walletId = "" } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const displayContext = useContext(DisplayContext);

  const [custodyFeeTypeId, setCustodyFeeTypeId] = useState<number>();
  const [stakingFeeTypeId, setStakingFeeTypeId] = useState<number>();
  const [tradingFeeTypeId, setTradingFeeTypeId] = useState<number>();
  const [custodyeePolicyList, setCustodyFeePolicyList] = useState<any[]>();
  const [stakingFeePolicyList, setStakingFeePolicyList] = useState<any[]>();
  const [tradingFeePolicyList, setTradingFeePolicyList] = useState<any[]>();

  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [clientOptions, setClientOptions] = useState<any[]>();
  const [assetOptions, setAssetOptions] = useState<any[]>();
  const [venueOptions, setVenueOptions] = useState<any[]>();
  const [walletTypeOptions, setWalletTypeOptions] = useState<any[]>();
  const [walletSwitches, setWalletSwitches] = useState<any[]>();
  const [assetNetworkList, setAssetNetworkList] = useState<any>();
  const [selectedClient, setSelectClient] = useState<any>();
  const [clientAccountList, setClientAccountList] = useState<any[]>();
  const [isWalletActivationDisabled, setIsWalletActivationDisabled] =
    useState<boolean>(true);
  const [toDeleteSubWallets] = useState<any[]>([]);

  const [popupVisible, setPopupVisible] = useState(false);
  const [subWallet, setSubWallet] = useState<any>();
  const handleSwitchChange = (checked: boolean, switchId: number) => {
    if (!checked) {
      setSubWallet(form.getFieldValue("subWallets")[switchId]);
      setPopupVisible(true);
    }
  };

  function handleClientSelect(option: any, optionElement: any) {
    setSelectClient(option);
    form.setFieldValue("clientAccountId", undefined);
  }

  const [isFiat, setIsFiat] = useState<boolean>(true);

  const checkCanDelete = (subWalletId: number) => {
    return axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/can-delete-sub-wallet",
      withCredentials: true,
      params: {
        subWalletId: subWalletId,
      },
    });
  };

  const fetchAssetApiUrlUtils = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/asset-url-utils",
      withCredentials: true,
    }).then((res) => {
      setAssetNetworkList(res.data.networks);
    });
  }, []);

  const getVenueList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
    })
      .then((res) => {
        setVenueOptions(res.data.list);
      })
      .catch(() => {
        console.log("Unable to fetch the asset list");
      });
  }, []);

  const getWalletTypes = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallet-type",
      withCredentials: true,
    })
      .then((res) => {
        setWalletTypeOptions(res.data.walletTypes);
      })
      .catch(() => {
        console.log("Unable to fetch the wallet type list");
      });
  }, []);

  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.clients) {
          res.data.clients.forEach(
            (client: any) =>
              (client.label = `${client.label} (${client.deltecAccount})`),
          );
        }
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);

  const getAssetOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        setAssetOptions(options);
      })
      .catch(() => {
        console.log("Unable to fetch the asset list");
      });
  }, []);

  const fetchFeePolicyList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "custody" },
      withCredentials: true,
    }).then((res) => {
      res.data.feePolicyList.forEach((t: any) => {
        t.label = t.name;
        t.value = t.id;
      });
      setCustodyFeePolicyList(res.data.feePolicyList);
      setCustodyFeeTypeId(res.data.feePolicyTypeId);
    });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "staking" },
      withCredentials: true,
    }).then((res) => {
      res.data.feePolicyList.forEach((t: any) => {
        t.label = t.name;
        t.value = t.id;
      });
      setStakingFeePolicyList(res.data.feePolicyList);
      setStakingFeeTypeId(res.data.feePolicyTypeId);
    });

    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "trading" },
      withCredentials: true,
    }).then((res) => {
      res.data.feePolicyList.forEach((t: any) => {
        t.label = t.name;
        t.value = t.id;
      });
      setTradingFeePolicyList(res.data.feePolicyList);
      setTradingFeeTypeId(res.data.feePolicyTypeId);
    });
  }, []);

  // Fetch Fee Policy Related info;
  useEffect(() => {
    fetchFeePolicyList();
  }, [fetchFeePolicyList]);

  const fetchSelectOptions = useCallback(() => {
    getClientOptions();
    getAssetOptions();
    getVenueList();
    getWalletTypes();
  }, [getAssetOptions, getClientOptions, getVenueList, getWalletTypes]);

  useEffect(() => {
    fetchSelectOptions();
  }, [fetchSelectOptions]);

  useEffect(() => {
    if (selectedClient && clientOptions) {
      const c = clientOptions?.find((t) => t.id === selectedClient);
      if (c.clientAccounts && c.clientAccounts.length) {
        setClientAccountList(c.clientAccounts);
        if (!form.getFieldValue("clientAccountId"))
          form.setFieldValue("clientAccountId", c.clientAccounts[0].value);
      } else {
        setClientAccountList([]);
      }
    }
  }, [selectedClient, clientOptions, form]);

  const fetchWalletWithId = useCallback(() => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallet",
      withCredentials: true,
      params: {
        walletId: walletId,
      },
    })
      .then((res) => {
        // Convert Date to dayjs format
        if (res.data.wallet.subWallets && res.data.wallet.subWallets.length) {
          for (const subWallet of res.data.wallet.subWallets) {
            subWallet.activationDate = subWallet.activationDate
              ? dayjs(subWallet.activationDate)
              : undefined;
            subWallet.custodyFeePolicyStartDate =
              subWallet.custodyFeePolicyStartDate
                ? dayjs(subWallet.custodyFeePolicyStartDate)
                : undefined;
            subWallet.stakingFeePolicyStartDate =
              subWallet.stakingFeePolicyStartDate
                ? dayjs(subWallet.stakingFeePolicyStartDate)
                : undefined;
            subWallet.tradingFeePolicyStartDate =
              subWallet.tradingFeePolicyStartDate
                ? dayjs(subWallet.tradingFeePolicyStartDate)
                : undefined;
          }
        }
        res.data.wallet.custodyFeePolicyStartDate = res.data.wallet
          .custodyFeePolicyStartDate
          ? dayjs(res.data.wallet.custodyFeePolicyStartDate)
          : undefined;
        res.data.wallet.stakingFeePolicyStartDate = res.data.wallet
          .stakingFeePolicyStartDate
          ? dayjs(res.data.stakingFeePolicyStartDate)
          : undefined;
        res.data.wallet.tradingFeePolicyStartDate = res.data.wallet
          .tradingFeePolicyStartDate
          ? dayjs(res.data.wallet.tradingFeePolicyStartDate)
          : undefined;
        if (res.data.wallet && res.data.wallet.subWallets) {
          if (res.data.wallet.isActive) {
            setIsWalletActivationDisabled(
              res.data.wallet.subWallets.some((t: any) => t.isActive),
            );
          } else {
            setIsWalletActivationDisabled(false);
          }
        }
        form.setFieldsValue(res.data.wallet);
        setIsFiat(res.data.isFiat);
        setWalletSwitches(res.data.wallet.switches);
        setSelectClient(res.data.wallet.clientId);
      })
      .catch(() => {
        toast.error("Failed to fetch wallet");
        navigate("/bo/wallets/list");
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, [walletId, form, navigate]);

  useEffect(() => {
    fetchWalletWithId();
    fetchAssetApiUrlUtils();
  }, [fetchWalletWithId, fetchAssetApiUrlUtils]);

  const [updating, setUpdating] = useState<boolean>(false);

  const onFinish = (form: any) => {
    setUpdating(true);
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallet",
      withCredentials: true,
      data: {
        walletId: walletId,
        data: {
          clientId: form.clientId,
          clientAccountId: form.clientAccountId,
          walletAddress: form.walletAddress,
          walletTypeId: form.walletTypeId,
          venueId: form.venueId ? form.venueId : "null",
          subWallets: form.subWallets,
          label: form.label,
          isActive: form.isActive,
          isArchived: form.isArchived,
          isClientPersonal: form.isClientPersonal,
          assetNetworkId: form.assetNetworkId ? form.assetNetworkId : null,
          deletedSubWallets: toDeleteSubWallets,
          custodyFeePolicyId: form.custodyFeePolicyId,
          custodyFeePolicyTypeId: custodyFeeTypeId,
          custodyFeePolicyStartDate: form.custodyFeePolicyStartDate,
          stakingFeePolicyTypeId: stakingFeeTypeId,
          stakingFeePolicyId: form.stakingFeePolicyId,
          stakingFeePolicyStartDate: form.stakingFeePolicyStartDate,
          tradingFeePolicyTypeId: tradingFeeTypeId,
          tradingFeePolicyId: form.tradingFeePolicyId,
          tradingFeePolicyStartDate: form.tradingFeePolicyStartDate,
        },
      },
    })
      .then((res) => {
        setUpdating(false);
        navigate("/bo/confirmation", {
          state: {
            pageType: "Wallet",
            createOrUpdate: "Update",
            displayName: form.label,
            createUrl: "/bo/wallet/create",
            editUrl:
              "/bo/wallet/update/" + encodeURIComponent(res.data.wallet.id),
          },
        });
        toast.success("Wallet Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
        toast.error(err.response.data.message);
      });
  };

  return onFinshiLoad ? (
    <Content id="dcl-edit-wallet-page">
      <Row>
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Button
            onClick={() => {
              if (location.state) {
                navigate(location.state.from, { state: location.state });
              } else {
                navigate("/bo/wallets/list");
              }
            }}
          >
            <FontAwesomeIcon
              icon={"fa-solid fa-circle-chevron-left" as IconProp}
            />
          </Button>
        </Col>
      </Row>

      <Form
        {...formItemLayout}
        form={form}
        labelWrap
        autoComplete="off"
        onFinish={onFinish}
        className="form-body"
        labelCol={{
          sm: {
            span: 7,
          },
        }}
        wrapperCol={{
          sm: {
            span: 14,
          },
        }}
        style={{
          padding: "1em",
          margin: "auto",
        }}
      >
        <Divider orientation="left">
          <h2>Wallet Info</h2>
        </Divider>
        <Row>
          <Col sm={{ span: 12, order: 1 }} xs={{ span: 24, order: 1 }}>
            <Form.Item
              label="Client"
              name="clientId"
              rules={[
                {
                  required: true,
                  message: "Please select client",
                },
              ]}
            >
              <Select
                options={clientOptions}
                onSelect={handleClientSelect}
                showSearch
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              />
            </Form.Item>
            <Form.Item label="Client Account" name="clientAccountId">
              <Select options={clientAccountList} />
            </Form.Item>
            <Form.Item label="Venue" name="venueId">
              <Select
                options={venueOptions}
                showSearch
                allowClear
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              />
            </Form.Item>
            <Form.Item
              label="Label"
              name="label"
              rules={[
                {
                  required: true,
                  message: "Please input wallet label",
                },
              ]}
            >
              <Input placeholder="Please enter wallet label" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 12, order: 2 }} xs={{ span: 24, order: 2 }}>
            <Form.Item name="assetNetworkId" label="Network" hidden={isFiat}>
              <Select
                showSearch
                allowClear
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                options={assetNetworkList}
                placeholder="Network (Ignore if Fiat asset)"
              />
            </Form.Item>

            <Form.Item
              label="Address"
              name="walletAddress"
              rules={[
                {
                  required: true,
                  message:
                    "Please input wallet address. If no address, please enter a label or '-' ",
                },
              ]}
            >
              <Input placeholder="Please enter wallet address" />
            </Form.Item>
            <Form.Item
              label="Client Personal Wallet"
              name="isClientPersonal"
              valuePropName="checked"
              initialValue={false}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Is Active"
              name="isActive"
              valuePropName="checked"
            >
              <Switch disabled={isWalletActivationDisabled} />
            </Form.Item>
            {walletSwitches?.map((t, i) => (
              <Form.Item
                className="dcl-switch"
                key={i}
                label={
                  t.value ? (
                    <b className="dcl-label-activated">Activated Date</b>
                  ) : (
                    <b className="dcl-label-archived">Archived Date</b>
                  )
                }
              >
                {new Date(t.date).toLocaleString("en-US", {
                  timeZone: displayContext?.displayContext.timezone,
                  timeZoneName: "short",
                })}
              </Form.Item>
            ))}
          </Col>
        </Row>
        <Divider orientation="left">
          <h2>Wallet Fee Policies</h2>
        </Divider>
        <Row>
          <Col span={7} offset={1}>
            <Card title="Custody">
              <Form.Item label="Custody Fee Policy" name="custodyFeePolicyId">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={custodyeePolicyList}
                  placeholder="Custody Fee Policy"
                />
              </Form.Item>
              <Form.Item
                label="Custody Fee Policy Start Date"
                name="custodyFeePolicyStartDate"
              >
                <DatePicker />
              </Form.Item>
            </Card>
          </Col>
          <Col span={7} offset={1}>
            <Card title="Staking">
              <Form.Item label="Staking Fee Policy" name="stakingFeePolicyId">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={stakingFeePolicyList}
                  placeholder={"Staking Fee Policy"}
                />
              </Form.Item>
              <Form.Item
                label="Staking Fee Policy Start Date"
                name="stakingFeePolicyStartDate"
              >
                <DatePicker />
              </Form.Item>
            </Card>
          </Col>
          <Col span={7} offset={1}>
            <Card title="Trading">
              <Form.Item label="Trading Fee Policy" name={"tradingFeePolicyId"}>
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={tradingFeePolicyList}
                  placeholder="Trading Fee Policy"
                />
              </Form.Item>
              <Form.Item
                label="Ttaking Fee Policy Start Date"
                name="tradingFeePolicyStartDate"
              >
                <DatePicker />
              </Form.Item>
            </Card>
          </Col>
          <Divider />
        </Row>
        <Divider orientation="left">
          <h2>Subwallets</h2>
        </Divider>
        <Row>
          <Col sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}>
            <Form.List name="subWallets">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <React.Fragment key={key}>
                      <Row>
                        <Col sm={{ span: 6, offset: 1 }}>
                          <Form.Item
                            label="Asset"
                            wrapperCol={{
                              sm: {
                                span: 24,
                                offset: 0,
                              },
                            }}
                            {...restField}
                            name={[name, "assetId"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing asset",
                              },
                            ]}
                          >
                            <Select
                              options={assetOptions}
                              showSearch
                              popupMatchSelectWidth={false}
                              filterOption={(input, option: any) => {
                                return option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase());
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col sm={{ span: 6 }}>
                          <Form.Item
                            wrapperCol={{
                              sm: {
                                span: 24,
                                offset: 0,
                              },
                            }}
                            label="Label"
                            {...restField}
                            name={[name, "label"]}
                          >
                            <TextArea autoSize />
                          </Form.Item>
                        </Col>
                        <Col sm={{ span: 4 }}>
                          <Form.Item
                            wrapperCol={{
                              sm: {
                                span: 24,
                                offset: 0,
                              },
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please select wallet type",
                              },
                            ]}
                            label="Type"
                            {...restField}
                            name={[name, "walletTypeId"]}
                          >
                            <Select
                              options={walletTypeOptions}
                              showSearch
                              popupMatchSelectWidth={false}
                              filterOption={(input, option: any) => {
                                return option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase());
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={{ span: 6, offset: 1 }}>
                          <Form.Item
                            label="Activation Date"
                            wrapperCol={{
                              sm: {
                                span: 24,
                                offset: 0,
                              },
                            }}
                            labelCol={{
                              sm: {
                                span: 12,
                                offset: 0,
                              },
                            }}
                            {...restField}
                            name={[name, "activationDate"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing activation date.",
                              },
                            ]}
                            initialValue={dayjs()}
                          >
                            <DatePicker />
                          </Form.Item>
                        </Col>
                        <Col sm={{ span: 4 }}>
                          <Form.Item
                            labelCol={{
                              sm: {
                                span: 20,
                              },
                            }}
                            wrapperCol={{
                              sm: {
                                span: 4,
                                offset: 0,
                              },
                            }}
                            {...restField}
                            label="Is Active"
                            name={[name, "isActive"]}
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Switch
                              size="small"
                              onChange={(checked) =>
                                handleSwitchChange(checked, key)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={{ span: 4 }}>
                          <Form.Item
                            labelCol={{
                              sm: {
                                span: 20,
                              },
                            }}
                            wrapperCol={{
                              sm: {
                                span: 4,
                                offset: 0,
                              },
                            }}
                            {...restField}
                            label="In Report"
                            name={[name, "isReport"]}
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Switch size="small" />
                          </Form.Item>
                        </Col>
                        <Col sm={{ span: 4 }}>
                          <Form.Item
                            labelCol={{
                              sm: {
                                span: 18,
                              },
                            }}
                            wrapperCol={{
                              sm: {
                                span: 6,
                                offset: 0,
                              },
                            }}
                            {...restField}
                            label="Is Placeholder"
                            name={[name, "isPlaceholder"]}
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Checkbox />
                          </Form.Item>
                        </Col>
                        <Col sm={{ span: 2 }}>
                          <Popconfirm
                            placement="right"
                            title="Are you sure to delete this sub-wallet?"
                            onConfirm={() => {
                              if (
                                form.getFieldValue("subWallets")[key] &&
                                form.getFieldValue("subWallets")[key].id
                              ) {
                                checkCanDelete(
                                  form.getFieldValue("subWallets")[key].id,
                                )
                                  ?.then(() => {
                                    toDeleteSubWallets.push(
                                      form.getFieldValue("subWallets")[key].id,
                                    );
                                    remove(name);
                                  })
                                  .catch(() => {
                                    toast.error(
                                      "Cannot delete this sub-wallet.",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      },
                                    );
                                  });
                              } else {
                                remove(name);
                              }
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            <MinusCircleOutlined onClick={() => {}} />
                          </Popconfirm>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={7} offset={1}>
                          <Card title="Custody">
                            <Form.Item
                              label="Custody Fee Policy"
                              name={[name, "custodyFeePolicyId"]}
                            >
                              <Select
                                showSearch
                                allowClear
                                filterOption={(input, option: any) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={custodyeePolicyList}
                                placeholder="Custody Fee Policy"
                              />
                            </Form.Item>
                            <Form.Item
                              label="Custody Fee Policy Start Date"
                              name={[name, "custodyFeePolicyStartDate"]}
                            >
                              <DatePicker />
                            </Form.Item>
                          </Card>
                        </Col>
                        <Col span={7} offset={1}>
                          <Card title="Staking">
                            <Form.Item
                              label="Staking Fee Policy"
                              name={[name, "stakingFeePolicyId"]}
                            >
                              <Select
                                showSearch
                                allowClear
                                filterOption={(input, option: any) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={stakingFeePolicyList}
                                placeholder={"Staking Fee Policy"}
                              />
                            </Form.Item>
                            <Form.Item
                              label="Staking Fee Policy Start Date"
                              name={[name, "stakingFeePolicyStartDate"]}
                            >
                              <DatePicker />
                            </Form.Item>
                          </Card>
                        </Col>
                        <Col span={7} offset={1}>
                          <Card title="Trading">
                            <Form.Item
                              label="Trading Fee Policy"
                              name={[name, "tradingFeePolicyId"]}
                            >
                              <Select
                                showSearch
                                allowClear
                                filterOption={(input, option: any) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={tradingFeePolicyList}
                                placeholder="Trading Fee Policy"
                              />
                            </Form.Item>
                            <Form.Item
                              label="Ttaking Fee Policy Start Date"
                              name={[name, "tradingFeePolicyStartDate"]}
                            >
                              <DatePicker />
                            </Form.Item>
                          </Card>
                        </Col>
                        <Divider />
                      </Row>
                    </React.Fragment>
                  ))}
                  <Form.Item
                    wrapperCol={{
                      sm: {
                        span: 12,
                        offset: 6,
                      },
                    }}
                  >
                    <Button
                      type="dashed"
                      onClick={() => {
                        add({ newlyAdded: true, edited: false });
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add New Asset
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Form.Item {...tailFormItemLayout}>
          <Button
            htmlType="submit"
            className="submit-button primary-button"
            loading={updating}
          >
            Update Wallet
          </Button>
        </Form.Item>
      </Form>
      <Modal
        destroyOnClose={true}
        open={popupVisible}
        onOk={() => setPopupVisible(false)}
        onCancel={() => {
          setPopupVisible(false);
          subWallet.archivedDate = undefined;
          subWallet.isActive = true;
        }}
      >
        <h2 style={{ color: "white" }}>
          {subWallet && subWallet.label
            ? `${subWallet ? subWallet.label : ""} (${
                subWallet && subWallet.asset ? subWallet.asset.ticker : ""
              })`
            : ""}
        </h2>
        <Row>
          <Col span={8}>
            <p>Archived Date: </p>
          </Col>
          <Col span={12}>
            {" "}
            <DatePicker
              onChange={(value) => {
                subWallet.archivedDate = value?.toDate();
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <p>Memo: </p>
          </Col>
          <Col span={12}>
            <TextArea
              onChange={(e) => {
                subWallet.memo = e.target.value;
              }}
            />
          </Col>
        </Row>
      </Modal>
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
