import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SupportedAssetList({ clientFacing = false }) {
  const navigate = useNavigate();
  const [bucketPrefix, setBucketPrefix] = useState<string | null>(null);
  const [assets, setAssetList] = useState<any>([]);
  const [assetsDisplaying, setAssetsDisplaying] = useState<any>([]);
  const [assetTypeList, setAssetTypeList] = useState<any[]>();
  // const [displayingTotal, setDisplayingTotal] = useState<number>();
  const getAssetTypes = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/asset-type",
      withCredentials: true,
    })
      .then((res) => {
        setAssetTypeList(res.data);
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);
  useEffect(() => {
    getAssetTypes();
  }, [getAssetTypes]);
  const [isFetchingAssetList, setIsFetchingAssetList] =
    useState<boolean>(false);

  const fetchAssetList = useCallback(() => {
    setIsFetchingAssetList(true);
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/active-list",
      withCredentials: true,
    })
      .then((res) => {
        setAssetList(res.data.assetList);
        setAssetsDisplaying(res.data.assetList);
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setIsFetchingAssetList(false);
      });
  }, [navigate]);

  useEffect(() => {
    fetchAssetList();
  }, [fetchAssetList]);

  const fetchBucketPrefix = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/media-bucket-prefix",
      withCredentials: true,
    })
      .then((response) => {
        setBucketPrefix(response.data.prefix);
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  useEffect(() => {
    fetchBucketPrefix();
  }, [fetchBucketPrefix]);

  const [filterForm] = Form.useForm();
  const onReset = useCallback(() => {
    setIsFetchingAssetList(true);
    filterForm.resetFields();
    setAssetsDisplaying(assets);
    setIsFetchingAssetList(false);
  }, [assets, filterForm]);
  const filterAsset = useCallback(
    (form: any) => {
      let res = assets;
      const assetIds = form.assetIds;
      const assetType = form.assetType;
      if (assetIds?.length) {
        res = res.filter((row: any) => assetIds.includes(row.id));
      }
      if (assetType) {
        res = res.filter((row: any) => Number(assetType) === row.assetTypeId);
      }
      setIsFetchingAssetList(true);
      setAssetsDisplaying(res);
      setIsFetchingAssetList(false);
    },
    [assets],
  );

  if (!bucketPrefix) {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: clientFacing ? "#fff" : "#13c2c2",
          },
        }}
      >
        <Row justify={"center"}>
          <Spin />
        </Row>
      </ConfigProvider>
    );
  }

  return (
    <Content id="supported-asset-list">
      <ConfigProvider
        theme={{
          components: clientFacing
            ? {
                Select: {
                  optionSelectedBg: "#22293B",
                  optionActiveBg: "#22293B",
                  multipleItemBg: "#22293B",
                  borderRadius: 30,
                },
                Input: {
                  borderRadius: 30,
                  hoverBorderColor: "rgba(255,255,255,0.3)",
                },
                DatePicker: {
                  borderRadius: 30,
                  hoverBorderColor: "rgba(255,255,255,0.3)",
                },
                Button: {
                  defaultColor: "rgba(255,255,255, 0.8)",
                  defaultHoverBorderColor: "rgba(255,255,255,0.3)",
                  defaultHoverColor: "#fff",
                  borderRadius: 30,
                },
                Table: {
                  borderColor: "rgba(255,255,255,0.1)",
                  colorBgBase: "#22293B",
                  headerBg: "#22293B",
                  rowHoverBg: "#14171E",
                  headerSplitColor: "rgba(255,255,255,0.1)",
                  bodySortBg: "#22293B",
                },
              }
            : undefined,
          token: {
            colorPrimary: clientFacing ? "#fff" : "#19bdc2",
          },
        }}
      >
        <Row className="dcl-filter-row">
          <Col md={{ span: 16 }} sm={{ span: 16 }}>
            <Form
              title="Filter"
              form={filterForm}
              onFinish={filterAsset}
              className={
                clientFacing
                  ? "dcl-toggled-content-dark dcl-toggled-content-filter"
                  : "dcl-toggled-content dcl-toggled-content-filter"
              }
            >
              <Row>
                <Form.Item name="assetIds" className="dcl-filter-item">
                  <Select
                    mode="multiple"
                    allowClear
                    className="dcl-asset-select"
                    placeholder="Assets"
                    options={assets.map((row: any) => ({
                      label: `${row.name}(${row.ticker})`,
                      value: row.id,
                      key: row.id,
                    }))}
                    popupMatchSelectWidth={false}
                    filterOption={(input, option: any) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  />
                </Form.Item>
                <Form.Item name="assetType" className="dcl-filter-item">
                  <Select
                    placeholder="Asset Type"
                    popupMatchSelectWidth={false}
                    allowClear
                    className="dcl-asset-type-select"
                    options={assetTypeList?.map((assetType) => ({
                      label: assetType.name,
                      key: assetType.id,
                      value: assetType.id,
                    }))}
                  />
                </Form.Item>
              </Row>
              <Row justify="end">
                <Space>
                  <ConfigProvider
                    theme={
                      clientFacing
                        ? {
                            components: {
                              Button: {
                                defaultBg: "#4A78F7",
                                defaultBorderColor: "#4A78F7",
                                defaultHoverBg: "#0F44D6",
                                defaultHoverBorderColor: "#0F44D6",
                              },
                            },
                          }
                        : undefined
                    }
                  >
                    <Space>
                      {assetsDisplaying?.length}
                      <b>Results</b>
                    </Space>
                    <Button
                      htmlType="submit"
                      loading={isFetchingAssetList}
                      type={clientFacing ? 'default' : "primary"}
                    >
                      Apply
                    </Button>
                  </ConfigProvider>
                  <Button onClick={onReset} loading={isFetchingAssetList}>
                    Reset
                  </Button>
                </Space>
              </Row>
            </Form>
          </Col>
        </Row>

        <Table
          className={clientFacing ? "client-table-dark" : undefined}
          sticky
          dataSource={assetsDisplaying}
          rowKey="id"
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
          }}
          columns={[
            {
              title: "Asset Ticker",
              dataIndex: "ticker",
              sorter: (a: any, b: any) => a.ticker.localeCompare(b.ticker),
              defaultSortOrder: "ascend",
              width: "150px",
              render: (_: any, { id, name, ticker }: any) => (
                <Row align={"middle"}>
                  <img
                    data-id={id}
                    src={`${bucketPrefix}${id}.png`}
                    alt=""
                    style={{
                      width: "25px",
                      height: "25px",
                      marginRight: "0.5em",
                      objectFit: "cover",
                    }}
                  />
                  <b>{ticker}</b>
                </Row>
              ),
            },
            {
              title: "Asset Name",
              dataIndex: "name",
              sorter: (a: any, b: any) => a.ticker.localeCompare(b.name),
              render: (_: any, { name }: any) => (
                <Row align={"middle"}>{name}</Row>
              ),
            },
            {
              title: "Networks Supported",
              dataIndex: "networks",
              render: (_: any, { networks, id }: any) =>
                networks.map((networkName: string) => (
                  <Row key={`${id}_${networkName}`}>{networkName}</Row>
                )),
            },
          ]}
        />
      </ConfigProvider>
    </Content>
  );
}
