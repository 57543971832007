import { Content } from "antd/es/layout/layout";
import ComingSoon from "./ClientComingSoon";

export default function ClientLedgerTransactions() {
  return (
    <Content id="client-ledger-transactions">
      <ComingSoon />
    </Content>
  );
}
