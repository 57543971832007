import { QuestionCircleOutlined } from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Col,
  ConfigProvider,
  Layout,
  Modal,
  Popover,
  Row,
  Spin,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import HTMLReactParser from "html-react-parser";
import { MenuInfo } from "rc-menu/lib/interface";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import appVersion from "../../appConfig";
import { TitleContext } from "../Context/titleContext";
import { UserContext } from "../Context/userContext";
import NavBar from "./NavBar";
import SideBar from "./SideBar";

function getItem(
  label: any,
  key: any,
  title?: any,
  icon?: any,
  children?: any,
  isactive = "true",
) {
  return {
    key,
    icon,
    children,
    label,
    title,
    isactive,
  };
}

export default function ProtectedLayout() {
  const titleContext = useContext(TitleContext);
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContext(UserContext);
  const user = useContext(UserContext)?.user;
  const [routeToLogin, setRouteToLogin] = useState(false);
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [topMenuItems, setTopMenuItems] = useState<any[]>([]);
  const [menu, setMenu] = useState<any>();
  const [routeMap, setRouteMap] = useState(new Map());
  const [titleArray, setTitleArr] = useState<any[]>();
  const [isLatestVersion, setIsLatestVersion] = useState<boolean>(true);
  const [collapsed, setCollapsed] = useState(false);
  const [redirectFlag, setRedirectFlag] = useState(false);

  const handleMenuClick = (info: MenuInfo) => {
    if (routeMap.get(info.key)?.startsWith("http")) {
      window.open(routeMap.get(info.key));
      return false;
    } else {
      const selected = titleArray?.find((e: any) => e.key === info.key);
      titleContext?.setTitleContext({
        title: selected ? selected.title : "",
        helperText: selected ? selected.helperText : "",
      });
      navigate(routeMap.get(info.key));
      return true;
    }
  };

  const fetchSession = useCallback(() => {
    const maxRetries = 3;
    let attempt = 0;
    const tryFetch = () => {
      axios({
        method: "GET",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/user/session/",
        withCredentials: true,
      })
        .then((res) => {
          if (user?.id === 0) {
            setRouteToLogin(false);
            const userJSON = res.data;
            context?.setUser({
              id: userJSON["id"],
              email: userJSON["email"],
              roleId: userJSON["roleId"],
              capabilities: userJSON["userCapabilities"],
              fullname: userJSON["fullname"],
              clients: userJSON["clients"],
              selectedClient: userJSON["clients"]?.[0],
              roleName: userJSON["roleName"],
            });
          }
          if (res.data.feVersion !== appVersion) {
            setIsLatestVersion(false);
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.status === 502 &&
            attempt < maxRetries
          ) {
            attempt++;
            // Optionally, use exponential backoff delay
            setTimeout(tryFetch, 1000 * Math.pow(2, attempt));
            return;
          }
          //Cookies.remove("connect.sid");
          setRouteToLogin(true);
          if (context?.user && context.user.id !== 0)
            axios({
              method: "POST",
              url:
                process.env.REACT_APP_AWS_BACKEND_URL +
                "/authentication/log-out/",
              withCredentials: true,
            }).catch(() => {});
          context?.setUser({
            id: 0,
            email: "",
            roleId: 0,
            capabilities: [],
            fullname: "",
            clients: [],
            selectedClient: undefined,
            roleName: "",
          });
        });
    };
    tryFetch();
  }, [context, user]);

  const handleAcceptPageRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  const fetchSideMenuItems = useCallback(
    (roleId: number, clientId?: string) => {
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/menu-items",
        params: {
          roleId: roleId,
          clientId: clientId || undefined,
        },
        withCredentials: true,
      })
        .then((res) => {
          setRedirectFlag(res.data.redirectFlag);
          const items: any[] = [];
          const menuItemsArray = res.data.sideMenuItems;
          const topItems = [];
          const topMenuItemArray = res.data.topMenuItems;
          const map = new Map();
          const titleArray: any[] = [];
          for (const [index, key] of Object.keys(menuItemsArray).entries()) {
            const menuItem = menuItemsArray[key];
            const uniqueKey = `${menuItem.label}-${index}`; // Unique key
            // console.log(menuItem.children);
            if (menuItem.isActive) {
              if (!menuItem.children) {
                map.set(uniqueKey, menuItem.link);
                const item = menuItem.iconName
                  ? getItem(
                      menuItem.label,
                      uniqueKey,
                      menuItem.label,
                      <FontAwesomeIcon icon={menuItem.iconName as IconProp} />,
                      undefined,
                      String(menuItem.isActive),
                    )
                  : getItem(
                      menuItem.label,
                      uniqueKey,
                      menuItem.label,
                      undefined,
                      undefined,
                      String(menuItem.isActive),
                    );
                items.push(item);
                titleArray.push({
                  key: uniqueKey,
                  label: menuItem.label,
                  title: menuItem.pageTitle
                    ? menuItem.pageTitle
                    : menuItem.label,
                  helperText: menuItem.helper,
                });
              } else {
                //console.log(menuItem.children);
                const childrenItems = [];
                for (const [childIndex, child] of menuItem.children.entries()) {
                  const uniqueChildKey = `${child.label}-${childIndex}`; // Unique key for child
                  map.set(uniqueChildKey, child.link);

                  const childItem = child.iconName
                    ? getItem(
                        child.label,
                        uniqueChildKey, // Use unique key here
                        child.label,
                        <FontAwesomeIcon icon={child.iconName as IconProp} />,
                        undefined,
                        String(child.isActive),
                      )
                    : getItem(
                        child.label,
                        uniqueChildKey, // Use unique key here
                        child.label,
                        undefined,
                        undefined,
                        String(child.isActive),
                      );
                  childrenItems.push(childItem);
                  titleArray.push({
                    key: uniqueChildKey,
                    label: child.label,
                    title: child.pageTitle ? child.pageTitle : child.label,
                    helperText: child.helper,
                  });
                }
                const item = menuItem.iconName
                  ? getItem(
                      menuItem.label,
                      uniqueKey,
                      menuItem.label,
                      <FontAwesomeIcon icon={menuItem.iconName as IconProp} />,
                      childrenItems,
                      String(menuItem.isActive),
                    )
                  : getItem(
                      menuItem.label,
                      uniqueKey,
                      menuItem.label,
                      undefined,
                      childrenItems,
                      String(menuItem.isActive),
                    );
                items.push(item);
              }
            } else {
              titleArray.push({
                key: uniqueKey,
                label: menuItem.label,
                title: menuItem.pageTitle ? menuItem.pageTitle : menuItem.label,
                helperText: menuItem.helper,
              });
              map.set(uniqueKey, menuItem.link);
            }
          }

          for (const [index, key] of Object.keys(topMenuItemArray).entries()) {
            const menuItem = topMenuItemArray[key];
            const uniqueKey = `${menuItem.label}-${index}`; // Unique key
            // console.log(menuItem.children);
            if (menuItem.isActive) {
              if (!menuItem.children) {
                map.set(uniqueKey, menuItem.link);
                const item = menuItem.iconName
                  ? getItem(
                      <Link to={menuItem.link}>
                        <span>{menuItem.label}</span>
                      </Link>,
                      uniqueKey,
                      menuItem.label,
                      <FontAwesomeIcon icon={menuItem.iconName as IconProp} />,
                      undefined,
                      String(menuItem.isActive),
                    )
                  : getItem(
                      <Link to={menuItem.link}>
                        <span>{menuItem.label}</span>
                      </Link>,
                      uniqueKey,
                      menuItem.label,
                      undefined,
                      undefined,
                      String(menuItem.isActive),
                    );
                topItems.push(item);
                titleArray.push({
                  key: uniqueKey,
                  label: menuItem.label,
                  title: menuItem.pageTitle
                    ? menuItem.pageTitle
                    : menuItem.label,
                  helperText: menuItem.helper,
                });
              } else {
                //console.log(menuItem.children);
                const childrenItems = [];
                for (const [childIndex, child] of menuItem.children.entries()) {
                  const uniqueChildKey = `${child.label}-${childIndex}`; // Unique key for child
                  map.set(child.label, child.link);

                  const childItem = child.iconName
                    ? getItem(
                        child.label,
                        uniqueChildKey,
                        child.label,
                        <FontAwesomeIcon icon={child.iconName as IconProp} />,
                        undefined,
                        String(child.isActive),
                      )
                    : getItem(
                        child.label,
                        uniqueChildKey,
                        child.label,
                        undefined,
                        undefined,
                        String(child.isActive),
                      );
                  childrenItems.push(childItem);
                  titleArray.push({
                    key: uniqueChildKey,
                    label: child.label,
                    title: child.pageTitle ? child.pageTitle : child.label,
                    helperText: child.helper,
                  });
                }
                const item = menuItem.iconName
                  ? getItem(
                      menuItem.label,
                      uniqueKey,
                      menuItem.label,
                      <FontAwesomeIcon icon={menuItem.iconName as IconProp} />,
                      childrenItems,
                      String(menuItem.isActive),
                    )
                  : getItem(
                      menuItem.label,
                      uniqueKey,
                      menuItem.label,
                      undefined,
                      childrenItems,
                      String(menuItem.isActive),
                    );
                topItems.push(item);
              }
            } else {
              titleArray.push({
                key: uniqueKey,
                label: menuItem.label,
                title: menuItem.pageTitle ? menuItem.pageTitle : menuItem.label,
                helperText: menuItem.helper,
              });
              map.set(uniqueKey, menuItem.link);
            }
          }
          setTitleArr(titleArray);
          setRouteMap(map);
          setMenu(Object.values(res.data));
          items.forEach((item) => {
            if (item.children && item.children.length) {
              item.children = item.children.filter(
                (t: any) => t.isactive === "true",
              );
            }
          });
          setMenuItems(items);
          setTopMenuItems(topItems);
        })
        .catch((err) => {
          console.log(err);
          console.log("Failed to fetch menu");
        });
    },
    [],
  );

  useEffect(() => {
    const roleId = user?.roleId ? user?.roleId : user?.selectedClient?.roleId;
    const clientId = user?.selectedClient?.clientId
      ? String(user?.selectedClient?.clientId)
      : undefined;
    if (roleId) {
      fetchSideMenuItems(roleId, clientId);
    }
  }, [user, fetchSideMenuItems]);

  useEffect(() => {
    if (redirectFlag) {
      setRedirectFlag(false);
      navigate("/");
    }
  }, [redirectFlag, navigate]);

  useEffect(() => {
    if (routeMap.size > 0) {
      const menuFound = [...routeMap.values()].find((e) => {
        return location.pathname.startsWith(e);
      });
      if (!menuFound) {
        const dashboardKey = [...routeMap.keys()].find(
          (e) => e.split("-")?.[0]?.toLocaleLowerCase() === "dashboard",
        );
        const dashboard = routeMap.get(dashboardKey);
        navigate(dashboard);
      }
    }
    if (location.pathname.startsWith("/bo")) {
      axios({
        method: "GET",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/bo",
        withCredentials: true,
      })
        .then(() => {
          setRouteToLogin(false);
        })
        .catch((err) => {
          setRouteToLogin(true);
          axios({
            method: "POST",
            url:
              process.env.REACT_APP_AWS_BACKEND_URL +
              "/authentication/log-out/",
            withCredentials: true,
          }).catch((e) => {
            console.error(e);
          });
          context?.setUser({
            id: 0,
            email: "",
            roleId: 0,
            capabilities: [],
            fullname: "",
            clients: [],
            selectedClient: undefined,
            roleName: "",
          });
        });
    }
  }, [location, context, menu, navigate, routeMap]);

  useEffect(() => {
    fetchSession();
  }, [fetchSession]);

  const clientGlboalTokens = {
    colorText: "white",
    colorTextPlaceholder: "rgba(255,255,255,0.6)",
    colorBgBase: "#1B1F2B",
    colorBgContainer: "#1B1F2B",
  };

  useEffect(() => {
    if (routeMap.size > 0) {
      let pathname = location.pathname;
      let menuFound = [...routeMap.entries()].find((e) => {
        return pathname === e[1];
      });
      if (!menuFound) {
        const pathArr = pathname.split("/");
        pathArr.pop();
        pathname = pathArr.join("/");
        menuFound = [...routeMap.entries()].find((e) => {
          return pathname === e[1];
        });
      }
      const titleItem = titleArray?.find((t) => t.key === menuFound?.[0]);
      if (titleItem) {
        titleContext?.setTitleContext({
          title: titleItem.title ? titleItem.title : "",
          helperText: titleItem.helperText ? titleItem.helperText : "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeMap, location, titleArray]);

  if (routeToLogin) {
    return <Navigate to="/login" state={{ initialPath: location.pathname }} />;
  }
  return user?.id !== 0 ? (
    <React.Fragment>
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              headerBg: "#14171E",
              siderBg: "#14171E",
            },
          },
          token:
            context?.user?.roleId === 4 || context?.user?.roleId === 5
              ? clientGlboalTokens
              : undefined,
        }}
      >
        {!isLatestVersion ? (
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  defaultBg: "#4A78F7",
                  defaultBorderColor: "#4A78F7",
                  defaultHoverBg: "#0F44D6",
                  defaultHoverBorderColor: "#0F44D6",
                },
                Modal: { contentBg: "#1D212C" },
              },
              token: {
                colorPrimary: "#fff",
              },
            }}
          >
            <Layout>
              <Modal
                width={"50%"}
                open={!isLatestVersion}
                closable={false}
                footer={
                  <Button
                    size="large"
                    onClick={() => {
                      handleAcceptPageRefresh();
                    }}
                    className={"refresh-button"}
                  >
                    Refresh
                  </Button>
                }
              >
                <Alert
                  className="new-version-alert"
                  message={
                    <b>
                      New Release!
                      <br />
                      We are happy to share with you that a newer version of the
                      application has been found.
                      <br /> Please refresh the page to get it.
                    </b>
                  }
                  type="warning"
                />
              </Modal>
            </Layout>
          </ConfigProvider>
        ) : (
          <React.Fragment>
            <Layout
              className={
                context?.user?.roleId === 4 || context?.user?.roleId === 5
                  ? "dcl-page-dark"
                  : undefined
              }
            >
              <NavBar
                items={menuItems}
                topItems={topMenuItems}
                handleMenuClick={handleMenuClick}
                collapsed={collapsed}
                routeMap={routeMap}
              />
            </Layout>
            <Layout
              style={{
                minHeight: "100vh",
                paddingTop: "64px",
              }}
              className={
                context?.user?.roleId === 4 || context?.user?.roleId === 5
                  ? "dcl-page-dark"
                  : undefined
              }
            >
              <SideBar
                items={menuItems}
                handleMenuClick={handleMenuClick}
                routeMap={routeMap}
                collapsed={collapsed}
                onCollapse={setCollapsed}
                style={{
                  height: "calc(100vh - 64px)", // Adjust based on your layout header height
                  overflowY: "auto",
                }}
              />

              <Content
                className={
                  context?.user?.roleId === 4 || context?.user?.roleId === 5
                    ? "dcl-page-dark"
                    : undefined
                }
              >
                <Row
                  className={
                    context?.user?.roleId === 4 || context?.user?.roleId === 5
                      ? "dcl-page-title-dark"
                      : "dcl-page-title"
                  }
                >
                  {titleContext?.titleContext.helperText ? (
                    <React.Fragment>
                      <Col sm={2} xs={2} />
                      <Col sm={20} xs={20}>
                        <h1
                          className={
                            context?.user?.roleId === 4 ||
                            context?.user?.roleId === 5
                              ? "dcl-page-title-dark"
                              : "dcl-page-title"
                          }
                        >
                          {titleContext?.titleContext.title}
                        </h1>
                      </Col>
                      <Col sm={2} xs={2}>
                        <Popover
                          trigger="click"
                          className="dcl-helper"
                          overlayClassName="custom-popover"
                          content={
                            titleContext?.titleContext.helperText ? (
                              <div className="dcl-helper-content">
                                {HTMLReactParser(
                                  titleContext?.titleContext.helperText,
                                )}
                              </div>
                            ) : (
                              ""
                            )
                          }
                          placement="bottomLeft"
                        >
                          <QuestionCircleOutlined />
                        </Popover>
                      </Col>
                    </React.Fragment>
                  ) : (
                    <Col sm={24} xs={24}>
                      <h1>{titleContext?.titleContext.title}</h1>
                    </Col>
                  )}
                </Row>
                <Outlet />
                <Row style={{ marginBottom: "2em" }} />
              </Content>
            </Layout>
          </React.Fragment>
        )}
      </ConfigProvider>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              bodyBg: "#1B1F2B",
            },
          },
          token: {
            colorPrimary: "#4A78F7",
          },
        }}
      >
        <Layout
          style={{
            minHeight: "100vh",
            paddingTop: "30vh",
          }}
        >
          <Spin size="large" />
        </Layout>
      </ConfigProvider>
    </React.Fragment>
  );
}
