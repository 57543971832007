import {
  Button, Col, Form,
  Input,
  Radio,
  Row,
  Select
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ViewScanResult from "./ViewScanResult";

export default function ComplianceTransactionScan() {
  const [scanForm] = Form.useForm();
  const [serviceAssetNetworkMap, setServiceAssetNetworkMap] = useState<any[]>(
    [],
  );
  const [assetOptions, setAssetOptions] = useState<any[]>([]);
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [scanResult, setScanResult] = useState<any>(undefined);
  const getServiceAssetNetworkList = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/asset/service-asset-network",
      withCredentials: true,
    })
      .then((res) => {
        setServiceAssetNetworkMap(
          res.data.data?.map((row: any) => ({
            label: `${row.value} (${row.serviceName})`,
            value: row.id,
            key: row.id,
          })),
        );
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);
  const getAssetList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    }).then((res) => {
      const options = [];
      for (const asset of res.data.assetList) {
        options.push({
          key: asset.id,
          label: `${asset.name} (${asset.ticker})`,
          value: asset.id,
        });
      }
      options.sort((a, b) => a.label.localeCompare(b.label));
      setAssetOptions(options);
    });
  }, []);
  useEffect(() => {
    getAssetList();
    getServiceAssetNetworkList();
  }, [getAssetList, getServiceAssetNetworkList]);
  const scanTransaction = useCallback(() => {
    scanForm
      .validateFields()
      .then(() => {
        setIsSending(true);
        setScanResult(undefined);
        axios({
          method: "Post",
          url:
            process.env.REACT_APP_AWS_BACKEND_URL +
            "/util/compliance/scan/transaction",
          data: {
            ...scanForm.getFieldsValue(),
          },
          withCredentials: true,
        })
          .then((res) => {
            setScanResult(res.data?.scanJSON);
          })
          .catch((err) => {
            if (err.response.status === 403) {
              navigate("/login");
            }
          })
          .finally(() => {
            setIsSending(false);
          });
      })
      .catch(() => {});
  }, [scanForm, navigate]);
 
  return (
    <Content id="compliance-transaction-scan">
      <Row align="middle" justify="space-around" style={{ marginTop: "2em" }}>
        <Col span={22}>
          <Form
            form={scanForm}
            labelCol={{
              style: {
                fontWeight: "bold",
              },
            }}
            wrapperCol={{
              style: {
                maxWidth: "500px",
              },
            }}
          >
            <Form.Item
              label="Transaction Hash"
              required
              name={"txHash"}
              rules={[
                {
                  required: true,
                  message: "Please input transaction hash!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Receiving Address"
              required
              name={"toAddress"}
              rules={[
                {
                  required: true,
                  message: "Please input to Address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Asset"
              name="assetId"
              rules={[
                {
                  required: true,
                  message: "Please select asset!",
                },
              ]}
            >
              <Select
                options={assetOptions}
                showSearch
                allowClear
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Asset"
              />
            </Form.Item>
            <Form.Item
              label="Network"
              name="serviceAssetNetworkMapId"
              rules={[
                {
                  required: true,
                  message: "Please select network!",
                },
              ]}
            >
              <Select
                options={serviceAssetNetworkMap}
                showSearch
                allowClear
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Network"
              />
            </Form.Item>
            <Form.Item
              label="Direction"
              name="direction"
              rules={[
                {
                  required: true,
                  message: "Please choose direction!",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="sent">Sender</Radio>
                <Radio value="received">Receiver</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
          <Row justify={"center"}>
            <Button
              type="primary"
              className="submit-button primary-button"
              onClick={() => {
                scanTransaction();
              }}
              loading={isSending}
            >
              Scan Transaction
            </Button>
          </Row>
          {scanResult ? <ViewScanResult scanResult={scanResult} /> : undefined}
        </Col>
      </Row>
    </Content>
  );
}
